import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-choose-meal-spinner',
  templateUrl: './choose-meal-spinner.component.html',
  styleUrls: ['./choose-meal-spinner.component.scss']
})
export class ChooseMealSpinnerComponent implements AfterViewInit, OnDestroy {
  constructor(
    private dialogRef: MatBottomSheetRef<ChooseMealSpinnerComponent>
  ) {}

  @ViewChild("rotateContainer") rotateContainer: any;
  timeOut: any;

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }

  ngAfterViewInit(): void {
    
    this.timeOut = setTimeout(()=> {
      if (this.rotateContainer.nativeElement)
        this.rotateContainer.nativeElement.classList.add("stop");
    }, 8000);
  }

  rotateAgain() {
    this.rotateContainer.nativeElement.classList.remove("stop");
    this.timeOut = setTimeout(()=> {
      this.rotateContainer.nativeElement.classList.add("stop");
    }, 10000);
  }

  closeBottomSheet() {
    this.dialogRef.dismiss();
  }
  
}

