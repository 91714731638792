import { OrderedItemCustomizations } from "./OrderedItemCustomization.model";

export enum ITEM_STATUS {
  VENDOR_AVAILABLE = "VENDOR_AVAILABLE", OUT_OF_STOCK = "OUT_OF_STOCK", IN_STOCK = "IN_STOCK"
}
export enum ITEM_TYPE {
  VEG = "VEG", NON_VEG = "NON_VEG"
}

export class Item {

  id: string;
  itemId: string = "";
  itemName: string = "";
  name: string;
  price: number;
  itemCategory: string;
  itemImageUrl: string;
  itemStatus: ITEM_STATUS;
  itemType: ITEM_TYPE;
  mrpItem: boolean;
  foodCourtId: string;
  vendorId: string;
  vendorName: string;
  itemTaxPercentage: number;
  itemQuantity: number;
  menuCustomizationList: any;
  orderedItemCustomizations: OrderedItemCustomizations[];
  appItemId: string;
  taxPercentPerItem: number = 0;
  taxPerItem: number = 0;
  markUpTaxValue: number = 0;
  markUpPercent: number = 0;
  markUpPrice: number = 0;
  packagingCharge: number = 0;
  taxCategory: "MRP" | "NON_MRP" = "MRP";

  constructor(id?: string, name?: string, price?: number, itemCategory?: string, itemImageUrl?: string, itemStatus?: ITEM_STATUS, itemType?: ITEM_TYPE, mrpItem?: boolean, vendorId?: string, foodCourtId?: string, vendorName?: string, itemTaxPercentage?: number, itemQuantity?: number, taxPercentPerItem?: number, taxPerItem?: number, orderedItemCustomizations?: OrderedItemCustomizations[]) {
    this.id = id || "";
    this.appItemId = "";
    this.name = name || "";
    this.price = price || 0;
    this.itemCategory = itemCategory || "";
    this.itemImageUrl = itemImageUrl || "";
    this.itemStatus = itemStatus || ITEM_STATUS.VENDOR_AVAILABLE;

    this.itemType = itemType || ITEM_TYPE.VEG;
    this.mrpItem = mrpItem || false;
    this.vendorName = vendorName || "";
    this.foodCourtId = foodCourtId || "";
    this.vendorId = vendorId || "";
    this.itemTaxPercentage = itemTaxPercentage || 0;
    this.itemQuantity = itemQuantity || 0;
    this.taxPercentPerItem = taxPercentPerItem || 0;
    this.taxPerItem = taxPerItem || 0 ;
    this.orderedItemCustomizations = orderedItemCustomizations || [new OrderedItemCustomizations()];
  }
}
