import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  constructor(
    private http: HttpClient
  ) { }

  getHomePageResponse(foodCourtId: string, userId: string | null) {
    if (userId)
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}?userid=${userId}`, {})
    else
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}`, {})
  }
}
