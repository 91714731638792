<section class="ordering-home-page" *ngIf="!homePageLoader; else showLoadingScreen">

  <div class="search-sec" *ngIf="screenWidth <= 480">
    <div class="search-sec__container" (click)="goToSearchPage()">
      <input type="text" class="input-container" placeholder="Search for dishes or restaurant">
      <mat-icon>search</mat-icon>
    </div>
  </div>
  <!-- <ngx-slick-carousel class="carousel-content" #slickModal="slick-carousel" [config]="slideConfig">
    <div class="carousel-content-listing" *ngIf="homePageResponse.promotionalBannerList.length > 0">
      <div class="main-banner-img" *ngFor="let feature of homePageResponse.promotionalBannerList; let i = index">
        <img loading="lazy" [src]="feature.bannerImage" alt="image">
        <div class="event-banner-content">
          <div class="event-banner__primary-text">{{ feature.title | titlecase }}</div>
          <div class="event-banner__secondary-text">
            <div class="event-banner__timing">{{ feature.description.substr(0, 10) + '...' }}</div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
  <ngx-slick-carousel class="carousel-content" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem class="slide" *ngFor="let feature of homePageResponse.promotionalBannerList; let i = index">
      <div class="img-container">
          <img loading="lazy" src="../../../assets/codining/images/home-page/{{ feature.imgUrl }}.svg" alt="">
      </div>
      <div class="feature-desc">{{ feature.name | titlecase }}</div>
    </div>
  </ngx-slick-carousel> -->
  <!-- <div class="carousel-content" *ngIf="homePageResponse.promotionalBannerList.length > 0">
    <div class="carousel-content-listing">
      <div class="main-banner-img" *ngFor="let feature of homePageResponse.promotionalBannerList; let i = index"
        [style.right.%]="bannerImageFromLeft % (homePageResponse.promotionalBannerList.length * 100)">
        <img loading="lazy" [src]="(screenWidth > 480 ? feature.bannerImages.WEB : (feature.bannerImages.MOBILE))"
          alt="image">
        <div class="event-banner-content" *ngIf="feature.showLabel">
          <div class="event-banner__primary-text">{{ feature.title | titlecase }}</div>
          <div class="event-banner__secondary-text">
            <div class="event-banner__timing">{{ feature.description | titlecase }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="dots-listing">
      <ng-container *ngFor="let _ of homePageResponse.promotionalBannerList; let idx = index">
        <div class="dots"
          [ngClass]="{'dots_active': (idx === (bannerImageFromLeft/100)%homePageResponse.promotionalBannerList.length)}">
        </div>
      </ng-container>
    </div>
  </div> -->

  <div class="new-changes-carousel">
    <ngx-slick-carousel #slickModal="slick-carousel" class="carousel-content" [config]="slideConfig"
      *ngIf="screenWidth <= 480">
      <div ngxSlickItem class="slide" *ngFor="let feature of homePageResponse.promotionalBannerList; let i = index">
        <div class="carousel-content" *ngIf="feature.bannerImages.MOBILE">
        <div class="img-container" (click)="openEnquiryFormDialog()">
          <img [src]="feature.bannerImages.MOBILE" alt="image">
        </div>
        </div>
      </div>
    </ngx-slick-carousel>

    <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" *ngIf="screenWidth > 480">
      <div ngxSlickItem class="slide" *ngFor="let feature of homePageResponse.promotionalBannerList; let i = index">
        <div class="carousel-content" *ngIf="feature.bannerImages.WEB">
          <div class="img-container" (click)="openEnquiryFormDialog()">
            <img [src]="feature.bannerImages.WEB" alt="image">
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>

  <ng-container
    *ngIf="(homePageResponse.cuisineList.length > 0 || homePageResponse.menuDataForEmployees?.length > 0 || homePageResponse.quickBitesList?.length > 0 || homePageResponse.trendingtodayList?.length > 0 || homePageResponse.vendordata?.length > 0); else showEmptyPage">
    <div class="cuisine-sec" *ngIf="homePageResponse.cuisineList.length > 0">
      <div class="primary-text">What’s on your mind?</div>

      <img loading="lazy" src="../../../../assets/codining/images/common/right-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="cuisine-left-arrow" (click)="scrollLeft('CUISINE_LISTING')">
      <div class="cuisine-listing">
        <div class="cuisine-sec__cuisine-list">
          <ng-container *ngFor="let cuisine of homePageResponse.cuisineList; let idx = index"
            [style.right.%]="bannerImageFromLeft % (homePageResponse.cuisineList.length * 100)">
            <div class="cuisine-list-container"
              [style.backgroundColor]="cuisineList[idx % homePageResponse.cuisineList.length]"
              (click)="goToResturantListingByCuisine(homePageResponse.cuisineList[idx])">
              <div class="cuisine-img">
                <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg" alt="cuisine"
                  *ngIf="!homePageResponse.cuisineList[idx].imageUrl">
                <img loading="lazy" [src]="homePageResponse.cuisineList[idx].imageUrl" alt="cuisine"
                  *ngIf="homePageResponse.cuisineList[idx].imageUrl">
              </div>
              <div class="cuisine-name">{{ homePageResponse.cuisineList[idx].cuisineName | titlecase }}</div>
            </div>
          </ng-container>
          <!-- <ng-container *ngFor="let _ of [].constructor(cusineListLength); let idx = index">
        <div class="cuisine-list__columne">
          <div class="cuisine-list__columne__row"
            [style.backgroundColor]="cuisineList[idx % homePageResponse.cuisineList.length]"
            (click)="goToResturantListingByCuisine(homePageResponse.cuisineList[idx])">
            <div class="cuisine-img">
              <img loading="lazy" src="../../../../assets/codining/images/common/dummy-cuisine-img.svg" alt=""
                *ngIf="!homePageResponse.cuisineList[idx].imageUrl">
              <img loading="lazy" [src]="homePageResponse.cuisineList[idx].imageUrl" alt="cuisine"
                *ngIf="homePageResponse.cuisineList[idx].imageUrl">
            </div>
            <div class="cuisine-name">{{ homePageResponse.cuisineList[idx].cuisineName | titlecase }}</div>
          </div>
          <div class="cuisine-list__columne__row"
            [style.backgroundColor]="cuisineList[(2*idx +1) % homePageResponse.cuisineList.length]"
            (click)="goToResturantListingByCuisine(homePageResponse.cuisineList[2*idx +1])">
            <div class="cuisine-img">

              <img loading="lazy" src="../../../../assets/codining/images/common/dummy-cuisine-img.svg" alt=""
                *ngIf="!homePageResponse.cuisineList[2*idx +1].imageUrl">
              <img loading="lazy" [src]="homePageResponse.cuisineList[2*idx +1].imageUrl" alt="cuisine"
                *ngIf="homePageResponse.cuisineList[2*idx +1].imageUrl">
            </div>
            <div class="cuisine-name">{{ homePageResponse.cuisineList[2*idx +1].cuisineName | titlecase }}</div>
          </div>
        </div>
      </ng-container> -->
        </div>
      </div>
      <img loading="lazy" src="../../../../assets/codining/images/common/left-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="cuisine-right-arrow" (click)="scrollRight('CUISINE_LISTING')">

    </div>

    <div class="oneclick-sec" *ngIf="homePageResponse.menuDataForEmployees?.length > 0">
      <div class="primary-text width-70">One Click Order</div>
      <div class="oneclick-listing">
        <ng-container *ngFor="let mealDeatils of homePageResponse.menuDataForEmployees">
          <div class="oneclick-container"
            [style.backgroundColor]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#EEF9FF' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#EDFFFA' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '#F5ECFF' : '#FFF2F4')) )">
            <div class="oneclick-container__header">
              <div class="meal-name"
                [style.color]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#219DD8' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#097D5F' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '$theme-primary' : '#EB4B4B')) )">
                {{ mealDeatils.mealCategory[0] | uppercase }}</div>
              <div class="meal-type">
                <ng-container *ngIf="mealDeatils.itemType === 'VEG'">
                  <img loading="lazy" src="../../../../../assets/codining/images/common/veg-icon.svg" alt="veg">
                  <div class="meal-type-name">Veg</div>
                </ng-container>
                <ng-container *ngIf="mealDeatils.itemType === 'NON_VEG'">
                  <img loading="lazy" src="../../../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg">
                  <div class="meal-type-name">Non-Veg</div>
                </ng-container>
              </div>
            </div>
            <div class="oneclick-container__item-list">
              <ul>
                <li>{{ mealDeatils.name | titlecase }}</li>
              </ul>
              <!-- <div class="see-more-btn">See more</div> -->

            </div>
            <div class="oneclick-contianer__footer">
              <div class="meal-pricing">
                <div class="selling-price">Rs. {{
                  ((mealDeatils.markUpPrice >= mealDeatils.employerMenu[0].paidByEmployer) && (mealDeatils.orderCount <
                    mealDeatils.employerMenu[0].threshold)) ? (mealDeatils.markUpPrice -
                    mealDeatils.employerMenu[0].paidByEmployer) : (mealDeatils.markUpPrice) | number: '1.0-2' }}</div>
                    <div class="actual-price">Rs. {{ mealDeatils.markUpPrice | number: '1.0-2' }}</div>
                </div>
                <button class="oneclick-payment-btn">
                  <span class="qr-btn" (click)="openOneClickBottomSheet(mealDeatils, 'QR_CODE')"
                    *ngIf="(mealDeatils.markUpPrice == mealDeatils.employerMenu[0].paidByEmployer) && (mealDeatils.orderCount < mealDeatils.employerMenu[0].threshold); else showPayNowBtn">Scan
                    QR</span>
                  <ng-template #showPayNowBtn>
                    <span class="payment-btn" (click)="openOneClickBottomSheet(mealDeatils, 'PAY_NOW')">Pay Now</span>
                  </ng-template>
                </button>
              </div>
            </div>
        </ng-container>
      </div>
    </div>

    <div class="quick-bites-sec" *ngIf="homePageResponse.quickBitesList?.length > 0">
      <div class="primary-text width-70">Quick bites in less than 10 minutes</div>
      <img loading="lazy" src="../../../../assets/codining/images/common/right-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="tab-left-arrow" (click)="scrollLeft('QUICK_BITE_ITEM')">
      <div class="quick-bites__item-list">
        <ng-container *ngFor="let item of homePageResponse.quickBitesList">
          <div class="quick-bites__item">
            <div class="item-image">
              <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg" alt="back image"
                *ngIf="!item.itemImageUrl">
              <img loading="lazy" [src]="item.itemImageUrl" alt="back image" *ngIf="item.itemImageUrl">
            </div>
            <div class="item-details">
              <div class="item-name">{{ (item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name) |
                titlecase
                }}</div>
              <div class="vendor-name">{{ item.vendorName | titlecase }}</div>
              <div class="item-footer">
                <div class="item-price">Price: Rs.{{ item.markUpPrice | number: '1.0-2' }}</div>
                <div class="is-customization" *ngIf="item.customizationRequired">Custom</div>
              </div>
            </div>
            <ng-container
              *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OUT_OF_STOCK">
              <div class="add-to-cart-btn" (click)="addItemToCart(item, 'INCREASE')" *ngIf="!item.showItemQuantity">Add
              </div>
              <div class="add-to-cart-btn" *ngIf="item.showItemQuantity">
                <div class="decrease-item-count" (click)="addItemToCart(item, 'DECREASE')"> - </div>
                <div class="item-itemQuantity-count">{{ item.showItemQuantity }}</div>
                <div class="increase-item-count" (click)="addItemToCart(item, 'INCREASE')"> + </div>
              </div>
            </ng-container>
            <ng-template #OUT_OF_STOCK>
              <div class=" out-of-stock-btn">Out Of Stock</div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <img loading="lazy" src="../../../../assets/codining/images/common/left-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="tab-right-arrow" (click)="scrollRight('QUICK_BITE_ITEM')">
    </div>

    <div class="explore-sec" *ngIf="false">
      <div class="primary-text__white" color="white">Explore</div>
      <div class="explore-options-listing">
        <ng-container>
          <div class="explore-option" (click)="openBottomSheet()">
            <img loading="lazy" src="../../../assets/codining/images/home-page/help-me-choose.svg" alt="">
            <div class="explore-text random-choice-text">Help me choose</div>
          </div>
          <div class="explore-option">
            <img loading="lazy" src="../../../assets/codining/images/home-page/subscription-banner.svg" alt="">
            <div class="explore-text subscription-banner-text">View Subscription</div>
          </div>
          <div class="explore-option" (click)="goToGuestOrderingFlow()"
            *ngIf="homePageResponse.menuDataForEmployees?.length > 0">
            <img loading="lazy" src="../../../assets/codining/images/home-page/help-me-choose.svg" alt="">
            <div class="explore-text random-choice-text">Guest Order</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="spotlight-items-sec" *ngIf="homePageResponse.trendingtodayList?.length > 0">
      <div class="primary-text">In the spotlight</div>
      <img loading="lazy" src="../../../../assets/codining/images/common/right-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="tab-left-arrow" (click)="scrollLeft('SPOTLIGHT_ITEM')">
      <div class="spotlight-items-listing">
        <ng-container *ngFor="let item of homePageResponse.trendingtodayList">
          .<div class="spotlight__item">
            <div class="item-image">
              <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg" alt="item"
                *ngIf="!item.itemImageUrl">
              <img loading="lazy" [src]="item.itemImageUrl" alt="item" *ngIf="item.itemImageUrl">
            </div>
            <div class="item-details">
              <div class="item-name">{{ ((item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name) |
                titlecase) }}</div>
              <div class="vendor-name">{{ item.vendorName | titlecase }}</div>
              <div class="item-footer">
                <div class="item-price">Price: Rs.{{ item.markUpPrice | number: '1.0-2' }}</div>
                <div class="is-customization" *ngIf="item.customizationRequired">Custom</div>
              </div>
            </div>
            <ng-container
              *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OUT_OF_STOCK">
              <div class="add-to-cart-btn" (click)="addItemToCart(item, 'INCREASE')" *ngIf="!item.showItemQuantity">Add
              </div>
              <div class="add-to-cart-btn" *ngIf="item.showItemQuantity">
                <div class="decrease-item-count" (click)="addItemToCart(item, 'DECREASE')"> - </div>
                <div class="item-itemQuantity-count">{{ item.showItemQuantity }}</div>
                <div class="increase-item-count" (click)="addItemToCart(item, 'INCREASE')"> + </div>
              </div>
            </ng-container>
            <ng-template #OUT_OF_STOCK>
              <div class="out-of-stock-btn">Out Of Stock</div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <img loading="lazy" src="../../../../assets/codining/images/common/left-arrow.svg" alt="right arrow"
        *ngIf="screenWidth > 480" class="tab-right-arrow" (click)="scrollRight('SPOTLIGHT_ITEM')">
    </div>

    <div class="resturant-sec" *ngIf="homePageResponse.vendordata?.length > 0">
      <div class="primary-text">All Restaurants</div>
      <!-- <div class="resturant-filter-section">
      <div class="resturant-filter">Sort By</div>
      <div class="resturant-filter">Ratings</div>
      <div class="resturant-filter">Cuisine</div>
      <div class="resturant-filter">Pure Veg</div>
    </div> -->
      <div class="resturant-listing-section">
        <ng-container *ngIf="screenWidth <= 480">
          <ng-container *ngFor="let vendorDetails of homePageResponse.vendordata">
            <div class="resturant-container" (click)="goToResturantPage(vendorDetails)">
              <div class="resturant-details">
                <div class="resturant-details__left-section">
                  <div class="vendor-name">{{ vendorDetails.brandName }}</div>
                  <div class="cuisines-list">
                    <div *ngFor="let cuisine of vendorDetails.vendorCuisines; let idx = index" class="cuisine-name_">{{
                      cuisine |
                      titlecase }}, </div>
                  </div>
                  <!-- <div class="ordering-type">Self Pickup / Delivery</div> -->
                </div>
                <div class="resturant-details__right-section">
                  <div class="rating">
                    <img loading="lazy" src="../../../../../assets/codining/images/resturant-page/rating.svg" alt=""
                      class="rating-img">
                    {{vendorDetails.rating | number:'1.1-1'}}
                  </div>
                  <img loading="lazy" [src]="vendorDetails.imgUrl" alt="vendor image"
                    *ngIf="vendorDetails.imgUrl && vendorDetails.imgUrl !== 'string'">
                  <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg" alt="vendor image"
                    *ngIf="!vendorDetails.imgUrl || vendorDetails.imgUrl === 'string'">
                </div>
              </div>
              <div class="resturant-footer" *ngIf="vendorDetails.activeDiscountsTitle.length > 0">
                <ng-container *ngFor="let discount of vendorDetails.activeDiscountsTitle; let idx = index">
                  <div class="resturant-discount"
                    [style.right.%]="(vendorDiscountPositionFromLeft * idx) % (vendorDetails.activeDiscountsTitle.length * 100)">
                    {{ discount | titlecase }}
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="screenWidth > 480">
          <ng-container *ngFor="let vendorDetails of homePageResponse.vendordata">
            <div class="resturant-container-tab" (click)="goToResturantPage(vendorDetails)">
              <div class="resturant-top-sec">
                <div class="rating">
                  <div class="rating-container-img">
                    <img loading="lazy" src="../../../../../assets/codining/images/resturant-page/rating.svg" alt=""
                      class="rating-img">
                  </div>
                  <div class="rating-text">{{vendorDetails.rating | number:'1.1-1'}}</div>
                </div>
                <img loading="lazy" [src]="vendorDetails.imgUrl" alt="vendor image"
                  *ngIf="vendorDetails.imgUrl && vendorDetails.imgUrl !== 'string'">
                <img loading="lazy" src="../../../../../assets/common/resturant-empty-icon.svg" alt=""
                  *ngIf="!vendorDetails.imgUrl || vendorDetails.imgUrl === 'string'">
              </div>
              <div class="returant-bottom-sec">
                <div class="returant-name">{{ vendorDetails.brandName }}</div>
                <div class="returant-cuisines">
                  <div class="cuisine-name" *ngFor="let cuisine of vendorDetails.vendorCuisines; let idx = index">
                    <ul class="cuisine">
                      <li>
                        {{cuisine |titlecase }}
                      </li>

                    </ul>

                  </div>
                </div>
              </div>
              <div class="resturant-footer" *ngIf="vendorDetails.activeDiscountsTitle.length > 0">
                <ng-container *ngFor="let discount of vendorDetails.activeDiscountsTitle; let idx = index">
                  <div class="resturant-discount"
                    [style.right.%]="(vendorDiscountPositionFromLeft * idx) % (vendorDetails.activeDiscountsTitle.length * 100)">
                    {{ discount | titlecase }}
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #showEmptyPage>
    <div class="empty-page-section">
      <div class="image-container">
        <img src="../../../../../assets/common/search-empty-image.svg" alt="empty">
      </div>
      <div class="heading-text">Hungry for Something?</div>
      <div class="secondary-text">Looks like your taste buds are ready for a treat! Try Searching for your favourite
        dish or explore our curated selections.</div>
      <div class="explore-button">
        <button (click)="openFoodCourtListing()">Explore</button>
      </div>
    </div>
  </ng-template>

  <div class="sec-divider"></div>

  <div class="testimonial-section" *ngIf="homePageResponse.testimonialsList.length > 0">
    <ng-container *ngIf="screenWidth >= 480">
      <div class="top-left-background">
        <img loading="lazy" src="../../../../../assets/website/images/home/testimonals-top-left-bg.svg" alt="bg image">
      </div>
      <div class="right-bottom-background">
        <img loading="lazy" src="../../../../../assets/website/images/home/testimonals-bottom-right-bg.svg"
          alt="bg image">
      </div>
    </ng-container>
    <div class="testimonial__primary-text">
      <span>Happy tales:</span>
      <div>Hear it from our <span class="blue-color-text">Joyful Folks</span></div>
    </div>
    <div class="testimonial-list">
      <ng-container *ngFor="let testimonial of homePageResponse.testimonialsList; let idx = index">
        <div class="testimonial__user-details"
          [style.left.%]="(positionFromLeft + (idx * 100)) % (homePageResponse.testimonialsList.length * 100)">
          <div class="user-image">
            <img loading="lazy" [src]="testimonial.imageUrl" alt="user" *ngIf="testimonial.imageUrl">
            <img loading="lazy" src="../../../../../assets/website/images/common/avatar-male.png" alt="avatar"
              *ngIf="!testimonial.imageUrl">
          </div>
          <div class="user-name">{{ testimonial.userName | titlecase }}</div>
          <div class="user-comment">
            “{{ testimonial.message | titlecase }}”
          </div>
        </div>
      </ng-container>
    </div>
    <div class="testimonial-action-btn">
      <div class="left-btn" (click)="moveInTestimonial('LEFT')">
        <img src="../../../../../assets/coliving/property-home/back-arrow-circular-outline.svg" alt="back arrow">
      </div>
      <div class="right-btn" (click)="moveInTestimonial('RIGHT')">
        <img src="../../../../../assets/coliving/property-home/next-arrow-circular-outline.svg" alt="back arrow">
      </div>
    </div>
  </div>
  <div class="events-section" *ngIf="homePageResponse.eventsList.length > 0">
    <div class="events-header">
      <div class="events__primary-text">Eat, Meet and Celebrate!</div>
      <div class="events__secondary-text">
        From events to movie nights, our spaces are vibrant hubs of non-stop excitement!
      </div>
    </div>

    <div class="events-image-section">
      <div class="event-listing">
        <ng-container *ngFor="let eventDetails of homePageResponse.eventsList">
          <div class="event-banner-img" [style.left.%]="eventPositionFromLeft">
            <img loading="lazy" [src]="( screenWidth > 480 ? eventDetails.coverImageWeb : eventDetails.coverImage)"
              alt="event">
            <div class="event-banner-content" *ngIf="eventDetails.showLabel">
              <div class="event-banner__status">{{ eventDetails.status }}</div>
              <div class="event-banner__primary-text">{{ eventDetails.eventName | titlecase }}</div>
              <div class="event-banner__secondary-text">
                <div class="event-banner__timing">{{ eventDetails.startDateTime | date }}</div>
                <div class="event-banner__location">{{ eventDetails.eventDesc | titlecase }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="events-side-img" *ngIf="false">
        <div class="left-img">
          <img loading="lazy" src="../../../assets/codining/images/home-page/events-side-img.svg" alt="">
          <div class="side-img-content">Fun Event</div>
        </div>
        <div class="right-img">
          <img loading="lazy" src="../../../assets/codining/images/home-page/events-side-img.svg" alt="">
          <div class="side-img-content">Food Festival</div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="userCartData.length > 0 && userCartData">
    <app-cart-footer [cartDetails]="userCartData"></app-cart-footer>
  </ng-container>

</section>
<ng-template #showLoadingScreen>
  <div class="loading-screen">
    <div class="loading-screen-container">
      <img loading="lazy" src="../../../../../assets/common/food-loader.gif" alt="food loader">
    </div>
  </div>
</ng-template>