import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from '../../shared-services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { BlobServiceClient } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private lsService: SharedService,
  ) { }

  getUserOrderHistoryList(payload: any) {
    return this.http.put(environment.baseUrl + `/foodcourt/user/ist/cart`, payload);
  }

  initiateRefundToWallet(orderId: string, pointsHistoryId: string, orderDetails: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/refund/bank?orderId=${orderId}&pointHistoryId=${pointsHistoryId}`, orderDetails
    )
  }

  changeUserPassword(sendingPayload: any) {
    return this.http.put(
      environment.baseUrl + `/user/userdetails/ist/password/update`, sendingPayload
    )
  }

  getHelpdeskColivingListing(sendingPayload: any) {
    return this.http.get(
      environment.oldBaseUrl + `/api/MobileApi/HelpDeskList?userId=${sendingPayload.userId}&status=${sendingPayload.status}`
    )
  }
  getColivingHelpdeskCategortListing() {
    return this.http.get(
      environment.oldBaseUrl + `/api/MobileApi/GetCategoryList`
    )
  }
  getColivingHelpdeskSubCategoryListing(categoryId: string) {
    return this.http.get(
      environment.oldBaseUrl + `/api/MobileApi/GetSubCategoryListByCategoryId?CategoryId=${categoryId}`
    )
  }
  createHelpdeskTicketColiving(description: string, categoryId: string, subCategoryId: string, userId: string, access_token: string) {
    // return this.http.post(environment.oldBaseUrl + `/api/MobileAPI/SaveOrUpdateHelpDesk?Title=anu&Description=${description}&CategoryMasterId=${categoryId}&SubCategoryMasterId=${subCategoryId}&userId=${userId}`, {});
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': access_token });
    // const body = new HttpParams().set('ResidentDetailsId', payload.ResidentDetailsId).set('Reason_Id', payload.Reason_Id).set('Reason', payload.Reason).set('CheckoutDate', payload.CheckoutDate).set('BankName', payload.BankName).set('AccountHolderName', payload.AccountHolderName).set('IFSC', payload.IFSC).set('AccountNo', payload.AccountNo).set('RequestedVia', payload.RequestedVia).set('RequestedBy', payload.RequestedBy).set('ResidentFeedBack', payload.ResidentFeedBack).set('BranchName', payload.BranchName).set('SocietyOrBuldingName', payload.SocietyOrBuldingName).set('WhereAreYouGoing', payload.WhereAreYouGoing).set('IfSameCity', payload.IfSameCity).set('Locality', payload.Locality);
    const options = { headers: headers };
    return this.http.post(environment.oldBaseUrl + `/api/HelpDeskApi/SaveOrUpdateHelpDesk?Title=anu&Description=${description}&CategoryMasterId=${categoryId}&SubCategoryMasterId=${subCategoryId}&userId=${userId}`, {}, options);
  }


  getUserPoints(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  logoutUser(userId: string, deviceInfo: any) {
    return this.http.put(
      environment.baseUrl + `/user/user/${userId}/logout`,
      deviceInfo,
      { headers: { "user_id": userId } }
    );
  }

  getUserPointsHistoryList(userId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/points/${userId}`)
  }

  // getting forbiden message
  getUserDetailsByUserId(userId: string) {
    return this.http.get(environment.baseUrl + `/user/user/${userId}`, { headers: { Authorization: this.lsService.getFromLocalStorage("token") } });
  }

  updateUserInfo(sendingPayload: any) {
    return this.http.put(environment.baseUrl + '/user/user/', sendingPayload, { headers: { Authorization: this.lsService.getFromLocalStorage("token") } });
  }

  // For uploading images to s3 bucket
  imageObject: any;

  async uploadImage(file: File, fileName: string) {
    const sasToken = environment.sasToken;
    const accountName = environment.accountName;
    const containerName = environment.containerName;

    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName + "/" + blobName);

    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });
    return blockBlobClient;
  }

  // async uploadImage(element: any) {
  //   const AWSService = AWS;
  //   const imageEnvCognito =
  //     environment.image.CredentialsProvider.CognitoIdentity.Default;
  //   const imageEnvUtility = environment.image.S3TransferUtility.Default;
  //   const region = imageEnvUtility.Region;
  //   const bucketName = imageEnvUtility.Bucket;
  //   const IdentityPoolId = imageEnvCognito.PoolId;
  //   // ? Configures the AWS service and initial authorization
  //   AWSService.config.update({
  //     region: region,
  //     credentials: new AWSService.CognitoIdentityCredentials({
  //       IdentityPoolId: IdentityPoolId,
  //     }),
  //   });
  //   // ? adds the S3 service, make sure the api version and bucket are correct
  //   const s3 = new AWSService.S3({
  //     apiVersion: '2012-07-10',
  //     params: {
  //       Bucket: bucketName,
  //     },
  //   });

  //   // const element = e.srcElement.files[0];

  //   let imgPromise = new Promise((res, rej) => {
  //     if (element.size < environment.imageUploadSize) {
  //       if (element) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(element);
  //         reader.onload = () => {
  //           s3.upload(
  //             {
  //               Key:
  //                 'imgaes/' +
  //                 Math.floor(100000 + Math.random() * 900000) +
  //                 'c' +
  //                 new Date().getTime() +
  //                 element.name,
  //               Bucket: bucketName,
  //               Body: element,
  //               ACL: 'public-read',
  //             },
  //             function (err: any, data: any) {
  //               if (err) {
  //                 rej(err);
  //               } else {
  //                 res(data);
  //               }
  //             }
  //           );
  //         };
  //       }
  //     } else {
  //       this.snackBar.open('File Size Greater than 1Mb!', 'Close');
  //       rej('File Size Greater than 1Mb!');
  //     }

  //   });

  //   return imgPromise
  //     .then((data) => {
  //       this.imageObject = data;
  //       //console.log('awsResult', data);
  //       return data;
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //       return err;
  //     });
  // }

  // openSnackBar(message: string, closingText: string, snackBarType: "SUCCESS" | "WARNING" | "ERROR" = "SUCCESS") {
  //   this.snackBar.open(message, closingText, {
  //     panelClass: ["warning-snackbar"],
  //     duration: 4000
  //   });
  // }




  // let paymentDetails = {
  //     "email": (userEmailId ? userEmailId : "test@gmail.com"),
  //     "mobile": userMobileName,
  //     // "orderAmount": this.paymentAmount * 100,
  //     "orderAmount": this.paymentAmount * 100,
  //     "orderNote": "string",
  //     "pointsToBePaid": 0,
  //     "userId": userId,
  //     "username": userName
  //   }

  createWalletPaymentToken(data: any) {
    return this.http.post(environment.baseUrl + `/foodcourt/payment/ist/wallet/token/web`, data)
  }

  getAllFavouriteProperties(userId: string) {
    return this.http.get(environment.baseUrl + `/property/property/ist/favouriteproperties/${userId}`)
  }

  getAllContactPerson(siteId: string, foodCourtId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/spoc/ist/getByFc/list?fcId=${foodCourtId}&siteId=${siteId}`)
  }


  generateToken(payload: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('username', payload.username).set('password', payload.password).set('grant_type', payload.grant_type);
    const options = { headers: headers };
    return this.http.post(environment.oldBaseUrl + `/token`, body.toString(), options);
  }

  // isAlreadyRequestedForCheckOut(residentDetailsId: string) {
  //   return this.http.get(environment.oldBaseUrl + `/api/MobileApi/IsAlreadyRequestedForCheckOut?ResidentDetailsId=${residentDetailsId}`);
  // }

  getReasonListForResidentLeaving() {
    return this.http.get(environment.oldBaseUrl + `/api/MobileApi/GetReasonListForResidentLeaving`);
  }

  residentCheckoutRequest(payload: any, access_token: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': access_token });
    const body = new HttpParams().set('ResidentDetailsId', payload.ResidentDetailsId).set('Reason_Id', payload.Reason_Id).set('Reason', payload.Reason).set('CheckoutDate', payload.CheckoutDate).set('BankName', payload.BankName).set('AccountHolderName', payload.AccountHolderName).set('IFSC', payload.IFSC).set('AccountNo', payload.AccountNo).set('RequestedVia', payload.RequestedVia).set('RequestedBy', payload.RequestedBy).set('ResidentFeedBack', payload.ResidentFeedBack).set('BranchName', payload.BranchName).set('SocietyOrBuldingName', payload.SocietyOrBuldingName).set('WhereAreYouGoing', payload.WhereAreYouGoing).set('IfSameCity', payload.IfSameCity).set('Locality', payload.Locality);
    const options = { headers: headers };
    return this.http.post(environment.oldBaseUrl + `/api/MobileApi/ResidentCheckoutRequest`, body.toString(), options);
  }

  hasAlreadyRequestedForCheckout(residentDetailId: string) {
    return this.http.get(environment.oldBaseUrl + `/api/MobileApi/IsAlreadyRequestedForCheckOut?ResidentDetailsId=${residentDetailId}`)
  }
  getCheckoutRequestDetails(residentDetailId: string) {
    return this.http.get(environment.oldBaseUrl + `/api/MobileApi/GetCheckoutDetailsByResidentDetailsId?ResidentDetailsId=${residentDetailId}`)
  }



  // notification API


  private notificationClicked = new Subject<void>();
  setNotificationClick() {
    this.notificationClicked.next();
  }

  getNotificationClickObs() {
    return this.notificationClicked;
  }

  getUserNotifications(payload: any) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/notification/custom/ist/notification/history/${payload.userId}?startDate=${payload.startDate}&endDate=${payload.endDate}`
    )
  }


  updateNotificationClicked(payload: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/notification/custom/ist/notification/clicked/${payload.notificationId}?userId=${payload.userId}&date=${payload.date}`, {}
    )
  }
  //  helpDesk detailspage api
  getHelpDeskDetails(helpdeskId: any) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/ist/v1/${helpdeskId}`)
  }

  closeTicketHelpDesk(helpdeskTicketId: any) {
    return this.http.put(
      environment.baseUrl + `/property/helpdesk/ist/v1/${helpdeskTicketId}/close?ticketType=FOOD_COURT`, {}
    )
  }


  getNotificationForOldErp(userId: any) {
    return this.http.get(
      environment.oldBaseUrl +
      `/api/MobileApi/GetNotificationDetailsByUserId?UserId=${userId}`
    )
  }

  getCoLivingDetails(HelpDeskId: any) {
    return this.http.get(
      environment.oldBaseUrl + `/api/MobileApi/GetHelpDeskDetailsByHelpDeskId?HelpDeskId=${HelpDeskId}`
    )
  }


  // event API

  getEventListing(payload: any) {
    return this.http.put(
      environment.baseUrl +
      `/foodcourt/co-dining/ist/event/user/response`, payload
    )
  }

  // Subscription API
  getUserSubscriptionListing(userId: string, status: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/subscription/ist/user/plan?status=${status}&userId=${userId}`
    )
  }
}
