import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

enum VIEW_TYPES {
  ORDER_PLACE = "ORDER_PLACED",
  POINTS_RECHARED = "POINTS_RECHARGED",
  POINTS_RECHARGED_FAILED = "POINTS_RECHARGED_FAILED",
  ORDER_FAILED = "ORDER_FAILED"
}

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    private router: Router
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.viewPort = this.matDialogData.type;
    if (!this.viewPort)
      this.dialogRef.close();
  }

  viewPort: VIEW_TYPES | null = null;

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  closeDialogBox() {
    this.dialogRef.close();
  }
  goToOrderDetails() {
    this.dialogRef.close("ORDER_DETAIL");
  }
  goToRechargeDetails() {
    this.dialogRef.close("RECHARGE_DETAIL");
  }


  closeButton(){
    this.dialogRef.close('success');
  }
}
