import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APIService } from '../../shared-services/api-service.service';
import { SharedService } from '../../shared-services/shared.service';
import { SuccessPopupComponent } from '../../modules/codining/shared-components/success-popup/success-popup.component';

@Component({
  selector: 'app-enquiry-dialog',
  templateUrl: './enquiry-dialog.component.html',
  styleUrls: ['./enquiry-dialog.component.scss','./mq-enquiry-dialog.component.scss']
})
export class EnquiryDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EnquiryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: APIService,
    private sharedService: SharedService,
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    // if (this.screenWidth > 480) {
    //   this.dialogRef.updatePosition({ top: '5%', right: `2%` });
    // }
    this.findAllLocalityByCity();
  }

  localityList: any = [];
  findAllLocalityByCity() {
    this.apiService.getAllLocalityByCity(['hyderabad']).subscribe((response: any) => {
      this.localityList = response.data.filter((locality: any) => locality.active && !locality.verticalList.includes("FOODCOURT"));
    }, (error: any) => {
    }, () => {

    })
  }

  screenWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  paymentButtonLoader: boolean = false;
  enquiryDetails: any = {
    name: null,
    mobileno: null,
    description: null,
    localityname: '',
    sourceType: "WEBSITE"
  }


  submitEnquiry() {
    if (this.enquiryDetails.mobileno.toString().length === 10) {
      let sendingPayload: any = this.enquiryDetails;
      this.paymentButtonLoader = true;
      this.apiService.createEnquiry(sendingPayload).subscribe((response: any) => {
        if (this.screenWidth > 480) {
          this.sharedService.openDialogConfig
            (SuccessPopupComponent,
              '50vh',
              '25vw',
              'auto',
              { type: 'EQUIRYFORM' }
            ).afterClosed().subscribe(() => {
              this.dialogRef.close();
            });
        }
        else {
          this.sharedService.openDialogConfig
            (SuccessPopupComponent,
              '50vh',
              '80vw',
              'auto',
              { type: 'EQUIRYFORM' }
            )
            .afterClosed().subscribe(() => {
              this.dialogRef.close();
            });
        }
        this.paymentButtonLoader = false;
        this.dialogRef.close();
      }, (error: any) => {
        this.sharedService.openSnackBar("Failed to Create Enquiry");
      }, () => {
        this.paymentButtonLoader = false;
      })
    }
    else {
      this.sharedService.openSnackBar(
        'Please enter 10 digits mobile number'
      )
    }
  }

  selectedListingType: any = "FOOD_COURT";
  navigationListing: any = [
    { name: "MBD", value: "FOOD_COURT" },
    { name: "Coliving", value: "PROPERTY" }
  ];

  getEventListing(type: "FOOD_COURT" | "PROPERTY") {
    this.selectedListingType = type;
  }

  selectedChip: string = "FOOD_COURT";

}
