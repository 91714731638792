import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { environment } from '../../environments/environment';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Dialog } from '../constants/constant';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) { }

  tokenFromUI: string = environment.encodingKey;

  // Stored nomenclature for keys in local storage, when you add anything in local storage,
  // please specify the names as below. It will help people in future.

  // ist_key_id = propertyId
  // ist_ian_id = userId
  storeInLocalStorage(key: string, value: any) {

    if (value)
      localStorage.setItem(key, this.encryptUsingAES256(value));
    else
      localStorage.removeItem(key);

  }


  getFromLocalStorage(key: string) {
    let data = localStorage.getItem(key);

    if (data) {
      try {
        return JSON.parse(this.decryptUsingAES256(data));
      } catch (err) {
        return this.decryptUsingAES256(data);
      }
    } else {
      return null;
    }
  }

  private encryptUsingAES256(token: string | null) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(token), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  private decryptUsingAES256(encrypted: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    let decrypted = CryptoJS.AES.decrypt(encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
      .toString(CryptoJS.enc.Utf8)
      .replace(/"/g, '');
    return decrypted;
  }

  openBottomSheet(component: any, data: any) {
    return this._bottomSheet.open(component, {
      data: data,
      // panelClass: 'custom-notification-bottom-sheet',
    })
  }


  openSnackBar(message: string) {
    return this._snackBar.open(message, 'Close', {
      duration: 2000
    });
  }
  openDialogSingle(componentName: any, height?: any, width?: any,maxWidth?:any, data?: any) {
    return this._dialog.open(componentName, { width: width, height: height,  maxWidth:maxWidth, data: data });
  }

  openDialog(dialog: Dialog) {
    return this._dialog.open(dialog.Component, {
      width: dialog.width,
      height: dialog.height,
      maxWidth: dialog.maxWidth,
      maxHeight: dialog.maxHeight,
      data: dialog.data,
      enterAnimationDuration: dialog.enterAnimationDuration,
      exitAnimationDuration: dialog.exitAnimationDuration,
      panelClass: 'custom-dialog-container'
    });
  }

  openDialogConfig(component: any, height: any, width?: any, maxWidth?:any, data?: any) {
    return this._dialog.open(component, {
      height: height,
      width: width,
      maxWidth:maxWidth,
      data: data
    })
  }

  openDialogConfigRadius(component: any, height: any, width?: any, maxWidth?:any, panelClass?:any, data?: any) {
    return this._dialog.open(component, {
      height: height,
      width: width,
      maxWidth:maxWidth,
      panelClass: panelClass,
      data: data
    })
  }

}

