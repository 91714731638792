import {
  Component,
  HostListener,
  OnInit,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { APIService } from '../../shared-services/api-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../shared-services/shared.service';
import { Router } from '@angular/router';
import { SuccessPopupComponent } from '../../modules/codining/shared-components/success-popup/success-popup.component';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss'],
})
export class EnquiryFormComponent implements OnInit, OnDestroy {
  constructor(
    private apiService: APIService,
    private sharedService: SharedService,
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<EnquiryFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  screenLoading: boolean = true;
  buttonLoading: boolean = false;
  showImage = false;

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  paymentButtonLoader: boolean = false;
  enquiryDetails: any = {
    name: null,
    cityname: 'hyderabad',
    localityname: '',
    emailId: null,
    mobileno: null,
    sourceType: 'WEBSITE',
  };

  propertyDetails: any;
  propertyId: any;
  ngOnInit(): void {
    this.propertyId =
      this.sharedService.getFromLocalStorage('enquiryPropertyId');

    if (this.propertyId) {
      this.selectedEnquiryVertical = 'CO_LIVING';
      this.getPropertyDetailsByPropertyId();
    } else {
      this.getAllCityList();
    }

    this.getScreenSize();
  }

  ngOnDestroy(): void {
    this.sharedService.storeInLocalStorage('enquiryPropertyId', null);
  }

  getPropertyDetailsByPropertyId() {
    this.screenLoading = true;

    this.apiService.getPropertyByPropertyId(this.propertyId).subscribe({
      next: (response: any) => {
        this.propertyDetails = response.data;
      },
      error: (error: any) => {
        // if (error.message) this._sharedService.openSnackBar(error.message)
        // else this._sharedService.openSnackBar("Something went wrong, please try again");
      },
      complete: () => {
        this.screenLoading = false;
        this.getAllCityList();
      },
    });
  }

  closeDialog() {
    this.router.navigate(['/home']);
  }

  findAllLocalityByCity() {
    this.screenLoading = true;
    this.apiService
      .getAllLocalityByCity([this.enquiryDetails.cityname])
      .subscribe(
        (response: any) => {
          this.localityList = response.data.filter(
            (locality: any) =>
              locality.active && !locality.verticalList.includes('FOODCOURT')
          );
          if (this.propertyId) {
            this.enquiryDetails.localityname =
              this.propertyDetails.propertyAddress.locality;
          }
          this.screenLoading = false;
        },
        (error: any) => {
          this.screenLoading = false;
        },
        () => { }
      );
  }
  submitEnquiry() {
    // let enquiryDetails = {
    //   "cityname": "Hyderabad",
    //   "countryCode": "91",
    //   "emailId": "a@gmail.com",
    //   // "istharacityId":1,
    //   // "locality":"kukatpally",
    //   // "locationId":9,
    //   "mobileno": 9876545655,
    //   "name": "a",
    //   "sourceType": "WEBSITE",
    //   // "propertyId":null,
    //   // "istharaPropertyId":0
    // }
    if (this.enquiryDetails.mobileno.toString().length === 10) {
      let sendingPayload: any = this.enquiryDetails;
      if (this.propertyId) {
        sendingPayload = {
          ...this.enquiryDetails,
          propertyId: this.propertyId,
        };
      }

      this.paymentButtonLoader = true;
      this.apiService.createEnquiry(sendingPayload).subscribe(
        (response: any) => {
          // this.sharedService.openSnackBar("We will reach out to you shortly");
          if (this.screenWidth > 480) {
            this.sharedService
              .openDialogConfig(SuccessPopupComponent, '50vh', '25vw', 'auto', {
                type: 'EQUIRYFORM',
              })
              .afterClosed()
              .subscribe(() => {
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
                else {
                  this.router.navigate(['']);
                }
              });
          } else {
            this.sharedService
              .openDialogConfig(SuccessPopupComponent, '50vh', '80vw', 'auto', {
                type: 'EQUIRYFORM',
              })
              .afterClosed()
              .subscribe(() => {
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
                else {
                  this.router.navigate(['']);
                }
              });
          }

          // window.location.reload();
          // this.dialogRef.close();
        },
        (error: any) => {
          this.sharedService.openSnackBar('Failed to Create Enquiry');
          // }, () => {
          this.paymentButtonLoader = false;
        }
      );
    } else {
      this.sharedService.openSnackBar('Please enter 10 digits mobile number');
    }
  }

  selectedEnquiryVertical: any;
  selectedCity: any;
  localityList: any[] = [];
  enquiryVertical: any[] = [
    { name: 'Co - dining', value: 'CO_DINING' },
    { name: 'Co - Living', value: 'CO_LIVING' },
  ];

  cityList = [{ city: 'hyderabad', state: 'telangana' }];

  getAllCityList() {
    // if (this.propertyId) {
    // this.enquiryDetails.cityname = this.propertyDetails.propertyAddress.city;
    // }
    this.findAllLocalityByCity();
    // this.screenLoading = true;
    // this.apiService.getAllCityList().subscribe((response: any) => {
    //   this.cityList = response.data;

    //   this.screenLoading = false;
    // }, (error: any) => {

    // }, ()=> {

    // })
  }
}
