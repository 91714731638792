<section class="coupons-listing-section">
  <div class="coupon-heading">
    <div class="back-btn" (click)="goToCartPage()">
      <img loading="lazy" src="../../assets/codining/images/common/back_arrow.svg" alt="">
    </div>
    <div class="header-text">Apply Coupon</div>
  </div>
  <div class="coupon-search-sec">
    <input type="text" placeholder="Type Coupon Code" [(ngModel)]="enteredCouponCode">
    <div class="apply-btn-search" (click)="applyCouponCode()">Apply</div>
  </div>
  <div class="coupon-listing">
    <ng-container *ngIf="couponList.length > 0; else showEmptyScreen">
      <ng-container *ngFor="let couponDetails of couponList; let idx = index">
        <div class="coupon-details"
          [ngStyle]="{'background-color': couponDetails.discountType === 'PERCENTAGE' ? '#FFF2F4' : (couponDetails.discountType === 'FIXED_AMOUNT' ? '#EEF9FF' : '#EDFFFA')}">
          <div class="details__coupon-heading">
            <div class="coupon-brief">
              <div class="coupon-brief__left-sec">
                <div class="coupon-name"
                  [ngStyle]="{'color': couponDetails.discountType === 'PERCENTAGE' ? '#DB1A1A' : (couponDetails.discountType === 'FIXED_AMOUNT' ? '#056B9B' : '#097D5F')}">
                  {{ couponDetails.discountName | uppercase }}</div>
                <div class="coupon-desc">{{ couponDetails.discountDescription | titlecase }}</div>
              </div>
              <div class="coupon-brief__right-sec">
                <div class="apply-offer-btn" (click)="applyCoupon(couponDetails)"
                  [style.opacity]="couponDetails.applicable ? '1' : '0.3'">Apply</div>
              </div>
            </div>
            <div class="coupon-toggle-details-btn" (click)="couponDetails.status = !couponDetails.status">
              <span class="hide-details-btn" *ngIf="couponDetails.status">Hide details</span>
              <div class="show-details-btn" *ngIf="!couponDetails.status">View details</div>
            </div>
          </div>
          <ng-container *ngIf="couponDetails.status">
            <div class="details-divider"></div>
            <ul class="details__coupon-terms">
              <li>Offer valid from {{ couponDetails.validFrom | date }} - {{ couponDetails.validTo | date }}</li>
            </ul>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #showEmptyScreen>
      <div class="empty-screen-container">
        <img loading="lazy" src="../../../../../assets/common/coupon-empty-img.svg" alt="">
        <div class="empty-screen__primary-text">No Coupon Available</div>
        <div class="empty-screen__secondary-text">
          Looks like there are no coupons at the moment
        </div>
        <!-- <div class="empty-screen__button">Explore</div> -->
      </div>
    </ng-template>
  </div>
</section>