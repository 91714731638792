import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResturantPageService } from './resturant-page.service';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { ItemCustomizationComponent } from '../../shared-components/item-customization/item-customization.component';
import { Item } from '../../entities/Item.model';
import { StorageServiceService } from '../../shared-services/storage-service.service';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';

@Component({
  selector: 'app-resturant-page',
  templateUrl: './resturant-page.component.html',
  styleUrls: ['./resturant-page.component.scss', './mq-resturant-page.component.scss']
})
export class ResturantPageComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private resturantService: ResturantPageService,
    private sharedLocalStorageService: SharedService,
    private sharedUtilsService: SharedUtilsService,
    private storageService: StorageServiceService
  ) {
    this.getScreenSize();
  };

  resturantPageLoader: boolean = true;
  menuItemListLoader: boolean = true;
  searchText: string = "";

  foodcourtName: string = '';

  userCartData: Item[] = [];

  ngOnInit() {
    const fcName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");

    this.foodcourtName = fcName ? fcName : '';

    this.getUserCart();
  }

  couponIntervalRef: any;
  ngOnDestroy(): void {
    clearInterval(this.couponIntervalRef);
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  handleFoodcourtSearch = this.sharedUtilsService.searchContent(() => this.getVendorItemList(), 1000);


  getUserCart() {
    const request = this.storageService.getUserCart();
    request.onsuccess = (response: any) => {
      this.userCartData = request.result;
      this.getVendorDetails();
      this.getVendorItemList();
    };
    request.onerror = (error: any) => {
      this.getVendorDetails();
      this.getVendorItemList();
    }
  }
  searchMenuItems() {

    this.menuItemListLoader = true;
    let vendorId = this.sharedLocalStorageService.getFromLocalStorage("vendorId") || "";
    let foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId") || "";

    this.resturantService.searchMenuItemForVendor(foodCourtId, vendorId, this.searchText).subscribe((response: any) => {
      this.vendorItemList = response.data.map((item: any) => {
        return { ...item, itemQuantity: 0, showItemQuantity: 0, orderedItemCustomizations: [] }
      });
      this.sortItemListing();
      this.vendorItemList.forEach((item: any) => {
        this.userCartData.forEach((cartItem: any) => {
          if (item.id === cartItem.id) {
            item.showItemQuantity += cartItem.itemQuantity;
            item.itemQuantity = cartItem.itemQuantity;
            item.orderedItemCustomizations = cartItem.orderedItemCustomizations;
          }
        })
      })
    }, (error: any) => {

    }, () => {
      this.menuItemListLoader = false;
    })
  }

  fiterResturantMenu(type: string) {
    this.selectedMealType = type;
    this.getVendorItemList();
  }

  sortingType: "LOW_TO_HIGH" | "HIGH_TO_LOW" | null = null;
  showSortContent: boolean = false;

  goToHomePage() {
    let foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "";
    this.router.navigate([`/mbd/${foodCourtName.replace(" ", "+")}`]);
  }

  sortItemListing() {

    if (!this.sortingType)
      return;

    this.menuItemListLoader = true;

    if (this.sortingType === "LOW_TO_HIGH")
      this.vendorItemList = this.vendorItemList.sort((itemOne: any, itemTwo: any) => itemOne.markUpPrice > itemTwo.markUpPrice ? 1 : -1);
    else
      this.vendorItemList = this.vendorItemList.sort((itemOne: any, itemTwo: any) => itemOne.markUpPrice > itemTwo.markUpPrice ? -1 : 1);

    this.showSortContent = false;
    setTimeout(() => {
      this.menuItemListLoader = false;
    }, 1000)
  }


  vendorItemList: any[] = [];
  vendorDetails: any;

  couponPositionFromLeft: number = 0;

  getVendorDetails() {
    let vendorId = this.sharedLocalStorageService.getFromLocalStorage("vendorId") || "";

    this.resturantPageLoader = true;

    this.resturantService.getVendorDetails(vendorId).subscribe((response: any) => {
      this.vendorDetails = response.data;
      if (this.vendorDetails.discountResponseList.length > 0) {
        this.couponIntervalRef = setInterval(() => {
          this.couponPositionFromLeft += 100;
        }, 4000);
      }
    }, (error) => {

    }, () => {
      this.resturantPageLoader = false;
    })
  }

  selectedMealType: string | null = null;

  getVendorItemList() {

    this.menuItemListLoader = true;

    let foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    let vendorId = this.sharedLocalStorageService.getFromLocalStorage("vendorId");

    let data = {
      foodCourtId: foodCourtId,
      itemType: this.selectedMealType,
      mealCategory: null,
      vendorId: vendorId,
      name: this.searchText,
    }
    this.resturantService.getVendorFoodItemList(data).subscribe((reponse: any) => {
      this.vendorItemList = reponse.data.map((item: any) => {
        return { ...item, itemQuantity: 0, showItemQuantity: 0, orderedItemCustomizations: [] }
      });

      this.sortItemListing();

    }, (error: any) => {

    }, () => {
      this.menuItemListLoader = false;
      this.vendorItemList.forEach((item: any) => {
        this.userCartData.forEach((cartItem: any) => {
          if (item.id === cartItem.id) {
            item.itemQuantity = cartItem.itemQuantity;
            item.showItemQuantity += cartItem.itemQuantity;
            item.orderedItemCustomizations = cartItem.orderedItemCustomizations;
          }
        })
      })
    })
  }


  addItemToCart(itemDetails: any, operation: "INCREASE" | "DECREASE") {

    if (itemDetails.customizationRequired) {

      if (operation === "DECREASE") {
        this.router.navigate(['/mbd/cart']);
        return;
      }
      this.sharedUtilsService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails, type: operation }).afterDismissed().subscribe((response: any) => {
        if (response.status === "SUCCESS") {
          itemDetails.itemQuantity++;
          itemDetails.showItemQuantity++;
          if (response.createNewId)
            itemDetails.itemQuantity = 1;

          // itemDetails = {
          //   ...itemDetails,
          //   orderedItemCustomizations: response.selectedCustomizationList
          // };
          itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;

          if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId, true)) {
            itemDetails.itemQuantity--;
            itemDetails.showItemQuantity--;
          }
          // else {

          //   this.getUserCart();
          // }
          else {
            if (response.createNewId) {
              this.userCartData = [...this.userCartData, itemDetails];
            } else {
              if (this.userCartData.length > 0) {
                this.userCartData.forEach((item: any) => {
                  if (itemDetails.id === item.id)
                    item = itemDetails;
                });
              } else {
                this.userCartData = [itemDetails];
              }
              this.userCartData = [...this.userCartData];
            }
          }
        }
      });
    }
    else {

      if (operation === "INCREASE") itemDetails.itemQuantity++;
      else itemDetails.itemQuantity--;

      itemDetails.showItemQuantity = itemDetails.itemQuantity;


      this.userCartData = this.userCartData.filter(item => itemDetails.id !== item.id);

      if (itemDetails.itemQuantity > 0) {
        this.userCartData = [...this.userCartData, itemDetails];
        // this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, false).then((response: any) => {

        //   // if (!response) {
        //   //   itemDetails.itemQuantity--;
        //   //   itemDetails.showItemQuantity--;
        //   //   this.userCartData.pop();
        //   // }
        // });
      }
      this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, false);

      if (this.userCartData.length === 0) {
        this.storageService.clearAllItemsFromCart().then(() => {
          this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
        });
      }
    }

    // if (itemDetails.customizationRequired) {

    //   this.sharedUtilsService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails }).afterDismissed().subscribe((response: any) => {
    //     if (response.status === "SUCCESS") {
    //       itemDetails.itemQuantity++;
    //       // itemDetails = {
    //       //   ...itemDetails,
    //       //   orderedItemCustomizations: response.selectedCustomizationList
    //       // };
    //       itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;
    //       if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId))
    //         itemDetails.itemQuantity--;
    //     }
    //   });
    // }
    // else {

    //   if (operation === "INCREASE") itemDetails.itemQuantity++;
    //   else itemDetails.itemQuantity--;


    //   this.userCartData = this.userCartData.filter(item => itemDetails.id !== item.id);

    //   if (itemDetails.itemQuantity > 0) {
    //     this.userCartData = [...this.userCartData, itemDetails];
    //     this.storageService.addObject({ ...itemDetails } as Item).then((response: any) => {
    //       if (!response) {
    //         itemDetails.itemQuantity--;
    //         this.userCartData.pop();
    //       }
    //     });
    //   }

    //   if (this.userCartData.length === 0)
    //     this.storageService.clearAllItemsFromCart().then(() => {
    //       this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
    //     });
    // }
  }
}
