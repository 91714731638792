import { Component, HostListener, Inject, Optional } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { UserProfileService } from '../user-profile.service';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared-services/shared.service';
import { NotificationViewComponent } from '../shared-component/notification-view/notification-view.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


class notificationStatus {
  name: string | null;
  value: string | null;
  status: boolean | null;

  constructor(
  ) {
    this.name = null;
    this.value = null;
    this.status = null;
  }
}

@Component({
  selector: 'app-notification-listing',
  templateUrl: './notification-listing.component.html',
  styleUrls: ['./notification-listing.component.scss', './mq-notification-listing.component.scss']
})
export class NotificationListingComponent {

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private datePipe: DatePipe,
    public platform: Platform,
    private sharedLocalStorageService: SharedService,
    private bottomSheet: MatBottomSheet,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogRef: MatDialogRef<NotificationListingComponent>,
  ) {
    this.getScreenSize();
  }


  userId: any;
  userName: any;
  residentUserId: any;
  ngOnInit() {
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    this.residentUserId = this.sharedLocalStorageService.getFromLocalStorage("residentUserId");
    this.checkParam()
    // this.getUserNotificationsList();
    // this.getUserNotificationListFromOldERP();
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  goToProfilePage() {
    this.router.navigate(['/profile']);
  }


  notificationData: any = [
    { title: 'Today', list: [] },
    { title: 'Yesterday', list: [] },
    { title: 'Last 7 days', list: [] },
  ];

  showLoader: boolean = false;
  getUserNotificationsList() {
    this.showLoader = true;

    const currentDate = new Date().getTime();

    const payload = {
      userId: this.userId,
      startDate: this.datePipe.transform(currentDate - (7 * 24 * 60 * 60 * 1000), 'dd-MM-yyyy'),
      endDate: this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    }

    this.userProfileService.getUserNotifications(payload).subscribe((response: any) => {
      if (response.data && response.data.length > 0) {
        const todayDate = new Date().setHours(0, 0, 0, 0); // returns in milliseconds

        this.notificationData[0].list = [];
        this.notificationData[1].list = [];
        this.notificationData[2].list = [];

        response.data.forEach((element: any) => {
          element.showMore = false;
          element.updateTimeStamp = new Date(new Date(element.updateTimeStamp).getTime() - 19800000);
          const gettingDate = new Date(element.updateTimeStamp).setHours(0, 0, 0, 0);
          if (todayDate === gettingDate) {
            this.notificationData[0].list.push(element);
          } else if ((todayDate - (24 * 60 * 60 * 1000)) === gettingDate) {
            this.notificationData[1].list.push(element);
          } else {
            this.notificationData[2].list.push(element);
          }
        })
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        // this.userProfileService.snackBarMethod("Failed to get notifications, please try again");
      })
  }


  oldNotificationData: any = [];
  getUserNotificationListFromOldERP() {
    this.showLoader = true;
    this.userProfileService.getNotificationForOldErp(this.residentUserId).subscribe((response: any) => {
      this.showLoader = false;
      this.oldNotificationData = response.Result;
    },
      (error) => {
        this.showLoader = false;
        // this.userProfileService.snackBarMethod("Failed to get notifications, please try again");
      })
  }


  notificationClicked(notificationId: string, showLoader = true) {
    const userId = this.userId;

    if (userId) {
      const userName = this.userName;

      let payload = {
        notificationId: notificationId,
        userId: userId,
        date: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      }

      this.userProfileService.updateNotificationClicked(payload).subscribe((response: any) => {
        this.userProfileService.setNotificationClick();

        // this.landingService.openSnackBarMessage(`Welcome ${userName ? userName : ''}`);
      },
        (error) => {
        },
        () => {
          if (this.showLoader) this.showLoader = true;
          this.getUserNotificationsList();
        })
    }
  }


  showNotificationDetails(item: any) {
    if (!item.clicked) {
      this.notificationClicked(item.customNotificationRequest.notificationId, false);
    }

    if (this.screenWidth > 480) {
      item.showMore = !item.showMore;
      if (item.customNotificationRequest.orderId) {
        this.sharedLocalStorageService.storeInLocalStorage("orderId", item.customNotificationRequest.orderId);
        this.dialogRef.close();
        this.router.navigate(['/profile/tab'], { queryParams: { type: "ORDER_SUMMARY" } });
      }
    } else {
      this.bottomSheet.open(NotificationViewComponent, {
        data: { notificationData: item.customNotificationRequest, type: 'CODINING' },
        panelClass: 'custom-notification-bottom-sheet',
      });
    }
  }

  showOldNotificationDetails(item: any) {
    // if (!item.clicked) {
    //   this.notificationClicked(item.PushNotificationLog_Id, false);
    // }

    if (this.screenWidth > 480) {
      item.showMore = !item.showMore;
    } else {
      this.bottomSheet.open(NotificationViewComponent, {
        data: { notificationData: item, type: 'COLIVING' },
        panelClass: 'custom-notification-bottom-sheet',
      });
    }
  }


  notificationStatusListing: notificationStatus[] = [
    { name: 'Multi Brand Diner', value: 'MBD', status: true },
    { name: 'Coliving', value: "COLIVING", status: false }
  ]

  selectedNotificationStatusType: any = 'MBD';
  getOrderListByOrderStatus(selectedNotificationStatusType: notificationStatus) {
    this.notificationStatusListing.forEach((notificationStatus: notificationStatus) => {
      if (notificationStatus.value === selectedNotificationStatusType.value) {
        notificationStatus.status = true;
        this.selectedNotificationStatusType = notificationStatus.value;
      } else {
        notificationStatus.status = false;
      }
    })
    this.checkParam();
  }

  checkParam() {
    if (this.selectedNotificationStatusType === 'CODINING') {
      this.getUserNotificationsList();
    }
    else {
      this.getUserNotificationListFromOldERP();
    }
  }


  closeButton() {
    this.dialogRef.close();
  }

  goToStore() {
    // let appDownloadLink = '';

    // if (this.platform.ANDROID) {
    //   appDownloadLink =
    //     'https://play.google.com/store/apps/details?id=com.isthara.partner';
    // } else if (this.platform.IOS) {
    //   // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
    //   appDownloadLink = 'https://play.google.com/store/apps/details?id=com.isthara.partner';
    // }

    // const aElement = document.createElement('a');
    // aElement.href = appDownloadLink;
    // aElement.click();
    // this.closeBottomSheet();
  }

}
