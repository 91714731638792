<section class="warning-container">
    <ng-container *ngIf="dialogType === 'MULTIPLE_FOODCOURT'">
        <div class="heading-container">
            <div class="heading__left-sec">Info</div>
            <div class="heading__right-sec" (click)="closeDialogBox()">
                <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="">
            </div>
        </div>
        <div class="dialog-content">
            <div class="content-text">You already have items from another foodcourt.</div>
            <ul>
                <li class="left-btn-desc">Click Clear to clear previous foodcourt items</li>
                <li class="right-btn-desc">Click Close to close the Warning popup</li>
            </ul>
        </div>
        <div class="footer-container">
            <button class="left-btn" (click)="clearCartItems()" [disabled]="clickedBtn"
                *ngIf="!clickedBtn || clickedBtn!=='CLOSE'">Clear</button>
            <button class="right-btn" (click)="closeDialogBox()" [disabled]="clickedBtn"
                *ngIf="!clickedBtn || clickedBtn!=='CLEAR'">Close</button>
            <button class="left-btn" *ngIf="clickedBtn" [disabled]="true">Loading...</button>
        </div>
    </ng-container>
</section>