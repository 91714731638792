import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedUtilsService } from '../../modules/codining/shared-services/shared-utils.service';
import { CodiningSpacesComponent } from '../../modules/codining/shared-components/codining-spaces/codining-spaces.component';
import { SharedService } from '../../shared-services/shared.service';
import { EnquiryFormComponent } from '../enquiry-form/enquiry-form.component';
import { CodiningService } from '../../modules/codining/codining.service';

@Component({
  selector: 'app-header-sec',
  templateUrl: './header-sec.component.html',
  styleUrls: ['./header-sec.component.scss', './mq-header-sec.component.scss']
})
export class HeaderSecComponent implements OnInit, OnChanges {

  constructor(
    private _bottomSheet: MatBottomSheet,
    private activatedRoute: ActivatedRoute,
    private sharedLocalStorageService: SharedService,
    private sharedUtilsService: SharedUtilsService,
    private router: Router,
    private codiningService: CodiningService
  ) { }

  @Output() toggleSideBar = new EventEmitter();
  @Output() navbarSelected = new EventEmitter();
  @Input() selectedPath: string = '';

  currentUrl: string = '';
  userId: string | null = null;
  userName: string | null = null;
  profilePicture: string | null = null;

  topNavbarListing: any[] = [
    { name: "Home", value: "HOME", url: 'home', checkingParam: 'home' },
    // { name: "Co-Dining", value: "DINE_WITH_US", url: 'home/dine-with-us', checkingParam: 'dine-with-us' },
    { name: "Multi Brand Diner", value: "MBD", url: 'mbd', checkingParam: 'foodcourt' },
    { name: "Co-Living", value: "CO_LIVING", url: 'coliving', checkingParam: 'coliving' },
    { name: "Partnerships", value: "PARTNERSHIP", url: 'home/partner-with-us', checkingParam: 'partner-with-us' },
    { name: "About Us", value: "ABOUT_US", url: 'home/about-us', checkingParam: 'about-us' },
    // { name: "Profile", value: "PROFILE", url: 'profile' }
  ];

  screenWidth: number = 0;

  @HostListener('window:resize', ['$event'])
  getScreenWidth(): void {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.selectedFoodCourt = {
      name: this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "Select Multi Brand Diner",
      location: this.sharedLocalStorageService.getFromLocalStorage("siteName"),
    }

  }

  goToHomePage() {
    this.router.navigate(['/home']);
  }

  ngOnInit(): void {

    this.userName = this.sharedLocalStorageService.getFromLocalStorage("userName");
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    this.profilePicture = this.sharedLocalStorageService.getFromLocalStorage("profilePicture")

    let foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "Select Multi Brand Diner";
    let siteName = this.sharedLocalStorageService.getFromLocalStorage("siteName");

    this.currentUrl = this.router.url;
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url;
        // const url = ev.url;
        // const splitterURL = url.split('/');
        // this.currentUrl = splitterURL[splitterURL.length - 1];
        if (this.currentUrl.includes('mbd') && this.currentUrl.split("/").length === 3) {
          foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "Select Multi Brand Diner";
          siteName = this.sharedLocalStorageService.getFromLocalStorage("siteName");

          // this.selectedFoodCourt = {
          //   name: "Select Codinig Place",
          //   location: "",
          // }
        }
        if (foodCourtName && siteName) {
          this.selectedFoodCourt = {
            name: foodCourtName,
            location: siteName
          }
        }
        if (this.currentUrl.includes("cart")) {
          this.getFoodCourtDetails();
        }
       
        //
      }
    });
    if (this.currentUrl.includes("cart")) {
      this.getFoodCourtDetails();
    }
    this.pathChecking();

    this.getScreenWidth();
    this.getAllFoodCourtList();

  }

  getFoodCourtDetails() {
    const foodcourtId = this.sharedLocalStorageService.getFromLocalStorage("orderingFCId");
    this.codiningService.getFoodocurtByFoodcourtId(foodcourtId).subscribe((response: any)=> {
    this.selectedFoodCourt = {
        name: response.data.foodCourtName,
        location: response.data.siteName
      }
    })
  }
  pathChecking() {
    this.activatedRoute.queryParams.subscribe((qParams: any) => {
      const url = this.router.url.replace("/", "");
      this.topNavbarListing.forEach((navBar: any) => {
        if (navBar.url === url)
          this.selectedPath = url;
      })
    })
  }

  goToProfilePage() {
    this.router.navigate(['/profile/tab'], { queryParams: { type: 'MY_ORDER' } });
  }

  selectedFoodCourt = {
    name: "Select Multi Brand Diner",
    location: ""
  }

  navbarToggle: boolean = false;
  foodCourtListing: any[] = [];

  openCodiningSpaceBottomSheet() {
    this._bottomSheet.open(CodiningSpacesComponent, { data: { foodCourtList: this.foodCourtListing } }).afterDismissed().subscribe((response: any) => {
      this.selectedFoodCourt = {
        name: this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "Select Multi Brand Diner",
        location: this.sharedLocalStorageService.getFromLocalStorage("siteName"),
      }
    });
  }

  goToLoginPage() {
    this.router.navigate(['/auth']);
  }

  routePath(url: any) {
    this.selectedPath = url.url;
    this.navbarSelected.emit();
    if (url.url === 'mbd') {
      let foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
      let foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
      if (foodCourtId) {
        this.router.navigate([`/mbd/${foodCourtName}`]);
      } else {
        this.router.navigate([`/mbd/foodcourt`]);
      }
    } else {

      this.router.navigate([`/${url.url}`]);
    }
  }

  getAllFoodCourtList(): void {
    this.sharedUtilsService.getAllFoodCourts().subscribe((response: any) => {
      this.foodCourtListing = response.data.filter((foodCourt: any) => foodCourt.active);
      // this.foodCourtListing = response.data;
    }, (error: any) => {

    })
  }

  toggleSideNav() {
    this.toggleSideBar.emit(true);
  }

  openEnquiryForm() {
    // this.sharedUtilsService.openDialogConfig(EnquiryFormComponent, '80vh', '30vw');
    this.router.navigate(['/enquiry'])
  }

  openSearchPage() {
    this.router.navigate(['/mbd/search']);
  }
}
