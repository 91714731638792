import { Component, HostListener, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { OrderService } from '../../shared-services/order-service.service';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-coupons-listing',
  templateUrl: './coupons-listing.component.html',
  styleUrls: ['./coupons-listing.component.scss', './mq-coupons-listing.component.scss']
})
export class CouponsListingComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService,
    private orderService: OrderService,
    private sharedUtilService: SharedUtilsService,
    @Optional() private dialogRef: MatDialogRef<CouponsListingComponent>
  ) { }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  userId: string = '';
  ngOnInit(): void {
    this.userId = this.sharedLocalStorageService.getFromLocalStorage('userId');
    this.getCouponListing();
    this.getScreenSize();
  }

  couponList: any[] = [];
  enteredCouponCode: string | null = null;
  getCouponListing() {
    this.orderService.getCouponsByUserId(this.userId).subscribe((response: any) => {
      if (response.data.length > 0) {

        this.couponList = response.data.map((content: any) => {
          return {
            ...content,
            status: false
          }
        });
      } else {
        this.couponList = [];
      }
    }, (error: any) => {

    })
  }

  applyCouponCode() {
    let couponDetail;
    this.couponList.forEach((coupon: any) => {
      if (coupon.discountCouponCode.toLowerCase() === this.enteredCouponCode?.toLocaleLowerCase())
        couponDetail = coupon;
    })

    if (couponDetail) {
      this.applyCoupon(couponDetail);
    } else {

    }
  }

  applyCoupon(couponDetails: any) {

    if (!couponDetails.applicable) {
      return;
    }

    this.orderService.applyCouponToUser(this.userId, couponDetails.discountId).subscribe((response: any) => {
      this.goToCartPage();
    }, (error: any) => {
      this.sharedUtilService.openSnackBar("Failed to apply Coupon", "Close");
    })
  }

  goToCartPage() {
    if (this.screenWidth <= 480)
      this.router.navigate(['/mbd/cart']);
    else
      this.dialogRef.close();
  }
}
