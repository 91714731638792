<section class="spoc-section">
  <!-- <div class="spoc-header" *ngIf="screenWidth <= 480">
    <img loading="lazy" src="../../../../assets/codining/images/common/back_arrow.svg" alt="back">
    <div class="header-title">Contact Us</div>
  </div>
  <div class="spoc-header-tab" *ngIf="screenWidth > 480">
    Contact Us
  </div> -->


  <div class="staff-details-listing">
    <div class="staff-detail-title">
      <div class="text">Your Helping People</div>
      <div class="close-img">
        <img src="../../../assets/codining/images/common/close.svg" alt="close" (click)="closeDialog()">
      </div>
    </div>
    <div class="staff-details-list">
      <div class="staff-detail">
        <div class="staff-details__key-value">
          <div class="key">Isthara Helpline</div>
          <div class="value">
            <img loading="lazy" src="../../../../assets/codining/images/common/user-icon.svg" alt="user icon">
          </div>
        </div>
        <div class="staff-details-divider"></div>
        <div class="staff-details__key-value">
          <a class="key" href="tel: 8790858581">8790858581</a>
          <div class="value">
            <img loading="lazy" src="../../../../assets/codining/images/common/phonecall.svg" alt="phone icon">
          </div>
        </div>
      </div>