import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Item } from '../../entities/Item.model';
import { OrderedItemCustomizations } from '../../entities/OrderedItemCustomization.model';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { CodiningService } from '../../shared-services/codining-service.service';
import { StorageServiceService } from '../../shared-services/storage-service.service';

@Component({
  selector: 'app-item-customization',
  templateUrl: './item-customization.component.html',
  styleUrls: ['./item-customization.component.scss']
})
export class ItemCustomizationComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private sharedUtilService: SharedUtilsService,
    private codiningService: CodiningService,
    private storageService: StorageServiceService
  ) { }

  createNewId: boolean = false;
  itemCustomizationDetail!: Item;
  selectedCustomizationList: OrderedItemCustomizations[] = [];
  finalPrice: number = 0;
  itemSelected: string | null = null;

  operationType: "INCREASE" | "DECREASE" = "INCREASE";

  customizedItemList: any[] = [];
  selectedCustomizationToRemove: any[] = [];

  userCartData: any;

  ngOnInit(): void {
    this.itemCustomizationDetail = this.bottomSheetData.data;
    this.operationType = this.bottomSheetData.type;
    this.finalPrice = this.bottomSheetData.data.markUpPrice;

    if (this.operationType === "DECREASE") {
      const request = this.storageService.getUserCart();
      request.onsuccess = () => {
        this.userCartData = request.result;
        this.customizedItemList = this.userCartData.filter((itemDetail: any) => {
          return itemDetail.id === this.itemCustomizationDetail.id;
        });
      }
    }
    else {
      this.itemCustomizationDetail?.menuCustomizationList.forEach((customization: any) => {
        customization.noOfSelectedItem = 0;
        customization.selectedCustomizationId = null;
        customization.customizedItemsForMenuList = customization?.customizedItemsForMenuList.map((customItem: any) => {
          if (customItem.isDefault) {
            if (customization.maxOptionAllowed === 1)
              customization.selectedCustomizationId = customItem.uniqueId;
            else {
              if (!customization.selectedCustomizationId)
                customization.selectedCustomizationId = [];
              customization.selectedCustomizationId.push(customItem.uniqueId);
            }
          }

          return { ...customItem, selected: customItem.isDefault && customItem.active };
        })
      })
    }

    this.calculateCustomizationPrice();

  }

  removeCustomItem() {
    this.userCartData = this.userCartData.filter((item: any) => {
      return !this.selectedCustomizationToRemove.includes(item.appItemId);
    })
    this.storageService.clearAllItemsFromCart().then(() => {
      this.userCartData.forEach((item: any) => {
        this.storageService.addItem(item, false);
      })
    })
  }

  addItemToRemove(event: any, customItem: any) {

    if (event.target.checked) {
      this.selectedCustomizationToRemove.push(customItem);
    } else {
      this.selectedCustomizationToRemove = this.selectedCustomizationToRemove.filter((item: any) => item.appItemId === customItem.appItemId);
    }
  }

  changeCustomization(customization: any, customItem: any): void {
    customization.customizedItemsForMenuList.forEach((cItem: any) => {
      cItem.selected = (cItem.uniqueId === customItem.uniqueId);
    });
    this.calculateCustomizationPrice();
  }

  calculateCustomizationPrice() {
    let selectedItem = 0;
    this.finalPrice = this.bottomSheetData.data.markUpPrice;
    this.itemCustomizationDetail?.menuCustomizationList.forEach((customization: any) => {
      customization?.customizedItemsForMenuList.forEach((customItem: any) => {
        this.finalPrice += (customItem.selected ? customItem.markupPrice : 0);
          if(customItem.selected){
            selectedItem += 1;
          }
      })
    })
    this.itemCustomizationDetail?.menuCustomizationList.forEach((customization: any) => {
      customization?.customizedItemsForMenuList.forEach((customItem: any) => {
          if(selectedItem === customization.maxOptionAllowed){
            if(customItem.selected){
              customItem.disabled = false;
            }
            else{
              customItem.disabled = true;
            }
          }
          else{
              customItem.disabled = false;
          }
      })
    });
  }

  addItemToCart() {
    // Check if any required feild is empty
    this.itemCustomizationDetail?.menuCustomizationList.forEach((customization: any) => {
      if (customization.required && customization.customizedItemsForMenuList.every((customItem: any) => !customItem.selected)) {
        this.sharedUtilService.openSnackBar("Please select required field", "CLOSE", "WARNING");
        return;
      } else {
        customization.customizedItemsForMenuList.forEach((customItem: any) => {

          if (customItem.selected) {
            let data = {
              "active": true,
              "markupPrice": customItem.markupPrice,
              "category": "CUSTOMIZE",
              "categoryId": customItem.uniqueId,
              "name": customItem.subTitle,
              "packagingCharge": customItem.packingCharge,
              "price": customItem.price,
              "type": (customization.type ? customization.type : null)
            }
            this.selectedCustomizationList.push(data);
          }
        })
        const request = this.codiningService.checkSameCustomizationForItem();

        request.onsuccess = (response: any) => {

          if (request.result.length === 0) {

            this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: true, error: 'true' });
          }

          request.result.forEach((cartItem: any) => {
            if (cartItem.itemId === this.itemCustomizationDetail.id || cartItem.itemId === this.itemCustomizationDetail.itemId) {
              if (cartItem.orderedItemCustomizations.length === this.selectedCustomizationList.length) {
                let cartItemCategoryIds = "";
                let itemCategoryIds = "";
                this.selectedCustomizationList.forEach((element: any) => {
                  itemCategoryIds += element.categoryId;
                })
                cartItem.orderedItemCustomizations.forEach((element: any) => {
                  cartItemCategoryIds += element.categoryId;
                })

                if (cartItemCategoryIds === itemCategoryIds) {

                  this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: false });
                } else {

                  this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: true });
                }
              } else {

                this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: true });
              }
            }
          });

          this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: true, error: 'false' });
        };
        request.onerror = (error: any) => {
          this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.selectedCustomizationList, createNewId: true });
        }
      }

    })
  }

  choseNewCustomization() {
    this.createNewId = true;
  }

  repeatCustomization() {

    this.bottomSheetRef.dismiss({ status: "SUCCESS", selectedCustomizationList: this.itemCustomizationDetail.orderedItemCustomizations, createNewId: false });
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }
}
