import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from '../../shared-services/order-service.service';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { StorageServiceService } from '../../shared-services/storage-service.service';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss', './mq-warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<WarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private orderService: OrderService,
    private sharedLocalStorageService: SharedService,
    private storageService: StorageServiceService
  ) { }

  userId: string | null = null;
  dialogType: string = "MULTIPLE_FOODCOURT";
  clickedBtn: string | null = null;

  ngOnInit(): void {
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
  }

  clearCartItems() {
    this.clickedBtn = "CLEAR";

    if (this.userId) {

      this.orderService.deleteItemFromCart(this.userId).subscribe((response: any) => {
        this.storageService.clearAllItemsFromCart().then(() => {
          this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
          this.dialogRef.close({ status: "SUCCESS" });
        })
      }, (error: any) => {
        this.sharedLocalStorageService.openSnackBar("Failed to Remove Item from Cart");
      }, () => {
        this.clickedBtn = null;
      })
    } else {
      this.storageService.clearAllItemsFromCart().then(() => {
        this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
        this.dialogRef.close({ status: "SUCCESS" });
      })
    }
  }

  closeDialogBox() {
    this.dialogRef.close({ status: "CLOSE" });
  }

}
