import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ChooseMealSpinnerComponent } from '../../shared-components/choose-meal-spinner/choose-meal-spinner.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CodiningSpacesComponent } from '../../shared-components/codining-spaces/codining-spaces.component';
import { StorageServiceService } from '../../shared-services/storage-service.service';
import { HomePageService } from './home-page.service';
import { Item } from '../../entities/Item.model';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { ItemCustomizationComponent } from '../../shared-components/item-customization/item-customization.component';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { OneClickDialogComponent } from '../../shared-components/one-click-dialog/one-click-dialog.component';
import { CodiningService } from '../../shared-services/codining-service.service';
import { CartService } from '../../shared-services/cart.service';
import { CuisineResturantComponent } from '../cuisine-resturant/cuisine-resturant.component';
import { BannerDetailsComponent } from '../../shared-components/banner-details/banner-details.component';

interface CuisineContainer {
  cuisine: string;
  imgUrl: string;
  backgroundColor: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss', './mq-home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageServiceService,
    private homePageService: HomePageService,
    private sharedUtilsService: SharedUtilsService,
    private sharedLocalStorageService: SharedService,
    private codiningService: CodiningService,
    private cartService: CartService
  ) { }

  foodCourtId: string | null = null;

  homePageResponse: any;
  homePageLoader: boolean = true;
  userCartData: Item[] = [];
  cuisineList: string[] = ['#F5ECFF', '#DFF4FF', '#D4F6F0', '#FCE3E4', '#F5ECFF', '#DFF4FF', '#F5ECFF', '#F5ECFF', '#F5ECFF', '#F5ECFF'];
  cusineListLength: number = 0;

  eventIntervalPointer: any;

  vendorDiscountPositionFromLeft: number = 0;
  positionFromLeft: number = 0;
  eventPositionFromLeft: number = 0;

  bannerImageFromLeft: number = 0;

  ngOnInit() {

    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");

    setInterval(() => {
      this.bannerImageFromLeft += 100;
    }, 8000);

    this.getHomePageData();
    this.getUserCart();
    this.getScreenWidth();

    this.activatedRoute.params.subscribe((params: any) => {
      this.getHomePageData();
    });

    this.cartService.fetchAllCartItem.subscribe(() => {
      this.getUserCart();
    })
  }

  goToBanner(index: number) {
    index %= this.homePageResponse.promotionalBannerList.length;
    this.bannerImageFromLeft += (index * 100);
  }

  slideConfig = {
    "slidesToShow": 1,
    "centerMode": true,
    "slidesToScroll": 1,
    "infinite": true,
    "autoplay": true,
    "variableWidth": true,
    "autoplaySpeed": 3000,
    "dots": true,
    "arrows": true,
    "pauseOnHover": false
  };

  screenWidth: number = 0;

  @HostListener('window:resize', ['$event'])
  getScreenWidth(): void {
    this.screenWidth = window.innerWidth;
  }

  goToSearchPage() {
    this.router.navigate(['/mbd/search']);
  }

  cuisineLength: number = 0;
  getHomePageData() {

    this.homePageLoader = true;

    let foodCourtId: string | null = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    let userId: string | null = this.sharedLocalStorageService.getFromLocalStorage("userId");


    if (!foodCourtId) {
      // console.log("FoodCourt Id not found");
      this.router.navigate(['/mbd']);
      return;
    }

    this.homePageService.getHomePageResponse(foodCourtId, userId).subscribe((response: any) => {
      this.homePageResponse = response.data;
      console.log(this.homePageResponse, 'checking')

      this.homePageResponse.quickBitesList = this.homePageResponse.quickBitesList.filter((item: any) => item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE');
      this.homePageResponse.trendingtodayList = this.homePageResponse.trendingtodayList.filter((item: any) => item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE');
      this.homePageResponse.promotionalBannerList = this.homePageResponse.promotionalBannerList.filter((item:any) => (item.platform.includes('WEB') || item.platform.includes('MOBILE')) && (item.bannerVertical === 'BOTH' || item.bannerVertical === 'FOOD_COURT'));

      this.homePageResponse.quickBitesList.forEach((item: any) => {
        item.itemQuantity = 0;
        item.showItemQuantity = 0;
        item.orderedItemCustomizations = [];
      });
      this.homePageResponse.trendingtodayList.forEach((item: any) => {
        item.itemQuantity = 0;
        item.showItemQuantity = 0;
        item.orderedItemCustomizations = [];
      });


      this.cusineListLength = Math.floor(this.homePageResponse.cuisineList.length / 2);

      this.eventIntervalPointer = setInterval(() => {
        this.eventPositionFromLeft += 100;
        this.eventPositionFromLeft %= this.homePageResponse.eventsList.length;

        this.vendorDiscountPositionFromLeft += 100;

      }, 5000);

    }, (error: any) => {

    }, () => {
      this.homePageLoader = false;
      this.updateItemQuantity();
    })
  }

  openBottomSheet() {
    this.sharedUtilsService.openBottomSheet(ChooseMealSpinnerComponent);
  }

  getUserCart() {
    const request = this.storageService.getUserCart();
    request.onsuccess = (response: any) => {
      this.userCartData = request.result;
    };
    request.onerror = (error: any) => { }
  }

  openBannerDetails(bannerDetails: any) {

    if (bannerDetails.promotionType === 'VENDOR_LAUNCH') {
      this.sharedLocalStorageService.storeInLocalStorage('vendorId', bannerDetails.mappedId);
      const foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
      this.router.navigate([`/mbd/${foodCourtName}/resturant`]);
    } else if (bannerDetails.promotionType === 'OFFERS_DISCOUNT') {

      this.sharedUtilsService.openBottomSheet(BannerDetailsComponent);
    }
  }

  moveInTestimonial(movePosition: "LEFT" | "RIGHT") {

    if (movePosition === "LEFT")
      this.positionFromLeft -= 100;
    else
      this.positionFromLeft += 100;
  }

  updateItemQuantity() {
    this.homePageResponse.quickBitesList.forEach((item: any) => {

      this.userCartData.forEach((userCartItem: any) => {
        if (userCartItem.id === item.id) {
          item.showItemQuantity += userCartItem.itemQuantity;
          item.itemQuantity = userCartItem.itemQuantity;
          item.orderedItemCustomizations = userCartItem.orderedItemCustomizations;
        }
      })

    });

    this.homePageResponse.trendingtodayList.forEach((item: any) => {

      this.userCartData.forEach((userCartItem: any) => {
        if (userCartItem.id === item.id) {
          item.showItemQuantity += userCartItem.itemQuantity;
          item.itemQuantity = userCartItem.itemQuantity;
          item.orderedItemCustomizations = userCartItem.orderedItemCustomizations;
        }
      })
    });
  }

  openCodiningSpaceBottomSheet() {
    this.sharedUtilsService.openBottomSheet(CodiningSpacesComponent).afterDismissed().subscribe((response: any) => {
      this.getHomePageData();
    });
  }
  openOneClickBottomSheet(employerMenu: any, bottomSheetType: "QR_CODE" | "PAY_NOW") {
    this.sharedUtilsService.openBottomSheet(OneClickDialogComponent, { bottomSheetType: bottomSheetType, employerMenu: employerMenu }).afterDismissed().subscribe((response: any) => {
      this.getHomePageData();
    });
  }

  goToResturantPage(vendorDetails: any) {
    this.sharedLocalStorageService.storeInLocalStorage("vendorId", vendorDetails.vendorId);
    const foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
    this.router.navigate([`/mbd/${foodCourtName}/resturant`]);
  }

  goToGuestOrderingFlow() {

    const foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
    this.router.navigate([`/mbd/${foodCourtName}/guest-order`]);
  }

  addItemToCart(itemDetails: any, operation: "INCREASE" | "DECREASE") {

    if (itemDetails.customizationRequired) {

      if (operation === "DECREASE") {
        this.router.navigate(['/mbd/cart']);
        return;
      }
      this.sharedUtilsService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails, type: operation }).afterDismissed().subscribe((response: any) => {
        if (response.status === "SUCCESS") {
          itemDetails.itemQuantity++;
          itemDetails.showItemQuantity++;
          if (response.createNewId)
            itemDetails.itemQuantity = 1;

          // itemDetails = {
          //   ...itemDetails,
          //   orderedItemCustomizations: response.selectedCustomizationList
          // };
          itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;

          if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId, true)) {
            itemDetails.itemQuantity--;
            itemDetails.showItemQuantity--;
          }
          // else {

          //   this.getUserCart();
          // }
          else {
            if (response.createNewId) {
              this.userCartData = [...this.userCartData, itemDetails];
            } else {
              if (this.userCartData.length > 0) {
                this.userCartData.forEach((item: any) => {
                  if (itemDetails.id === item.id)
                    item = itemDetails;
                });
              } else {
                this.userCartData = [itemDetails];
              }
              this.userCartData = [...this.userCartData];
            }
          }
        }
      });
    }
    else {

      if (operation === "INCREASE") itemDetails.itemQuantity++;
      else itemDetails.itemQuantity--;

      itemDetails.showItemQuantity = itemDetails.itemQuantity;


      this.userCartData = this.userCartData.filter(item => itemDetails.id !== item.id);

      if (itemDetails.itemQuantity > 0) {
        this.userCartData = [...this.userCartData, itemDetails];

        // this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, false).then((response: any) => {

        //   // if (!response) {
        //   //   itemDetails.itemQuantity--;
        //   //   itemDetails.showItemQuantity--;
        //   //   this.userCartData.pop();
        //   // }
        // });
      }
      this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, false);

      if (this.userCartData.length === 0) {
        this.storageService.clearAllItemsFromCart().then(() => {
          this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
        });
      }
    }
  }

  scrollLeft(listingType: "SPOTLIGHT_ITEM" | "QUICK_BITE_ITEM" | "CUISINE_LISTING") {
    if (listingType === "SPOTLIGHT_ITEM")
      document.getElementsByClassName('spotlight-items-listing')[0].scrollLeft -= 100;
    else if (listingType === "QUICK_BITE_ITEM")
      document.getElementsByClassName('quick-bites__item-list')[0].scrollLeft -= 100;
    else
      document.getElementsByClassName('cuisine-sec__cuisine-list')[0].scrollLeft -= 100;
  }
  scrollRight(listingType: "SPOTLIGHT_ITEM" | "QUICK_BITE_ITEM" | "CUISINE_LISTING") {
    if (listingType === "SPOTLIGHT_ITEM")
      document.getElementsByClassName('spotlight-items-listing')[0].scrollLeft += 100;
    else if (listingType === "QUICK_BITE_ITEM")
      document.getElementsByClassName('quick-bites__item-list')[0].scrollLeft += 100;
    else
      document.getElementsByClassName('cuisine-sec__cuisine-list')[0].scrollLeft += 100;
  }

  goToResturantListingByCuisine(cuisineDetails: any) {
    this.codiningService.setSelectedCuisineDetails(cuisineDetails);
    this.router.navigate(['/mbd/cuisine-resturant']);
  }

  openFoodCourtListing() {
    this.sharedUtilsService.openBottomSheet(CodiningSpacesComponent);
  }

  openEnquiryFormDialog(){
    this.router.navigate(['/enquiry'])
  }
}
