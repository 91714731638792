import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: "root"
})
export class SharedUtilsService {

  constructor(
    private http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private dialogBox: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  getAllFoodCourts() {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/foodCourts`
    );
  }
  searchContent = (func: any, waitTime: number = 5000) => {
    let timeout: any;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(func, waitTime);
    }
  }

  openBottomSheet(component: any, data?: any) {
    return this.bottomSheet.open(component, { data: data });
  }
  openDialogBox(component: any, data?: any) {
    return this.dialogBox.open(component, { data });
  }

  openDialogConfig(component: any, height: any, width: any, data?: any) {
    return this.dialogBox.open(component, {
      height: height,
      width: width,
      data: data
    })
  }

  openSnackBar(message: string, closingText: string, snackBarType: "SUCCESS" | "WARNING" | "ERROR" = "SUCCESS") {
    this.snackBar.open(message, closingText, {
      panelClass: ["warning-snackbar"],
      duration: 4000
    });
  }

  addItemToCart(payload: any) {
    return this.http.put(environment.baseUrl + `/foodcourt/user/ist/cart`, payload);
  }

  getUserPoints(userId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`);
  }


  getPropertyListing(){
    return this.http.get(
      environment.baseUrl + `/property/property/ist/list`
    )
  }

}
