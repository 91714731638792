<section class="one-click-sec" *ngIf="bottomSheetType === 'PAY_NOW'">
  <div class="billing-sec">
    <div class="billing-header">
      <div class="billing-header__left-sec">Bill details</div>
      <div class="billing-header__right-sec" (click)="closeBottomSheet()">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="">
      </div>
    </div>
    <div class="billing-details">
      <div class="billing-breakup">
        <div class="billing-breakup__key">Item Total</div>
        <div class="billing-breakup__value">Rs. {{ employerMenu.markUpPrice | number: '1.0-2' }}</div>
      </div>
      <div class="billing-breakup">
        <div class="billing-breakup__key">Employer Discount</div>
        <div class="billing-breakup__value">Rs. {{ employerMenu.employerMenu[0].paidByEmployer | number: '1.0-2' }}
        </div>
      </div>
      <div class="billing-breakup">
        <div class="billing-breakup__key">Taxes & Charges</div>
        <div class="billing-breakup__value">Rs. {{ employerMenu.markUpTaxValue | number: '1.0-2' }}</div>
      </div>
    </div>
    <div class="billing-sec-divider"></div>
    <div class="user-paying-amount-details">

      <div class="billing-breakup">
        <div class="billing-breakup__key bold">To Pay</div>
        <div class="billing-breakup__value bold">Rs. {{(((employerMenu.markUpPrice >=
          employerMenu.employerMenu[0].paidByEmployer) && (employerMenu.orderCount <
            employerMenu.employerMenu[0].threshold)) ? (employerMenu.markUpPrice -
            employerMenu.employerMenu[0].paidByEmployer) : (employerMenu.markUpPrice)) + employerMenu.markUpTaxValue
            }}</div>
        </div>
        <div class="user-isthara-points">
          <div class="isthara-points-heading">Isthara Wallet</div>
          <div class="isthara-points">
            <div>{{ userPaymentDetail.pointsAvailable }} Reward Points (Rs. {{ userPaymentDetail.pointsAvailable |
              number: '1.0-2' }})</div>
            <mat-slide-toggle (change)="togglePoints($event)"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="oneclick-payment-btn" [style.opacity]="paymentBtnLoader ? '0.3' : '1'"
        (click)="doPaymentForOneClick()">
        <span *ngIf="!paymentBtnLoader">Proceed to Pay Rs.{{ userPaymentDetail.cashUsed | number: '1.0-2' }}</span>
        <span *ngIf="paymentBtnLoader">Loading...</span>
      </div>
    </div>
</section>
<div class="one-click-sec" *ngIf="bottomSheetType === 'QR_CODE'">
  <div class="qr-code-heading">
    <div class="heading-text">
      <div class="heading-primary-text">{{ employerMenu.vendorName | titlecase }} </div>
      <!-- <div class="heading-secondary-text">{{ employerMenu.employerMenu.mealCategory[0] | titlecase }}</div> -->
    </div>
    <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close"
      (click)="closeBottomSheet()">
  </div>
  <qrcode #qrcodeprint [qrdata]="dataSending" [errorCorrectionLevel]="'M'" [margin]="5" [scale]="50" [width]="280">
  </qrcode>
  <div class="qr-code-footer">Enjoy your Meal</div>
</div>