import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CodiningService } from '../../shared-services/codining-service.service';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { environment } from "../../../../../environments/environment";
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";

@Component({
  selector: 'app-one-click-dialog',
  templateUrl: './one-click-dialog.component.html',
  styleUrls: ['./one-click-dialog.component.scss']
})
export class OneClickDialogComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<OneClickDialogComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private codiningService: CodiningService,
    private sharedLocalStorageService: SharedService
  ) { }

  userPaymentDetail = {
    pointsUsed: 0,
    pointsAvailable: 0,
    cashUsed: 0,
    totalAmount: 0
  }

  paymentBtnLoader: boolean = false;
  initalPageLoading: boolean = true;

  foodCourtName: string | null = null;
  username: string | null = null;
  usermobile: string | null = null;
  useremail: string | null = null;


  bottomSheetType: "QR_CODE" | "PAY_NOW" = "PAY_NOW";

  togglePoints(event: any) {
    if (event.checked) {
      if (this.userPaymentDetail.totalAmount > this.userPaymentDetail.pointsAvailable) {
        this.userPaymentDetail.cashUsed = (this.userPaymentDetail.totalAmount - this.userPaymentDetail.pointsAvailable);
      } else {
        this.userPaymentDetail.cashUsed = 0;
        this.userPaymentDetail.pointsUsed = this.userPaymentDetail.totalAmount;
      }
    } else {
      this.userPaymentDetail.cashUsed = this.userPaymentDetail.totalAmount;
      this.userPaymentDetail.pointsUsed = 0;
    }
  }

  getUserPoints() {

    if (!this.userId)
      return;

    this.initalPageLoading = true;
    this.codiningService.getUserPoints(this.userId).subscribe((response: any) => {
      this.userPaymentDetail.pointsAvailable = response.data;
    }, (error: any) => {

    }, () => {
      this.initalPageLoading = false;
    })
  }
  employerMenu: any;
  userId: string | null = null;
  dataSending: any;

  ngOnInit(): void {
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    this.usermobile = this.sharedLocalStorageService.getFromLocalStorage("mobile");
    this.username = this.sharedLocalStorageService.getFromLocalStorage("userName");
    this.useremail = this.sharedLocalStorageService.getFromLocalStorage("userEmail") || "test@gmail.com";

    this.bottomSheetType = this.bottomSheetData.bottomSheetType;
    this.employerMenu = this.bottomSheetData.employerMenu;

    let data = {
      foodCourtId: this.employerMenu.foodCourtId,
      itemsList: [
        {
          imgUrl: null,
          inStock: null,
          itemId: this.employerMenu.id,
          itemName: this.employerMenu.name,
          price: this.employerMenu.markUpPrice,
          veg: null,
          // mrpItem: this.employerMenu.mrpItem,
          taxCategory: this.employerMenu.taxCategory,
          vendorId: this.employerMenu.vendorId,
          vendorName: this.employerMenu.vendorName,
          itemQuantity: 1,
          foodCourtId: this.employerMenu.foodCourtId,
          itemStatus: this.employerMenu.itemStatus,
          itemCategory: this.employerMenu.itemCategory,
          // deliveryAvailable: this.foodCourtDetails.hasDelivery,
        },
      ],
      userId: this.userId,
      userName: this.username,
      orderType: 'DINE_IN',
      mobile: this.usermobile,
      email: this.useremail,
      // deliveryAvailable: this.foodCourtDetails.hasDelivery,
      paymentBY: 'EMPLOYER_PAYMENT',
      mealCategory: this.employerMenu.mealCategory[0],
      billDetails: {
        breakup: {
          "Taxes & charge": 0,
          "Item Total": this.employerMenu.markUpPrice,
          "Item Discount": 0,
          "Delivery Charges": 0,
        },
        finalPrice: this.employerMenu.markUpPrice,
        couponCode: null,
        employeePrice: 0,
        couponId: null,
      },
      address: null,
    };

    this.dataSending = JSON.stringify(data);

    this.employerMenu = this.bottomSheetData.employerMenu;
    this.userPaymentDetail.totalAmount = (((this.employerMenu.markUpPrice >= this.employerMenu.employerMenu[0].paidByEmployer) && (this.employerMenu.orderCount < this.employerMenu.employerMenu[0].threshold))
      ? (this.employerMenu.markUpPrice - this.employerMenu.employerMenu[0].paidByEmployer)
      : (this.employerMenu.markUpPrice)) + this.employerMenu.markUpTaxValue;
    this.userPaymentDetail.cashUsed = this.userPaymentDetail.totalAmount;

    this.getUserPoints();
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  doPaymentForOneClick() {

    if (this.paymentBtnLoader)
      return;

    this.paymentBtnLoader = true;

    const data = {
      address: null,
      billDetails: {
        breakup: {
          'Taxes & charge': this.employerMenu.markUpTaxValue,
          'Item Total': this.employerMenu.markUpPrice,
          'Item Discount': null,
          'Delivery Charges': 0,
          'Packing Charges': 0
        },
        couponCode: null,
        couponId: null,
        employeePrice: this.userPaymentDetail.cashUsed + this.employerMenu.markUpTaxValue,
        finalPrice: this.employerMenu.sellingPrice
      },
      platform: "WEB",
      email: (this.useremail ? this.useremail : "test@gmail.com"),
      foodCourtId: this.employerMenu.foodCourtId,
      deliveryAvailable: false,
      itemsList: [
        {
          imgUrl: this.employerMenu.itemImageUrl,
          inStock: null,
          itemId: this.employerMenu.id,
          itemName: this.employerMenu.name,
          price: this.employerMenu.markUpPrice,
          taxPerItem: this.employerMenu.taxValue,
          taxPercentPerItem: this.employerMenu.taxPercentPerItem,
          packagingCharge: this.employerMenu.packagingCharge,
          veg: null,
          // mrpItem: this.data.orderDetails.mrpItem,
          markUpPrice: this.employerMenu.markUpPrice,
          markUpTaxPerItem: (this.employerMenu.markUpTaxValue ? this.employerMenu.markUpTaxValue : this.employerMenu.markUpTaxPerItem),
          markUpTotalPrice: this.employerMenu.markUpPrice * this.employerMenu.itemQuantity,
          markUpTotalTax: (this.employerMenu.markUpTaxValue ? this.employerMenu.markUpTaxValue : this.employerMenu.markUpTaxPerItem) * this.employerMenu.itemQuantity,

          taxCategory: this.employerMenu.taxCategory,
          vendorId: this.employerMenu.vendorId,
          vendorName: this.employerMenu.vendorName,
          itemQuantity: 1,
          foodCourtId: this.employerMenu.foodCourtId,
          itemStatus: "IN_STOCK",
          itemCategory: this.employerMenu.itemCategory
        },
      ],
      mealCategory: this.employerMenu.mealCategory[0],
      mobile: this.usermobile,
      orderType: 'DINE_IN',
      paymentBY: 'PARTIAL_PAYMENT',
      userId: this.userId,
      userName: this.username,
    };

    if (this.userPaymentDetail.cashUsed === 0) {
      this.payWithIstharaPoints(data);
    } else {
      const mobilePaymentToken = {
        email: (this.useremail ? this.useremail : "test@gmail.com"),
        mobile: this.usermobile,
        // orderAmount: Math.ceil(Number((this.userData.amtPayable * 100).toFixed(2))),
        orderAmount: Math.ceil(Number(((this.userPaymentDetail.cashUsed + this.employerMenu.markUpTaxValue)).toFixed(2))),
        orderNote: 'Order',
        userId: this.userId,
        userName: this.username,
        pointsToBePaid: 0
      };
      this.payWithCashfree(data, mobilePaymentToken);
    }
  }

  payWithIstharaPoints(data: any) {
    const isPointsPayment = true;

    this.codiningService
      .payForOneClickOrderWithPoints(data, this.userPaymentDetail.pointsUsed)
      .subscribe(
        (res: any) => {
          // this.landingComponentService.setOrderId(res.data.orderId);
          // this.bottomSheetRef.dismiss(res.data);
        },
        (err) => {
          // this.landingComponentService.setOrderId(null);
        }
      );
  }

  formDataView: any = {};
  payWithCashfree(cartItems: any, mobilePaymentToken: any) {
    const payload = {
      cartItems: cartItems,
      mobilePaymentToken: mobilePaymentToken,
    };

    this.codiningService
      .payForOneClickOrderWithCashfree(payload)
      .subscribe(
        (pay: any) => {

          // this.landingComponentService.setPaymentFor('ONE_CLICK_PAYMENT');
          // this.landingComponentService.setOrderId(pay.data.fcOrderId);

          this.sharedLocalStorageService.storeInLocalStorage("paymentFor", "ONE_CLICK_PAYMENT");
          const paymentSessionId = pay.data.orderSignature;
          let cashFree;
          if (environment.production) {
            cashFree = new cashfreeProd.Cashfree(paymentSessionId);
          } else {
            cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
          }
          cashFree.redirect();


          // setTimeout(() => {
          //   this.loading = false;
          //   document.forms['redirectForm'].submit();
          // }, 100);
        },
        (err) => {
          // this.landingComponentService.setOrderId(null);
        }
      );
  }
}
