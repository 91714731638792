// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'https://newstage-api.isthara.com',
  baseUrl:'https://newprod-api.isthara.com',
  oldBaseUrl: 'https://erptest.isthara.com',
  // baseUrl: 'https://api.isthara.com',
  cashFreeUrl: 'https://test.cashfree.com/billpay/checkout/post/submit',
  encodingKey: '0123456789123456',
  googleMapsKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
  imageUploadSize: 1000000,
  gtagHyd: 'G-3K6MSER9GX',
  gtagHydConversion: 'G-3K6MSER9GX/_UvoCK',
  sasToken:
    '?sp=racwli&st=2023-07-06T05:50:23Z&se=2030-07-06T13:50:23Z&sv=2022-11-02&sr=c&sig=xzzIxmfnGiKYdMaiacsfo%2Bps%2Bfc4R94U6BrzRNAUeFw%3D',
  accountName: 'istharadev',
  containerName: 'dev-isthara',

  firebase: {
    apiKey: 'AIzaSyD20ru8QZUy34tkgcEFkl8EcWLDJp9HQjg',
    authDomain: 'isthara-7eae1.firebaseapp.com',
    projectId: 'isthara-7eae1',
    storageBucket: 'isthara-7eae1.appspot.com',
    messagingSenderId: '1005405471256',
    appId: '1:1005405471256:web:9847e18b512a180cb3396d',
  },
  application_identity_Key:
    'BGMjIKCVnZt3PG-kqqvPIZ_HoaN5PJaFqCHfRO7aXg0xPsAKEd87FDpElkvkAR67fbQ7PM3b_4HqP05PpLf-O94',

  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'us-east-1:39c0b6a2-7c1e-4452-ab8c-c3e9a79aeafe',
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'dev-isthara',
        Region: 'us-east-1',
      },
    },
  },
  application_identity_key:
    'BMJJPWevldduBseVQ1SFU7QCAFWrmq4JiX5D94dHVflAYsHRTj5CL9UUpRzpJ6T2w7pVanuRxmvM8R4bYt83Mro',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
