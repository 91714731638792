import { ORDER_TYPE } from "../../../constants/enums";
import { CartItem } from "./CartItem.model";
import { UserAddress } from "./UserAddress.model";

class BillDetails {
  finalPrice: number;
  breakup: BreakUp;
  couponId: string | null = null;
  couponCode: string | null = null;

  constructor(finalPrice?: number, breakup?: BreakUp) {
    this.finalPrice = finalPrice || 0;
    this.breakup = breakup || new BreakUp();
  }
}
class BreakUp {
  "Item Total": number = 0;
  "Taxes & charge": number = 0;
  "Item Discount": number = 0;
  "Packing Charges": number = 0;
  "Delivery Charges": number = 0;
}

export class Cart {

  foodCourtId: string;
  siteId: string;
  userName: string;
  mobile: string;
  userId: string;
  orderType: string = "DINE_IN";
  platform: "WEB" = "WEB";
  createdTimestamp: Date;
  updateTimestamp: Date;
  address: UserAddress | null = null;
  billDetails: BillDetails;
  itemsList: CartItem[];

  constructor(foodCourtId?: string, siteId?: string, userName?: string, userId?: string, mobile?: string, orderType?: ORDER_TYPE, address?: UserAddress, billDetails?: BillDetails, itemsList?: CartItem[]) {
    this.foodCourtId = foodCourtId || "";
    this.siteId = siteId || "";
    this.userId = userId || "";
    this.mobile = mobile || "";
    this.userName = userName || "";
    this.createdTimestamp = new Date();
    this.updateTimestamp = new Date();
    this.address = address || null;
    this.billDetails = billDetails || new BillDetails();
    this.itemsList = itemsList || [new CartItem()];
  }
}
