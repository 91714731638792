import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared-services/shared.service';
// import { FaqComponent } from '../faq/faq.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FaqComponent } from '../../modules/website/components/faq/faq.component';
import { ColivingService } from '../../modules/coliving/coliving.service';
import { PropertySearchType, PropertyView } from '../../constants/constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './mq-footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private _colivingService: ColivingService
  ) {
    this.getScreenSize();
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url;
        // console.log("url : ", this.currentUrl === '/' && this.screenWidth <= 480);
      }
    });
  }
  currentUrl: string = '';
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.currentUrl = this.router.url;

    // this.checkVisibility();
    // this.router.events.subscribe((ev) => {
    //   if (ev instanceof NavigationEnd) {
    //     this.currentUrl = ev.url;
    //     console.log("URL ", this.currentUrl);
    //     // const url = ev.url;

    //     // const splitterURL = url.split('/');
    //     // this.currentUrl = splitterURL[splitterURL.length - 1];
    //     // console.log(this.currentUrl);
    //     // de?bugger;
    //     //
    //   }
    // });
  }

  checkVisibility() {
    this.isImageVisible = !(this.currentUrl === '/' && this.screenWidth <= 480);
  }
  isImageVisible: boolean = false;
  redirectToPage(type: string, extraParam?: any) {
    // if (type === 'DINE_WITH_US') this.router.navigate(['/home/dine-with-us']);
    if (type === 'MBD')
      this.router.navigate(['/mbd/foodcourt']);
    else if (type === 'CO_LIVING') this.router.navigate(['/coliving']);
    else if (type === 'ABOUT_US') this.router.navigate(['/home/about-us']);
    else if (type === 'PARTNERSHIP')
      this.router.navigate(['/home/partner-with-us'], {
        queryParams: { extraParam: extraParam },
      });
    else if (type === 'CAREER') this.router.navigate(['/home/career']);
    else if (type === 'FAQ') {
      this.router.navigate(['/home/faq']);
    } else if (type === 'CONTACT_US') {
      if (this.screenWidth > 480) {
        this.sharedService.openDialogConfig(
          ContactUsComponent,
          '40vh',
          '30vw',
          'auto',
          {}
        );
      } else {
        this.sharedService.openDialogConfig(
          ContactUsComponent,
          '35vh',
          '80vw',
          'auto',
          {}
        );
      }
      // if (this.screenWidth > 480) {
      //   this.router.navigate(['/profile/tab'], { queryParams: { type: 'CONTACT_US' } })
      // } else {
      //   this.router.navigate(['/profile'], { queryParams: { type: 'CONTACT_US' } });
      // }
    }
  }

  navigateToStore(type: 'APP_STORE' | 'PLAY_STORE') {
    let appDownloadLink =
      'https://play.google.com/store/apps/details?id=com.tao.isthara';
    if (type === 'APP_STORE') appDownloadLink = 'https://tinyurl.com/5n7vn985';

    window.open(appDownloadLink);
  }

  goToHomePage() {
    this.router.navigate(['/home']);
  }
  goToTermsPage() {
    this.router.navigate(['/home/terms-and-condition']);
  }
  goToPrivacyPolicy() {
    this.router.navigate(['/home/privacy-policy']);
  }

  footerList: any[] = [
    {
      vertical: 'Multi Brand Diner',
      city: [
        {
          name: 'Hyderabad',
          selected: false,
          list: [
            {
              title: 'Multi Brand Diner in Hyderabad',
              sublist: [
                {
                  name: 'IIT Hyderabad Foodcourt',
                  siteId: '3d7ccf81-7371-11ec-81b6-02d12e814022',
                  foodCourtId: '5d8d94d6-7371-11ec-81b6-02d12e814022',
                },
                {
                  name: 'Abdul Kalam Foodcourt',
                  siteId: '6c6c71f5-551a-11ed-8948-02d12e814022',
                  foodCourtId: 'a4422ec4-551a-11ed-8948-02d12e814022',
                },
                {
                  name: 'Mallareddy University Foodcourt 6',
                  siteId: '4e23757b-7dd0-11ec-81b6-02d12e814022',
                  foodCourtId: '01b11fe8-7dd2-11ec-81b6-02d12e814022',
                },
                {
                  name: 'Sohini Foodcourt',
                  siteId: 'cc7486f2-aff0-11ec-bcde-02d12e814022',
                  foodCourtId: 'fa75c0a5-aff0-11ec-bcde-02d12e814022',
                },
              ],
            },
            {
              title: 'MBD Places in Hyderabad',
              sublist: [
                {
                  name: 'DLF Foodcourt',
                  siteId: 'f0b24315-e383-11ed-87a9-0a1b96611e98',
                  foodCourtId: '7f8cb8b2-e384-11ed-87a9-0a1b96611e98',
                },
              ],
            },
            // {
            //   title: "Hostels in Hyderabad",
            //   sublist: [
            //     "Hostels in KPHB",
            //     "Hostels in Gowlidoddi",
            //     "Hostels in Financial District",
            //     "Hostels in Madhapur"
            //   ]
            // }
          ],
        },
        {
          name: 'Delhi Ncr',
          selected: false,
          list: [
            {
              title: 'Multi Brand Diner in Delhi',
              sublist: [
                {
                  name: 'Meerut Expressway Food Court',
                  siteId: '2eddd129-eb15-11ed-a05a-0a1b96611e98',
                  foodCourtId: '7370aa2f-eb15-11ed-8993-0ae944680890',
                },
                {
                  name: 'Trans Haryana Site No-1  Rhs 152',
                  siteId: '8c6990cf-125d-11ee-8f1f-0a1b96611e98',
                  foodCourtId: '69278e56-125f-11ee-b9af-0ae944680890',
                },
                {
                  name: 'BOTANICAL GARDEN FOOD COURT',
                  siteId: 'd010cad4-3e54-11ee-b37c-0a1b96611e98',
                  foodCourtId: 'bd8676d6-3e55-11ee-9e5f-0ae944680890',
                },
                {
                  name: 'IIT Delhi Food Court',
                  siteId:'8996a96b-9d5a-11ee-9e03-6045bda5e089',
                  foodCourtId: 'a4395813-9d5c-11ee-aef5-0022486ea389'
                },
                {
                  name: 'Devika Mall Isthara Food Court',
                  siteId: '0c07407a-4015-11ee-bcf2-0ae944680890',
                  foodCourtId: 'c09a94b6-4015-11ee-bcf2-0ae944680890'
                },
              ],
            },
          ],
        },
        {
          name: 'Tamil Nadu',
          selected: false,
          list: [
            {
              title: 'Multi Brand Diner in Tamil Nadu',
              sublist: [
                {
                  name: 'IIT Madras',
                  siteId: 'ec738d62-b1a5-11ec-bcde-02d12e814022',
                  foodCourtId: '8249d79c-b25a-11ec-bcde-02d12e814022',
                },
                {
                  name: 'Sree Balaji Food Court',
                  siteId: '3663a4f7-7f61-11ec-81b6-02d12e814022',
                  foodCourtId: '22d32f56-7f63-11ec-81b6-02d12e814022',
                },
              ],
            },
            // {
            //   title: "Codining in Tamil Nadu",
            //   sublist: [
            //     { name: "Merut Foodcourt", value: '' }
            //   ]
            // }
          ],
        },
        // {
        //   name: 'Bangalore',
        //   selected: false,
        //   list: [
        //     {
        //       title: "PG's in Bangalore",
        //       sublist: [
        //         "PG in KPHB",
        //         "PG in Gowlidoddi",
        //         "PG in Financial District",
        //         "PG in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Rooms for rent in Bangalore",
        //       sublist: [
        //         "Rooms for rent in KPHB",
        //         "Rooms for rent in Gowlidoddi",
        //         "Rooms for rent in Financial District",
        //         "Rooms for rent in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Hostels in Bangalore",
        //       sublist: [
        //         "Hostels in KPHB",
        //         "Hostels in Gowlidoddi",
        //         "Hostels in Financial District",
        //         "Hostels in Madhapur"
        //       ]
        //     }
        //   ]
        // }
      ],
    },
    {
      vertical: 'Co - Living',
      city: [
        {
          name: 'Hyderabad',
          selected: false,
          list: [
            {
              title: "PG's in Hyderabad",
              sublist: [
                { name: 'PG in KPHB', value: 'kukatpally' },
                {
                  name: 'PG in Gowlidoddi / Puppalguda / Kokapet',
                  value: 'gowlidoddi',
                },
                { name: 'PG in Puppalguda / Kokapet', value: 'gowlidoddi' },
                {
                  name: 'PG in Gachibowli / Nanakramguda',
                  value: 'gachibowli',
                },
                {
                  name: 'PG in Madhapur / Mindspace / Knowledge city',
                  value: 'madhapur',
                },
              ],
            },
            {
              title: "PG's in Hyderabad",
              sublist: [
                {
                  name: 'PG in Banjara Hills / Jubilee Hills',
                  value: 'banjara hills',
                },
                { name: 'PG in Kondapur / Kothaguda', value: 'kondapur' },
                { name: 'PG in Manikonda / Narsingi', value: 'manikonda' },
                { name: 'PG in Suraram', value: 'suraram' },
              ],
            },
            {
              title: "PG's in Hyderabad",
              sublist: [
                { name: 'PG in Nizampet / Miyapur', value: 'nizampet' },
                { name: 'PG in Kukatpally', value: 'kukatpally' },
                {
                  name: 'PG in Financial District / Cyber Towers',
                  value: 'financial district',
                },
                { name: 'PG in Hitech City', value: 'hitech city' },
                { name: 'PG in Jubliee Hills', value: 'jubliee hills' },
              ],
            },
          ],
        },
        // {
        //   name: 'NCR',
        //   selected: false,
        //   list: [
        //     {
        //       title: "PG's in NCR",
        //       sublist: [
        //         "PG in KPHB",
        //         "PG in Gowlidoddi",
        //         "PG in Financial District",
        //         "PG in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Rooms for rent in NCR",
        //       sublist: [
        //         "Rooms for rent in KPHB",
        //         "Rooms for rent in Gowlidoddi",
        //         "Rooms for rent in Financial District",
        //         "Rooms for rent in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Hostels in NCR",
        //       sublist: [
        //         "Hostels in KPHB",
        //         "Hostels in Gowlidoddi",
        //         "Hostels in Financial District",
        //         "Hostels in Madhapur"
        //       ]
        //     }
        //   ]
        // },
        // {
        //   name: 'Bangalore',
        //   selected: false,
        //   list: [
        //     {
        //       title: "PG's in Bangalore",
        //       sublist: [
        //         "PG in KPHB",
        //         "PG in Gowlidoddi",
        //         "PG in Financial District",
        //         "PG in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Rooms for rent in Bangalore",
        //       sublist: [
        //         "Rooms for rent in KPHB",
        //         "Rooms for rent in Gowlidoddi",
        //         "Rooms for rent in Financial District",
        //         "Rooms for rent in Madhapur"
        //       ]
        //     },
        //     {
        //       title: "Hostels in Bangalore",
        //       sublist: [
        //         "Hostels in KPHB",
        //         "Hostels in Gowlidoddi",
        //         "Hostels in Financial District",
        //         "Hostels in Madhapur"
        //       ]
        //     }
        //   ]
        // }
      ],
    },
  ];

  rediectToPlace(vertical: string, subItem: any) {
    if (vertical === 'Multi Brand Diner') {
      this.router.navigate([`/mbd/fc`], {
        queryParams: {
          siteId: subItem.siteId,
          foodCourtId: subItem.foodCourtId,
        },
      });
    }
    if (vertical === 'Co - Living') {
      this._colivingService.propertySearchParameter =
        PropertySearchType.LOCALITY;
      this._colivingService.propertyViewType.next(PropertyView.HOME);
      this.router.navigate([`/coliving/hyderabad/${subItem.value}`]);
    }
  }

  toggleSelectedLocationsList(index: number, city: string) {
    this.footerList[index].city.forEach((element: any) => {
      if (element.name === city) {
        element.selected = !element.selected;
      } else {
        element.selected = false;
      }
    });
  }
  faceBook() {
    window.open('https://www.facebook.com/IstharaColiving');
  }

  twitter() {
    window.open('https://twitter.com/IstharaLiving');
  }

  youTube() {
    window.open('https://www.youtube.com/watch?v=zqy7gpj4Xkk');
  }
  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;

    this.checkVisibility();
  }
}
