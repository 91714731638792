<ng-container *ngIf="!initialLoading; else showInitialLoader">
  <mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #drawer mode="over" position="end" (openedStart)="triggerOpen()">
      <app-side-navbar (closeSideBar)="drawer.close()" (moduleSelected)="pathChanged($event)"
        [triggerUserId]="triggerUserId"></app-side-navbar>
    </mat-drawer>
    <mat-drawer-content>
      <div class="header-sec"
        *ngIf="(!currentUrl.includes('profile') && !currentUrl.includes('enquire') && !currentUrl.includes('refer') && !currentUrl.includes('payment') && !currentUrl.includes('auth') && !currentUrl.includes('search') && !currentUrl.includes('cart') && !currentUrl.includes('payment-summary') && !currentUrl.includes('coupons') && !currentUrl.includes('guest-order') && !currentUrl.includes('address')) || (screenWidth > 480)">
        <div
          *ngIf="!currentUrl.includes('lp') && !currentUrl.includes('food-subscription') && !currentUrl.includes('auth') && !currentUrl.includes('resturant')">
          <app-header-sec (toggleSideBar)="drawer.toggle()" (navbarSelected)="selectedFromNavbar()"
            [selectedPath]="selectedPath"></app-header-sec>
        </div>
      </div>
      <section class="routing-content">

        <router-outlet></router-outlet>
      </section>
      <div class="footer-sec"
        *ngIf="!currentUrl.includes('profile') && !currentUrl.includes('food-subscription') && !currentUrl.includes('enquire') && !currentUrl.includes('refer') && !currentUrl.includes('auth') && !currentUrl.includes('payment') && !currentUrl.includes('search') && !currentUrl.includes('cart') && !currentUrl.includes('resturant')&& !currentUrl.includes('payment-summary') && !currentUrl.includes('coupons') && !currentUrl.includes('guest-order') && !currentUrl.includes('address') && !currentUrl.includes('lp')">
        <app-footer></app-footer>
      </div>


      <div class="enquiry-now-icon" (click)="goToEnquiryDialog()"
        *ngIf="(currentUrl === '/' || splittedUrl?.includes('coliving')) && showBtn">
        Enquire Now
      </div>

      <!-- <div class="notification-icon" (click)="goToNotificationListing()"
        *ngIf="screenWidth > 480 && (currentUrl.includes('coliving') || currentUrl.includes('codining'))">
      <div class="notification-icon" (click)="goToNotificationListing()"
        *ngIf="userId && screenWidth > 480 && (currentUrl.includes('coliving') || currentUrl.includes('codining'))">
        <img src="../assets/codining/images/side-nav/notification-icon.svg" alt="notification-icon" />
      </div> -->
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>
<ng-template #showInitialLoader>
  <div class="loading-screen">
    <div class="loading-screen-container">
      <img loading="lazy" src="./../assets/common/istharaloader.gif" alt="">
    </div>
  </div>
</ng-template>