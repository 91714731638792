import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageServiceService } from './storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class CodiningService {

  constructor(
    private http: HttpClient,
    private storageService: StorageServiceService
  ) { }

  selectedCuisineDetails: any;

  getSelectedCuisineDetails() {
    return this.selectedCuisineDetails;
  }
  setSelectedCuisineDetails(selectedCuisine: any) {
    this.selectedCuisineDetails = selectedCuisine;
  }

  getUserPoints(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }
  getHomePageResponse(foodCourtId: string, userId: string | null) {
    if (userId)
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}?userid=${userId}`, {})
    else
      return this.http.post(environment.baseUrl + `/foodcourt/user/ist/getHomedata/${foodCourtId}`, {})
  }
  payForOneClickOrderWithPoints(data: any, isPointsPayment: number) {
    return this.http.post(
      environment.baseUrl +
      `/foodcourt/ist/order/create/?isPointsPayment=${isPointsPayment}`,
      data
    );
  }
  getFoodocurtByFoodcourtId(foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }
  payForOneClickOrderWithCashfree(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/employee/token/web`,
      data
    );
  }

  getCartData(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }

  createGuestOrderRequest(data: any) {
    return this.http.post(
      environment.baseUrl + '/foodcourt/ist/guestOrder/request',
      data
    );
  }

  getItemByCuisines(sendingPayload: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/menu/ist/v1/search/items`, sendingPayload
    )
  }

  getFoodCourtDetails(foodCourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodCourtId}`
    )
  }
  searchItemsRestaurants(foodCourtId: string, text: string) {
    return this.http.post(environment.baseUrl + `/foodcourt/search/ist/suggest/${foodCourtId}?name=${text}`, {})
  }

  changeOrderStatus(data: any) {
    return this.http.put(
      environment.baseUrl + '/foodcourt/user/ist/cart/delivery',
      data
    );
  }

  checkSameCustomizationForItem() {
    const request = this.storageService.getUserCart();
    return request;
  }
}
