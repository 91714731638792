import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnquiryFormComponent } from './shared-components/enquiry-form/enquiry-form.component';
import { QrCodeComponent } from './modules/qrCode/qr-code/qr-code.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'coliving',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/login/login.module').then(
        (module) => module.LoginModule
      ),
    data: { animation: 'authPage' },
  },
  {
    path: 'mbd',
    loadChildren: () =>
      import('./modules/codining/codining.module').then(
        (module) => module.CodiningModule
      ),
    data: { animation: 'codiningPage' },
  },
  {
    path: 'codining',
    loadChildren: () =>
      import('./modules/codining/codining.module').then(
        (module) => module.CodiningModule
      ),
    data: { animation: 'codiningPage' },
  },
  {
    path: 'coliving',
    loadChildren: () =>
      import('./modules/coliving/coliving.module').then(
        (module) => module.ColivingModule
      ),
    data: { animation: 'colivingPage' },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/website/website.module').then(
        (module) => module.WebsiteModule
      ),
    data: { animation: 'istharaPage' },
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import("./modules/website/website.module").then((module) => module.WebsiteModule),
  //   data: { animation: 'homePage' }
  // },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (module) => module.UserProfileModule
      ),
    data: { animation: 'profilePage' },
  },
  {
    path: 'lp',
    loadChildren: () =>
      import('./modules/lp/lp.module').then((module) => module.LpModule),
  },
  {
    path: 'enquiry',
    component: EnquiryFormComponent,
  },

  {
    path: 'qrcode',
    component: QrCodeComponent,
  },
  // {
  //   path: '**',
  //   loadChildren: () =>
  //     import('./modules/coliving/coliving.module').then(
  //       (module) => module.ColivingModule
  //     ),
  //   data: { animation: 'colivingPage' },
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    // console.log("Hello");
  }
}
