import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CodiningSpacesService } from './codining-spaces.service';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';

@Component({
  selector: 'app-codining-spaces',
  templateUrl: './codining-spaces.component.html',
  styleUrls: ['./mq-codining-spaces.component.scss', './codining-spaces.component.scss']
})
export class CodiningSpacesComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    private codiningSpacesService: CodiningSpacesService,
    private sharedUtilsService: SharedUtilsService,
    private sharedLocalStorageService: SharedService,
    private router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }


  isLocationOn: boolean = false;

  ngOnInit(): void {

    this.selectedFoodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId") || "";

    let foodCourtDetails: any;

    this.foodCourtListing = this.data?.foodCourtList || [];
    this.filteredFoodCourtList = this.foodCourtListing || [];
    if (this.filteredFoodCourtList.length === 0) {
      this.getFoodCourtListing();
    }
    // this.foodCourtListing = this.data.foodCourtList.filter((foodCourt: any) => {
    //   return ((foodCourt.foodCourtId == this.selectedFoodCourtId) ? (foodCourtDetails = foodCourt) && false : true);
    // });
    // this.foodCourtListing.unshift(foodCourtDetails);
    // this.foodCourtListing.shift();

  }
  getFoodCourtListing() {
    this.foodCourtListingLoader = true;
    this.codiningSpacesService.getAllFoodCourts().subscribe((response: any) => {
      this.foodCourtListing = response.data;
      this.filteredFoodCourtList = this.foodCourtListing;
      this.foodCourtListingLoader = false;
    }, (error: any) => {
      this.sharedUtilsService.openSnackBar("We are Facing some issue please hold on.", "Close");
    })
  }


  handleFoodcourtSearch = this.sharedUtilsService.searchContent(() => this.searchFoodCourt(), 1000);

  searchFoodCourt() {
    this.filteredFoodCourtList = this.foodCourtListing.filter((foodCourtDetails: any) => {
      return foodCourtDetails.foodCourtName.toLowerCase().includes(this.searchText.toLowerCase()) || foodCourtDetails.siteName.toLowerCase().includes(this.searchText.toLowerCase());
    })
  }

  selectedFoodCourtId: string = "";
  searchText: string = "";

  foodCourtListingLoader: boolean = false;

  foodCourtListing: any[] = [];
  filteredFoodCourtList: any[] = [];

  bottomSheetListingType = [
    { name: "All", value: "ALL" },
    { name: "Co-dining", value: "CO_DINING" },
    { name: "Travel Plaza", value: "TRAVEL_PLAZA" },
  ]

  foodCourtSelected(foodCourtDetails: any) {
    this.selectedFoodCourtId = foodCourtDetails.foodCourtId;
    this.sharedLocalStorageService.storeInLocalStorage("foodCourtId", foodCourtDetails.foodCourtId);
    this.sharedLocalStorageService.storeInLocalStorage("foodCourtName", foodCourtDetails.foodCourtName);
    this.sharedLocalStorageService.storeInLocalStorage("siteId", foodCourtDetails.siteId);
    this.sharedLocalStorageService.storeInLocalStorage("siteName", foodCourtDetails.siteName);
    this.bottomSheetRef.dismiss();
    this.router.navigate([`/mbd/${foodCourtDetails.foodCourtName.replace(" ", "+")}`]);
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  userPosition = {
    userLatitute: 0,
    userLongitute: 0,
  };
  // locationPermission: any;

  getLocationPermission() {

    this.foodCourtListingLoader = true;
    if (navigator.geolocation) {

      // if (this.isLocationOn) {
      //   const watchID = navigator.geolocation.watchPosition(()=>{});
      //   navigator.geolocation.clearWatch(watchID);
      //
      // }

      this.foodCourtListingLoader = false;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.isLocationOn = true;
          this.userPosition.userLongitute = position.coords.longitude;
          this.userPosition.userLatitute = position.coords.latitude;
          this.getSitesByLocation();
        }
      );
    } else {
      this.foodCourtListingLoader = false;
      //console.log('No support for geolocation');
    }
  }

  getSitesByLocation() {

    this.foodCourtListingLoader = true;
    let data = {
      geolocation: {
        latitude: this.userPosition.userLatitute,
        longitude: this.userPosition.userLongitute,
      },
    }
    this.codiningSpacesService.getSitesByLocation(data).subscribe((response: any) => {

      this.foodCourtListing = [];
      const siteList = response.data.filter((site: any) => site.foodCourtList.length > 0);
      siteList.forEach((site: any) => {
        site.foodCourtList.forEach((foodCourt: any) => {
          this.foodCourtListing.push({ ...foodCourt, siteName: site.siteName });
        })
      })
      this.filteredFoodCourtList = this.foodCourtListing;
    }, (error: any) => {

    }, () => {
      this.foodCourtListingLoader = false;
    }

    );
  }

}
