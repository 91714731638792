import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

const MODULES: any[] = [
    SlickCarouselModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...MODULES],
  exports: MODULES
})
export class SlickModule { }
