import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root"
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar
  ) { }

  // selectedDeliveryAddress: any = null;
  // addDeliveryAddress(address: any) {
  //   this.selectedDeliveryAddress = this.selectedDeliveryAddress;
  // }
  // getDeliveryAddress() {
  //   return this.selectedDeliveryAddress;
  // }

  getOrderPaymentStatusForCartPayment(orderId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/payment/ist/order/status?orderId=${orderId}`);
  }

  changeOrderStatus(data: any) {
    return this.http.put(
      environment.baseUrl + '/foodcourt/user/ist/cart/delivery',
      data
    );
  }

  getOrderPaymentStatusForOneClick(orderId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/payment/ist/order/status/one-click?orderId=${orderId}`
    );
  }

  deleteItemFromCart(userId: string | null, itemId?: string) {
    if (itemId) {
      return this.http.delete(environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}&itemId=${itemId}`);
    } else {

      return this.http.delete(environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`)
    }
  }


  getCouponsByUserId(userId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/discount/ist/v1/cart?userId=${userId}`);
  }

  applyCouponToUser(userId: string, discountId: string) {
    return this.http.put(environment.baseUrl + `/foodcourt/discount/ist/v1/${userId}/apply/${discountId}`, {});
  }
}
