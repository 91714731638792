import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { StorageServiceService } from './modules/codining/shared-services/storage-service.service';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { NotificationService } from './modules/login/notification.service';
import { slideInAnimation } from './animation';
import { SharedService } from './shared-services/shared.service';
import { CodiningLandingService } from './modules/codining/shared-services/codining-landing.service';
import { userDetailsStorageService } from './modules/login/user-details-storage.service';
import { NotificationListingComponent } from './modules/user-profile/notification-listing/notification-listing.component';
import { EnquiryDialogComponent } from './shared-components/enquiry-dialog/enquiry-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {

  title = 'isthara-codining';

  constructor(
    private storageService: StorageServiceService,
    private userPropertyDetails: userDetailsStorageService,
    private activatedRoute: ActivatedRoute,
    private sharedLocalStorageService: SharedService,
    private router: Router,
    private _notificationService: NotificationService,
    private contexts: ChildrenOutletContexts,
    private codiningLandingService: CodiningLandingService,
    private _bottomSheet: MatBottomSheet
  ) { }

  selectedPath: string = '';
  initialLoading: boolean = true;
  currentUrl: string = '';

  userId: string | null = null;

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  triggerUserId: string | null = null;
  triggerOpen() {
    this.triggerUserId = this.sharedLocalStorageService.getFromLocalStorage("userId");
  }

  splittedUrl : any;
  ngOnInit() {

    this.getScreenSize();
    this._notificationService.requestPermission();
    this._notificationService.receiveMessage();

    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url;
        if (this.currentUrl.includes('profile/tab'))
          return;
        if (this.currentUrl.includes('/cart?static='))
          return;
        if (this.currentUrl === '/')
        {
          setTimeout(() => {
            this.goToEnquiryDialog();
          },3000);
        }

        this.splittedUrl = this.currentUrl.split('/')
        // console.log(this.currentUrl);
        this.initialLoading = true;
        setTimeout(() => {
          this.initialLoading = false;
        }, 500);
        if (this.currentUrl.includes("foodCourtId")) {
          const foodCourtId = this.currentUrl.split("foodCourtId=")[1];
          this.sharedLocalStorageService.storeInLocalStorage("foodCourtId", foodCourtId);
          this.router.navigate(['mbd']);
        }
      }
    });

    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");

    setTimeout(() => {
      this.initialLoading = false;
    }, 2000);

  }

  selectedFromNavbar() {
    this.initialLoading = true;
    setTimeout(() => {
      this.initialLoading = false;
    }, 2000);
  }

  pathChanged(event: any) {
    this.selectedPath = event;
  }


  goToNotificationListing() {
    this.sharedLocalStorageService.openDialogConfig
      (
        NotificationListingComponent,
        '90vh',
        '40vw',
        'auto',
        'borderClass'
      );
  }

  showBtn : boolean = true;
  goToEnquiryDialog(){
    this.showBtn = false;
    if(this.screenWidth > 480){
      let showEnquirBtn = this.sharedLocalStorageService.openDialogConfigRadius
      (
        EnquiryDialogComponent,
        '95vh',
        '65vw',
        'auto',
      );

      showEnquirBtn.afterClosed().subscribe(
        (res:any) => {
          this.showBtn = true;
        }
      )
    }
    else{
      let showEnquirBtn = this.sharedLocalStorageService.openDialogConfigRadius
      (
        EnquiryDialogComponent,
        '100vh',
        '100vw',
        'auto',
      );

      showEnquirBtn.afterClosed().subscribe(
        (res:any) => {
          this.showBtn = true;
        }
      )
    }
  }
}
