import { NgModule } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { ResturantPageComponent } from './major-components/resturant-page/resturant-page.component';
import { CartPageComponent } from './major-components/cart-page/cart-page.component';
import { CodiningLandingPageComponent } from './major-components/codining-landing-page/codining-landing-page.component';
import { HomePageComponent } from './major-components/home-page/home-page.component';
import { PaymentSummaryComponent } from './major-components/payment-summary/payment-summary.component';
import { CouponsListingComponent } from './major-components/coupons-listing/coupons-listing.component';
import { GuestOrderComponent } from './major-components/guest-order/guest-order.component';
import { CodiningLandingComponent } from './major-components/codining-landing/codining-landing.component';
import { SearchPageComponent } from './major-components/search-page/search-page.component';
import { CuisineResturantComponent } from './major-components/cuisine-resturant/cuisine-resturant.component';
import { DeliveryAddressPageComponent } from './major-components/delivery-address-page/delivery-address-page.component';
import { SharedService } from '../../shared-services/shared.service';
import { RedirectingPageComponent } from './major-components/redirecting-page/redirecting-page.component';
import { SharedUtilsService } from './shared-services/shared-utils.service';

const routes: Routes = [


  { path: 'fc', component: RedirectingPageComponent },
  { path: 'foodcourt', component: CodiningLandingPageComponent },

  { path: 'cart', component: CartPageComponent },
  { path: 'coupons', component: CouponsListingComponent },
  { path: 'search', component: SearchPageComponent },
  { path: 'mbd/order/payment', component: PaymentSummaryComponent },
  { path: 'payment-summary', component: PaymentSummaryComponent },
  { path: 'cuisine-resturant', component: CuisineResturantComponent },
  { path: 'delivery-address', component: DeliveryAddressPageComponent },
  { path: '', component: CodiningLandingPageComponent },
  {
    path: ':foodCourt',
    component: CodiningLandingComponent,
    children: [
      // { path: '', redirectTo: 'home', pathMatch: 'full' },
      // { path: 'home', component: HomePageComponent },
      { path: '', component: HomePageComponent },
      { path: 'guest-order', component: GuestOrderComponent },
      { path: 'resturant', component: ResturantPageComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodiningRoutingModule {
  constructor(
    private router: Router,
    private sharedUtilService: SharedService,
    private activatedRoute: ActivatedRoute
  ) {

    // console.log(this.router.url);

    this.checkParams();

  }
  checkParams() {

    this.activatedRoute.queryParams.subscribe((qParams: any) => {
      if (qParams['siteId']) {

        let foodCourtId = qParams['foodCourtId'];
        let siteId = qParams['siteId'];
        this.sharedUtilService.storeInLocalStorage('foodCourtId', foodCourtId);
        this.sharedUtilService.storeInLocalStorage('siteId', siteId);
        // this.getFoodCourtDetails();
      }

    })
  }
  // getFoodCourtDetails() {
  //   if (!this.foodCourtId)
  //     return;


  //   this.codiningService.getFoodocurtByFoodcourtId(this.foodCourtId).subscribe((response: any) => {
  //     let foodCourtName = response.data.foodCourtName;
  //     let siteName = response.data.siteName;
  //     let siteId = response.data.siteId;


  //     this.sharedUtilService.storeInLocalStorage('foodCourtName', foodCourtName);
  //     this.sharedUtilService.storeInLocalStorage('siteName', siteName);
  //     this.sharedUtilService.storeInLocalStorage('siteId', siteId);
  //     this.router.navigate([`/codining/${foodCourtName}`]);
  //   }, (error: any) => {
  //     this.sharedUtilService.storeInLocalStorage('foodCourtName', null);
  //     this.sharedUtilService.storeInLocalStorage('siteName', null);
  //     this.sharedUtilService.storeInLocalStorage('siteId', null);
  //     this.router.navigate([`/codining`]);
  //   })
  // }
}

