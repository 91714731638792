import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CodiningService {

  constructor(
    private http: HttpClient
  ) { }

  getUserOrderHistoryList(payload: any) {
    return this.http.put(environment.baseUrl + `/foodcourt/user/ist/cart`, payload);
  }

  getAllFoodCourtList() {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/list?pageNo=0&size=1000`
    )
  }
  getFoodocurtByFoodcourtId(foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }


  changePickupToDelivery(orderId: string, address: any, orderType: string, points: number) {
    return this.http.post(
      environment.baseUrl +
      `/foodcourt/ist/order/convert/toDelivery?orderId=${orderId}&orderType=${orderType}&pointsToBeDeducted=${points}`,
      address
    );
  }

  getUserRewardPoints(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/corporate/ist/user/get/points/?userId=${userId}`
    );
  }

  createPaymentTokenForDelivery(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/payment/ist/delivery/token/web`,
      data
    );
  }

  getSavedAddressesOfUser(userId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    );
  }

  getOrderSummaryByNumber(orderNumber: string) {

    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/orderNum?orderNum=${orderNumber}`
    )
  }

  getPackagingCharges(orderId: string, fcId: string, orderType: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/get/charges/?foodCourtId=${fcId}&orderId=${orderId}&orderType=${orderType}`
    )
  }

  cancelOrderByOrderId(orderId: string) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/ist/order/cancel?orderId=${orderId}`,
      null
    );
  }
  getOrderPaymentStatusForCartPayment(orderId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/payment/ist/order/status?orderId=${orderId}`
    );
  }

  getAllCodiningTickets(userId: string) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/ist/v1/fc/${userId}/tickets?pageNo=0&pageSize=500`
    )
  }

  getHelpdeskCategory(vertical: string) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/category/ist/v1/fc/list?vertical=${vertical}`
    );
  }
  getHelpdeskSubCategory(categoryId: string, vertical: string) {
    return this.http.get(
      environment.baseUrl + `/property/helpdesk/sub-category/ist/v1/fc/${categoryId}/issue/all?vertical=${vertical}
      `
    )
  }


  raiseHelpdeskTicket(helpdeskTicketPayload: any) {
    return this.http.post(environment.baseUrl + `/property/helpdesk/ist/v1/ticket/create`, helpdeskTicketPayload);
  }


  checkUserFoodSubscriptionPaymentStatus(data: any) {
    return this.http.post(environment.baseUrl + `/foodcourt/subscription/ist/payment/status`, data)
  }

  getOrderPaymentStatusForOneClick(orderId: string) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/payment/ist/order/status/one-click?orderId=${orderId}`
    );
  }

  getOrderPaymentStatusForDeliveryPayment(
    orderId: string,
    fcOrderId: string,
    deliveryAddress: any,
    orderType: any,
    pointsToBeDeducted: number = 0
  ) {
    return this.http.post(
      environment.baseUrl +
      `/foodcourt/payment/ist/delivery-order/status?fcOrderId=${fcOrderId}&orderId=${orderId}&orderType=${orderType}&pointsToBeDeducted=${pointsToBeDeducted}`,
      deliveryAddress
    );
  }

  getGuestOrderHistoryOfUser(userId: string, pageNo: number, size: number) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/guestOrder/user/requests?pageNo=${pageNo}&size=${size}&userId=${userId}`)
  }

  getGuestPendingOrderHistoryOfUser(userId: string, pageNo: number, size: number) {
    return this.http.get(environment.baseUrl + `/foodcourt/ist/guestOrder/user/orders?pageNo=${pageNo}&size=${size}&userId=${userId}`)
  }

  getWalletPaymentStatus(orderId: string) {
    return this.http.post(environment.baseUrl + `/foodcourt/payment/ist/wallet/payment/status?orderId=${orderId}`, {})
  }

  sendRemainderForGuestOrderRequest(employerId: string, requestId: string) {
    return this.http.post(environment.baseUrl + `/foodcourt/ist/guestOrder/request/reminder?employerId=${employerId}&requestId=${requestId}`, {})
  }

  getRequestDetailByRequestId(requestId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/guestOrder/request?requestId=${requestId}`
    )
  }

  getSPOClist(fcId: any, siteId: any) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/spoc/ist/getByFc/list?fcId=${fcId}&siteId=${siteId}`
    )
  }

  getOrdersListByUserId(userId: string | null, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl +
      `/foodcourt/ist/order/v1/${userId}?pageNo=${pageNo}&size=${size}`
    );
  }

  getInvoiceURL(orderId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/orders/export/pdf?orderId=${orderId}`)
  }

  getOrderDetailByOrderId(orderId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/ist/order/getOrder/${orderId}`
    );
  }

  createRatingForOrder(data:any){
    return this.http.post(
      environment.baseUrl+`/foodcourt/ratingReview/ist/create`,data
    )
  }
}
