<section id="footer">
  <div class="top_footer">
    <div class="left_top_footer">
      <img loading="lazy" loading="lazy" src="../../../assets/codining/images/home-page/isthara-logo.svg" alt="Isthara"
        class="isthara-logo" (click)="goToHomePage()">

      <span class="download-store__wrapper" *ngIf="isImageVisible">
        <span class="download-store" (click)="navigateToStore('PLAY_STORE')">
          <img loading="lazy" loading="lazy" src="../../../assets/footer/google-play.svg" alt="play store">
          <!-- <span class="text-download-store">
            <span>GET IT ON</span>
            <span>Google Play</span>
          </span> -->
        </span>

        <span class="download-store" (click)="navigateToStore('APP_STORE')">
          <img loading="lazy" loading="lazy" src="../../../assets/footer/app-store.svg" alt="App store">
          <!-- <span class="text-download-store">
            <span>Download on the</span>
            <span>App Store</span>
          </span> -->
        </span>
      </span>
    </div>

    <div class="right_top_footer">
      <div class="top_right_top_footer">
        <div class="content_top_right_top_footer">
          <h1 class="content-title">Isthara</h1>
          <!-- <div class="content-points" (click)="redirectToPage('DINE_WITH_US')">Co-Dining</div> -->
          <div class="content-points" (click)="redirectToPage('MBD')">Multi Brand Diner</div>
          <div class="content-points" (click)="redirectToPage('CO_LIVING')">Co-Living</div>
          <div class="content-points" (click)="redirectToPage('ABOUT_US')">About us</div>
        </div>

        <div class="footer-left-divider"></div>

        <div class="content_top_right_top_footer">
          <h1 class="content-title">Partnership</h1>
          <ul>
            <li class="content-points" (click)="redirectToPage('PARTNERSHIP', 'BUILDERS')">Property Owner</li>
            <li class="content-points" (click)="redirectToPage('PARTNERSHIP', 'FOOD_BRAND')">Food Vendors</li>
            <li class="content-points" (click)="redirectToPage('PARTNERSHIP', 'COLLEGES')">Colleges/Corporates</li>
            <!-- <li class="content-points">Showcase your talent</li> -->
          </ul>
        </div>

        <div class="footer-left-divider"></div>

        <div class="content_top_right_top_footer">
          <h1 class="content-title">Support</h1>
          <ul>
            <li class="content-points" (click)="redirectToPage('CAREER')">Careers</li>
            <li class="content-points" (click)="redirectToPage('FAQ')">FAQs</li>
            <li class="content-points" (click)="redirectToPage('CONTACT_US')">Contact us</li>
          </ul>
        </div>
      </div>

      <div class="divider"></div>

      <div class="bottom_right_top_footer">
        <h1 class="content-title">Our Presence</h1>

        <ng-container *ngFor="let footer of footerList; index as footerIndex">
          <h2 class="content-sub-title">{{ footer.vertical }}</h2>

          <div class="our_presence">
            <div class="top__our-presence">
              <ng-container *ngFor="let city of footer.city">
                <div class="city-name" [style.fontWeight]="city.selected ? '600' : ''"
                  [style.opacity]="city.selected ? '1' : '0.7'"
                  (click)="toggleSelectedLocationsList(footerIndex, city.name)">
                  {{ city.name }}
                  <span *ngIf="!city.selected">+</span>
                  <span *ngIf="city.selected">-</span>
                </div>
              </ng-container>
            </div>

            <div class="bottom__our-presence">
              <ng-container *ngFor="let city of footer.city">
                <ng-container *ngIf="city.selected">
                  <ng-container *ngFor="let item of city.list">
                    <div class="content_bottom-our-presence">
                      <h4>{{ item.title }}</h4>
                      <ul>
                        <li class="sub-content-points" *ngFor="let subItem of item.sublist"
                          (click)="rediectToPlace(footer.vertical, subItem)">{{ subItem.name }}</li>
                      </ul>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <div class="bottom_footer">
    <div class="left_bottom_footer">
      <span (click)="goToTermsPage()">Terms & Conditions</span>
      <span (click)="goToPrivacyPolicy()">Privacy policy</span>
    </div>

    <div class="right_bottom_footer">
      <span><img loading="lazy" loading="lazy" src="../../../assets/footer/twitter-icon.svg" alt="twitter"
          (click)="twitter()"></span>
      <span><img loading="lazy" loading="lazy" src="../../../assets/footer/youtube-icon.svg" alt="youtube"
          (click)="youTube()"></span>
      <span><img loading="lazy" loading="lazy" src="../../../assets/footer/facebook-icon.svg" alt="facebook"
          (click)="faceBook()"></span>
    </div>
  </div>

  <!-- <div class="divider"></div> -->

  <div class="copy-right">
    © ISTHARA PARKS PRIVATE LIMITED 2024
  </div>
</section>