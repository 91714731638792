<section class="codining-spaces-section">
  <div class="spaces-section-one">
    <div class="section-one__header">
      <div class="section-one__header__left-sec" (click)="closeBottomSheet()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </div>
      <div class="section-one__header__right-sec header-primary-text">Select your Multi Brand Diner space</div>
    </div>
    <div class="section-one__content">
      <div class="section-one__search-section">
        <input type="text" placeholder="Search FoodCourt or Places" [(ngModel)]="searchText"
          (ngModelChange)="handleFoodcourtSearch()">
        <mat-icon>search</mat-icon>
      </div>
      <div class="section-one__use-location" (click)="getLocationPermission()">
        <mat-icon>location_on</mat-icon>
        <div class="text">Us<span *ngIf="!isLocationOn">e</span><span *ngIf="isLocationOn">ing</span> your
          current location</div>
      </div>
    </div>
  </div>
  <div class="spaces-section-two">
    <!-- <mat-tab-group>
            <ng-container *ngFor="let type of bottomSheetListingType">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="custome-tab-label">{{ type.name | titlecase }}</span>
                    </ng-template> -->
    <ng-container *ngIf="!foodCourtListingLoader; else ShowLoadingScreen">
      <ng-container *ngFor="let foodCourtDetails of filteredFoodCourtList">
        <!-- <ng-container
                                *ngIf="(type.value === 'CO_DINING' && !foodCourtDetails.travelPlaza) || (type.value === 'TRAVEL_PLAZA' && foodCourtDetails.travelPlaza) || type.value === 'ALL'  "> -->
        <div class="foodcourt-card-container" (click)="foodCourtSelected(foodCourtDetails)">
          <div class="foodcourt-name">{{ foodCourtDetails.foodCourtName | titlecase }}</div>
          <div class="foodcourt-location">{{ foodCourtDetails.siteName | titlecase }}, {{
            foodCourtDetails.city | titlecase }}</div>
          <div class="foodcourt-checkbox">
            <mat-radio-group [(ngModel)]="selectedFoodCourtId">
              <mat-radio-button [value]="foodCourtDetails.foodCourtId"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!-- </ng-container> -->
      </ng-container>
    </ng-container>
    <ng-template #ShowLoadingScreen>
      <div class="loading-screen">
        <div class="loading-screen-container">
          <img loading="lazy" src="../../../assets/codining/images/common/burger_loader.gif" alt="">
        </div>
      </div>
    </ng-template>
    <!-- </mat-tab>
            </ng-container>
        </mat-tab-group> -->
  </div>
</section>