import { Platform } from '@angular/cdk/platform';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private sharedService: SharedService,
    private router: Router,
    public platform: Platform,
  ) { }

  ngOnInit(): void {
    this.dataSource = this.data.notificationData;
    // console.log(this.dataSource);

    if (this.dataSource.notificationType === 'EVENTS') {
      if (this.dataSource.eventTime) {
        const eventTime = this.dataSource.eventTime.split(':');

        this.dataSource.eventTime = new Date(0, 0, 0, eventTime[0], eventTime[1], eventTime[2]);
      }

      if (this.dataSource.eventEndTime) {
        const eventTime = this.dataSource.eventEndTime.split(':');

        this.dataSource.eventEndTime = new Date(0, 0, 0, eventTime[0], eventTime[1], eventTime[2]);
      }
    }
  }

  dataSource: any;

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }
  goToOrderDetails() {
    this.sharedService.storeInLocalStorage("orderId", this.dataSource.orderId);
    this.bottomSheetRef.dismiss();
    this.router.navigate(['/profile/order-summary']);
  }

  goToStore() {
    let appDownloadLink = '';

    if (this.platform.ANDROID) {
      appDownloadLink =
        'https://play.google.com/store/apps/details?id=com.isthara.partner';
    } else if (this.platform.IOS) {
      // tiny url for ISTHARA App for IOS ("https://tinyurl.com/5n7vn985")
      appDownloadLink = 'https://play.google.com/store/apps/details?id=com.isthara.partner';
    }

    const aElement = document.createElement('a');
    aElement.href = appDownloadLink;
    aElement.click();
    this.closeBottomSheet();
  }

  goToHomePage() {
    this.router.navigate([`/partner/vendor/orders`]);
    this.closeBottomSheet();
  }
}
