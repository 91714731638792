import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { CodiningService } from '../../shared-services/codining-service.service';

enum GUEST_ORDERING_PAGE {
  ITEM_LISTING = "ITEM_LISTING",
  GUEST_DETAILS = "GUEST_DETAILS",
  PREVIEW_PAGE = "PREVIEW_PAGE"
}

@Component({
  selector: 'app-guest-order',
  templateUrl: './guest-order.component.html',
  styleUrls: ['./guest-order.component.scss', './mq-guest-order.component.scss']
})
export class GuestOrderComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService,
    private codiningService: CodiningService
  ) { }

  CURRENT_PAGE: GUEST_ORDERING_PAGE = GUEST_ORDERING_PAGE.ITEM_LISTING;
  userId: string | null = null;
  foodCourtId: string | null = null;
  foodCourtName: string | null = null;
  guestOrderMenu: any;

  guestCount: number = 0;
  guestReasons: string | null = null;

  addedGuestMenu: any = [];

  guestDetailsList: any[] = [];

  screenLoading: boolean = false;

  ngOnInit(): void {
    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    this.foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");

    this.getGuestOrderMenu();
  }

  changeView(view: any) {
    this.CURRENT_PAGE = view;
  }

  addItemGuestOrder(mealDetails: any, type?: "INCREASE" | "DECREASE") {
    if (type === "INCREASE") {
      mealDetails.quantity++;
      this.addedGuestMenu.push(mealDetails);
    } else {

      mealDetails.quantity--;
      if (!mealDetails.quantity) {
        this.addedGuestMenu = this.addedGuestMenu.filter((menuDetail: any) => {
          return menuDetail.id != mealDetails.id;
        })
      }
    }
  }

  removeGuestFromList(idx: number) {
    this.guestDetailsList.splice(idx, 1);
  }

  showGuestList() {

    if (!this.guestCount || !this.guestReasons)
      return;

    this.showGuestListing = true;
    for (let i = 0; i < this.guestCount; i++)
      this.guestDetailsList.push({
        name: '',
        mobile: '',
        email: ''
      });
  }

  showGuestListing: boolean = false;
  guestReasonList = [
    { name: "Came for Interview", value: "CAME_FOR_INTERVIEW" },
    { name: "Came for Client Meeting", value: "CAME_FOR_INTERVIEW" },
    { name: "Came for WorkShop", value: "CAME_FOR_INTERVIEW" },
    { name: "Other", value: "CAME_FOR_INTERVIEW" },
  ]
  addGuest() {
    this.guestDetailsList.push({
      name: '',
      mobile: '',
      email: ''
    });
  }

  getGuestOrderMenu() {

    if (!this.foodCourtId || !this.userId)
      return;

    this.screenLoading = true;
    this.codiningService.getHomePageResponse(this.foodCourtId, this.userId).subscribe((response: any) => {
      this.guestOrderMenu = response.data.menuDataForEmployees.map((item: any) => {
        return {
          ...item,
          quantity: 0
        }
      });
      this.screenLoading = false;
    }, (error: any) => {

      this.screenLoading = false;

    })
  }

  goToHomePage() {
    this.router.navigate([`/mbd/${this.foodCourtName}`]);
  }
  goToGuestDetailsPage() {
    this.CURRENT_PAGE = GUEST_ORDERING_PAGE.GUEST_DETAILS;
  }
  goToPreviewPage() {
    this.CURRENT_PAGE = GUEST_ORDERING_PAGE.PREVIEW_PAGE;
  }

  raiseGuestRequest() {

    const userName = this.sharedLocalStorageService.getFromLocalStorage("userName");
    const userMobile = this.sharedLocalStorageService.getFromLocalStorage("mobile");
    const employerId = this.guestOrderMenu[0].employerMenu[0].employerId;

    const orderedItemsList = this.addedGuestMenu.map((element: any) => {
      return {
        discountAmount: 0,
        finalBillPrice: 0,
        imgUrl: element.itemImageUrl,
        inStock: true,
        istharaMarginAmountPerItem: 0,
        istharaTotalMarginAmount: 0,
        itemCategory: element.itemCategory,
        itemId: element.itemId,
        itemMealCategory: element.mealCategory[0],
        itemName: element.name,
        itemQuantity: element.quantity,
        itemStatus: element.itemStatus,
        // mrpItem: element.mrpItem,
        markUpPrice: element.markUpPrice,
        markUpTaxPerItem: (element.markUpTaxValue ? element.markUpTaxValue : element.markUpTaxPerItem),
        markUpTotalPrice: element.markUpPrice * element.itemQuantity,
        markUpTotalTax: (element.markUpTaxValue ? element.markUpTaxValue : element.markUpTaxPerItem) * element.itemQuantity,
        taxCategory: element.taxCategory,
        price: element.price,
        rating: element.rating,
        taxPerItem: element.taxValue, // element.taxPercentPerItem
        totalPrice: element.price * element.quantity,
        // totalTax: 0.05 * element.quantity * element.price,
        totalTax: element.taxValue * element.quantity,
        veg: element.itemType === 'VEG' ? true : false,
        vendorId: element.vendorId,
        vendorMarginAmountPerItem: null,
        vendorName: element.vendorName,
        vendorTotalMarginAmount: null,
      };
    });

    const billDetails = {
      breakup: {
        'Taxes & charge': 0,
        'Item Total': 0,
        'Item Discount': null,
        'Delivery Charges': 0,
      },
      couponCode: null,
      couponId: null,
      employeePrice: 0,
      finalPrice: 0,
    };

    orderedItemsList.forEach((element: any) => {
      billDetails.breakup['Item Total'] += element.totalPrice;
      billDetails.breakup['Taxes & charge'] += element.totalTax;
    });

    billDetails.finalPrice =
      billDetails.breakup['Item Total'] + billDetails.breakup['Taxes & charge'];

    let data = {
      billDetails: billDetails,
      designation: null,
      employerId: employerId,
      fcId: this.foodCourtId,
      guestDetails: this.guestDetailsList,
      imgUrl: null,
      mobile: userMobile,
      orderedItemsList: orderedItemsList,
      pointsToBeUsed: 0,
      reason: this.guestReasons,
      rejectReason: null,
      staffId: null,
      staffName: null,
      userId: this.userId,
      userName: userName,
      requestStatus: "NEW",
      platform: "WEB"
    };


    this.codiningService.createGuestOrderRequest(data).subscribe(
      (res: any) => {
        this.router.navigate(['/profile/order-listing']);
      },
      (err) => {
      }
    );
  }
}
