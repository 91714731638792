import { Component, HostListener, OnInit } from '@angular/core';
import { OrderService } from '../../shared-services/order-service.service';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CodiningService } from '../../../user-profile/codining-service.service';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { SuccessPopupComponent } from '../../shared-components/success-popup/success-popup.component';
import { StorageServiceService } from '../../shared-services/storage-service.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss', './mq-payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit {

  constructor(
    private orderSummaryService: OrderService,
    private codiningService: CodiningService,
    private sharedService: SharedService,
    private storageService: StorageServiceService,
    private router: Router,
    private sharedUtilService: SharedUtilsService,
    private route: ActivatedRoute
  ) { }

  orderId: string = '';
  paymentFor: string = '';
  foodCourtName: string | null = null;
  userId: string = '';

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  loadingText: string = "Thanks for ordering via isthara"
  ngOnInit(): void {

    this.getScreenSize();

    this.orderId = this.sharedService.getFromLocalStorage('orderId');
    this.paymentFor = this.sharedService.getFromLocalStorage('paymentFor');
    this.foodCourtName = this.sharedService.getFromLocalStorage('foodCourtName');
    this.userId = this.sharedService.getFromLocalStorage("userId");

    if (!this.paymentFor) {
      this.router.navigate([`/mbd/${this.foodCourtName}`]);
    }

    if (this.paymentFor === "RECHARGE_WALLET")
      this.loadingText = "Thanks for choosing Isthara";


    if (this.paymentFor === 'DELIVERY_PAYMENT') {
      const addressId = this.sharedService.getFromLocalStorage('userAddressId');

      this.codiningService.getSavedAddressesOfUser(this.userId).subscribe((res: any) => {
        res.data.forEach((element: any) => {
          if (element.addressId === addressId) {
            this.deliveryAddress = element;
          }
        });
        // Cornor Scenario
        if (this.paymentFor === "DELIVERY_PAYMENT") {
          this.checkPackagingChargesStatus();
        }
      });
    }

    this.route.queryParams.subscribe((data: any) => {
      if (data?.order_id) {
        this.orderId = data?.order_id;
        if (!this.orderId) {
          this.sharedService.openSnackBar("Some Error Occured!!");
          this.sharedService.storeInLocalStorage("paymentFor", null);
          this.router.navigate([`/mbd/${this.foodCourtName}`]);
        }
        this.showTextWhileLoading();
      } else {
        this.sharedService.openSnackBar("Some Error Occured!! Failed to fetch Order Details");
        this.sharedService.storeInLocalStorage("paymentFor", null);
        this.router.navigate([`/mbd/${this.foodCourtName}`]);
      }
    });
  }


  showTextWhileLoading() {
    setTimeout(() => {
      this.loadingText = "Please wait...";
      setTimeout(() => {
        this.loadingText = "We are processing your " + (this.paymentFor === "RECHARGE_WALLET" ? "payment" : "order");
        setTimeout(() => {
          this.loadingText = "Hold On...";

          if (this.paymentFor === "CART_PAYMENT")
            this.checkOrderStatusOfCartPayment();
          else if (this.paymentFor === "RECHARGE_WALLET")
            this.checkRechargeWalletStatus();
          else if (this.paymentFor === "ONE_CLICK_PAYMENT")
            this.checkOneClickPaymentStatus();
          else if (this.paymentFor === "SELF_PICKUP_PAYMENT" || this.paymentFor === 'DELIVERY_PAYMENT')
            this.checkPackagingChargesStatus();
          else if (this.paymentFor === "PROPERTY_FOOD_SUBSCRIPTION")
            this.checkPropertyFoodSubscription();

        }, 3000);
      }, 3000);
    }, 3000);
  }
  deliveryAddress: any = null;

  checkPackagingChargesStatus() {
    const fcOrderId = this.sharedService.getFromLocalStorage("changeOrderTypeFCID");
    let pointsToBeDeducted = this.sharedService.getFromLocalStorage("pointsToBeDeducted");

    this.codiningService
      .getOrderPaymentStatusForDeliveryPayment(
        this.orderId,
        // this.order_token,
        fcOrderId,
        this.deliveryAddress ? this.deliveryAddress : {},
        this.deliveryAddress ? "DELIVERY" : "PICK_UP",
        (pointsToBeDeducted ? pointsToBeDeducted : 0)
      )
      .subscribe(
        (res: any) => {
          //console.log('order response', res);

          if (res.data.paymentStatus && res.data.paymentStatus === 'SUCCESS') {
            if (this.screenWidth <= 480) {

              this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

                this.router.navigate(['/profile/order-listing']);
              });
            } else {
              this.router.navigate(['/profile/tab'], { queryParams: { type: 'MY_ORDER' } });
            }
            this.sharedService.storeInLocalStorage("paymentFor", null);
            this.sharedService.storeInLocalStorage("userAddressId", null);
            this.sharedService.storeInLocalStorage("pointsToBeDeducted", null);
            this.sharedService.storeInLocalStorage("changeOrderTypeFCID", null);
          } else {
            if (this.screenWidth <= 480) {

              this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

                this.router.navigate(['/profile/order-listing']);
              });
            } else {
              this.router.navigate(['/profile/tab'], { queryParams: { type: 'ORDER_FAILED' } });
            }
          }

        },
        (error: any) => {
          if (this.screenWidth <= 480) {

            this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

              this.router.navigate(['/profile/order-listing']);
            });
          } else {
            this.router.navigate(['/profile/tab'], { queryParams: { type: 'ORDER_FAILED' } });
          }
        }
      );
  }


  checkRechargeWalletStatus() {
    this.codiningService.getWalletPaymentStatus(this.orderId).subscribe((response: any) => {

      this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "POINTS_RECHARGED" }).afterClosed().subscribe(() => {
        if (this.screenWidth <= 480) {
          this.router.navigate(['/profile/recharge-wallet']);
        } else {
          this.router.navigate(['/profile/tab'], { queryParams: { type: 'POINTS' } });
        }
      })
      this.sharedService.storeInLocalStorage("paymentFor", null);
    }, (error: any) => {
      this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "POINTS_RECHARGED_FAILED" }).afterClosed().subscribe(() => {
        if (this.screenWidth <= 480) {
          this.router.navigate(['/profile/recharge-wallet']);
        } else {
          this.router.navigate(['/profile/tab'], { queryParams: { type: 'POINTS' } });
        }
      })
    })
  }


  // checkFoodSubscriptionStatus() {

  //   let userId = this.sharedService.getFromLocalStorage("userId");
  //   let planId = this.sharedService.getFromLocalStorage("planId");
  //   let subscriptionDate = this.sharedService.getFromLocalStorage("planDate");

  //   let data = {
  //     "endDate": subscriptionDate.endDate,
  //     "orderId": this.orderId,
  //     "paymentFor": "ORDER",
  //     "planId": planId,
  //     "pointsUsed": 0,
  //     "startDate": subscriptionDate.startDate,
  //     "userId": userId
  //   }
  //   this.codiningService.checkUserFoodSubscriptionPaymentStatus(data).subscribe(() => {
  //     this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "ORDER_PLACED" }).afterClosed().subscribe(() => {

  //       this.router.navigate(['profile/user/user-foodsubscription-list']);
  //     });
  //     this.sharedService.storeInLocalStorage("planId", null);
  //     this.sharedService.storeInLocalStorage("planDate", null);
  //   }, (err: any) => {

  //   }, () => {
  //   })
  // }

  checkPropertyFoodSubscription() {
    const userId = this.sharedService.getFromLocalStorage("userId");
    const planId = this.sharedService.getFromLocalStorage("subscriptionPlanId");
    const subscriptionPlanStartDate = this.sharedService.getFromLocalStorage("subscriptionPlanStartDate");
    const subscriptionPlanEndDate = this.sharedService.getFromLocalStorage("subscriptionPlanEndDate");

    const data = {
      // "bedName": "string",
      // "cf_payment_id": "string",
      "endDate": subscriptionPlanStartDate,
      "orderId": this.orderId,
      "paymentFor": "ORDER",
      // "paymentSessionId": "string",
      // "payment_amount": "string",
      // "payment_message": "string",
      // "payment_method": "string",
      // "payment_status": "string",
      // "payment_time": "string",
      "planId": planId,
      "pointsUsed": 0,
      // "referenceId": "string",
      // "signature": "string",
      "startDate": subscriptionPlanEndDate,
      "userId": userId
    }
    this.codiningService.checkUserFoodSubscriptionPaymentStatus(data).subscribe(() => {
      this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "ORDER_PLACED" }).afterClosed().subscribe(() => {

        if (this.screenWidth > 480) {
          this.router.navigate(['/profile/tab'], { queryParams: { type: 'SUBSCRIPTION' } });
        } else {
          this.router.navigate(['/profile/food-subscriptions']);
        }
        // this.router.navigate(['profile/user/user-foodsubscription-list']);
      });

      this.sharedService.storeInLocalStorage("subscriptionPlanId", null);
      this.sharedService.storeInLocalStorage("subscriptionPlanStartDate", null);
      this.sharedService.storeInLocalStorage("subscriptionPlanEndDate", null);
    }, (err: any) => {

    }, () => {
    })
  }

  checkOrderStatusOfDeliveryPayment() {
    const fcOrderId = this.sharedService.getFromLocalStorage("deliveryOrderId");
    let pointsToBeDeducted = this.sharedService.getFromLocalStorage("pointsToBeDeducted");

    this.codiningService.getOrderPaymentStatusForDeliveryPayment(
      this.orderId,
      fcOrderId,
      this.deliveryAddress ? this.deliveryAddress : {},
      this.deliveryAddress ? "DELIVERY" : "PICK_UP",
      (pointsToBeDeducted ? pointsToBeDeducted : 0)
    ).subscribe(
      (res: any) => {
        //console.log('order response', res);

        if (res.data.paymentStatus && res.data.paymentStatus === 'SUCCESS') {

          this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

            this.router.navigate(['profile/user/user-foodsubscription-list']);
          });
        } else {
          if (this.screenWidth <= 480) {

            this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

              this.router.navigate(['/profile/order-listing']);
            });
          } else {
            this.router.navigate(['/profile/tab'], { queryParams: { type: 'ORDER_FAILED' } });
          }
        }

      },
      (err: any) => {
        if (this.screenWidth <= 480) {

          this.sharedUtilService.openDialogBox(SuccessPopupComponent).afterClosed().subscribe(() => {

            this.router.navigate(['/profile/order-listing']);
          });
        } else {
          this.router.navigate(['/profile/tab'], { queryParams: { type: 'ORDER_FAILED' } });
        }
      }
    );
  }

  checkOneClickPaymentStatus() {
    this.codiningService.getOrderPaymentStatusForOneClick(this.orderId)
      .subscribe(
        (res: any) => {
          this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "ORDER_PLACED" }).afterClosed().subscribe(() => {
            if (this.screenWidth <= 480) {

              this.router.navigate(['/profile/order-listing']);
            }
            else {
              this.router.navigate(['/profile/tab'], { queryParams: { type: 'MY_ORDER' } });
            }
          });

          this.sharedService.storeInLocalStorage("paymentFor", null);
        },
        (err: any) => {
          this.sharedService.openSnackBar("Failed to Check Staus");
          this.sharedService.storeInLocalStorage("paymentFor", null);
          this.router.navigate([`/mbd/${this.foodCourtName}`]);
        }
      );
  }

  checkOrderStatusOfCartPayment() {
    this.orderSummaryService.getOrderPaymentStatusForCartPayment(this.orderId).subscribe(
      (res: any) => {

        if (res.data.paymentStatus === "SUCCESS") {
          this.storageService.clearAllItemsFromCart().then(() => {

            this.sharedService.storeInLocalStorage("paymentFor", null);
            this.sharedService.storeInLocalStorage("orderingFCId", null);

            this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "ORDER_PLACED" }).afterClosed().subscribe(() => {
              this.sharedService.storeInLocalStorage("orderId", res.data.fcOrderId)
              if (this.screenWidth <= 480)
                this.router.navigate(['/profile/order-summary']);
              else
                this.router.navigate(['/profile/tab'], { queryParams: { type: 'MY_ORDER' } });
            });
          })
        } else {
          this.dialogBoxFailed();
        }

      },
      (err) => {
        this.dialogBoxFailed();
      }
    );
  }

  dialogBoxFailed() {
    this.sharedUtilService.openDialogBox(SuccessPopupComponent, { type: "ORDER_FAILED" }).afterClosed().subscribe(() => {

      this.router.navigate(['/mbd/cart']);
    });
  }
}
