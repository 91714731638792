import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class userDetailsStorageService {

  constructor(
  ) {
    this.createDataBase();
  }

  databaseName: string = "USER_PROPERTY_DETAILS";
  databaseVersion: number = 1;
  objectStorageName: string = "USER_PROPERTY";
  database!: IDBDatabase;

  async createDataBase() {
    const request: IDBOpenDBRequest = window.indexedDB.open(this.databaseName, 1);

    request.onerror = () => { };
    request.onsuccess = async (event) => {
      this.database = await (event.target as IDBOpenDBRequest).result;
    };
    request.onupgradeneeded = (event) => {

      this.database = (event.target as IDBOpenDBRequest).result;
      const objectStore = this.database.createObjectStore(this.objectStorageName, { keyPath: "UserId" });
    };
  }

  getUserPropertyDetails() {
    const request = this.database.transaction(this.objectStorageName).objectStore(this.objectStorageName).getAll();
    return request;
  }

  storeUserPropertyDetails(userDetails: any) {
    const transaction = this.database.transaction(this.objectStorageName, "readwrite");
    const store = transaction.objectStore(this.objectStorageName);
    store.put(userDetails);
  }

  async addObject(data: any) {
    if (!this.database) {
      // throw new Error("IndexDB is not initialized");
      return new Promise((resolve, _reject) => resolve(false));
    }
    else {
      this.storeUserPropertyDetails(data);
    }
  }

  clearAllData() {
    return new Promise(async (resolve, reject) => {
      if (this.database != undefined) {
        const request = await this.database
          .transaction(this.objectStorageName, 'readwrite')
          .objectStore(this.objectStorageName)
          .clear();

        request.onsuccess = await function (event: any) {

          if (event.target.result) {
            resolve(event.result);
          } else {
            resolve('SUCCESS');
          }
        };
        request.onerror = await function (_event) {
          reject("No data");
        }
      }
    });
  }

}
