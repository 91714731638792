import { Component, HostListener, Optional, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../services/shared-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-partnership-form',
  templateUrl: './partnership-form.component.html',
  styleUrls: ['./partnership-form.component.scss', './mq-partnership-form.component.scss']
})
export class PartnershipFormComponent implements OnInit {

  constructor(
    @Optional() private dialogRef: MatDialogRef<PartnershipFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogBottomSheetRef: MatBottomSheetRef<PartnershipFormComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private shareService: SharedService,
    private _snackbar: MatSnackBar
  ) { }

  propertyButtons: any;
  selectedCodiningPartnerType: any;
  selectedColivingPartnerType: any;
  selectedCodiningBuilderPartnershipType: any;
  selectedCodiningCollegePartnershipType: any;
  selectedcoDiningFoodbrandPartnershipType: any;
  cusineType: any;
  selectedcoDiningBuildersorDeveloperandLeaseRealEstate: any;
  selectedcoDiningCollegeOrCoporateOpenFoodcourt: any;
  selectedcoLivingBuilderPartnershipType: any;
  selectedcoLviingCollegePartnershipType: any;
  selectedcoLivingBuildersorDeveloperandLeaseRealEstate: any;
  selectedcoLivingCollegeOrCoporateOpenFoodcourt: any;
  selectedRadioButton: any;
  name: any = '';
  mobileNumber: any = '';
  emailId: any = '';
  area: any = '';
  turnOver: any = '';
  selectedPartnershipType: any;
  selectedPropertyType: any;
  foodBusinessType: any;
  description: any;
  type: any;

  ngOnInit() {
    // console.log(this.data)
    this.getScreenWidth();
    this.selectedRadioButton = 'AGENT';
    if (this.data.data.type === 'NORMAL') {
      this.propertyButtons = this.topButtons[0].value;
      this.selectedCodiningPartnerType = (this.data.data.additionalInfo == 'BUILDERS' ? this.coDiningPartnerType[0].value : (this.data.data.additionalInfo === 'FOOD_BRAND' ? this.coDiningPartnerType[2].value : this.coDiningPartnerType[1].value));
      this.selectedColivingPartnerType = this.coLivingPartnerType[0].value;
      this.selectedCodiningBuilderPartnershipType = this.coDiningBuilderPartnershipType[0].value;
      this.selectedCodiningCollegePartnershipType = this.coDiningCollegePartnershipType[0].value;
      this.foodBusinessType = this.coDiningFoodbrandPartnershipType[0].value;
      this.selectedcoDiningBuildersorDeveloperandLeaseRealEstate = this.coDiningBuildersorDeveloperandLeaseRealEstate[0].value;
      this.selectedcoDiningCollegeOrCoporateOpenFoodcourt = this.coDiningCollegeOrCoporateOpenFoodcourt[0].value;
      this.selectedcoLivingBuilderPartnershipType = this.coLivingBuilderPartnershipType[0].value;
      this.selectedcoLviingCollegePartnershipType = this.coLviingCollegePartnershipType[0].value;
      this.selectedcoLivingBuildersorDeveloperandLeaseRealEstate = this.coLivingBuildersorDeveloperandLeaseRealEstate[0].value;
      this.selectedcoLivingCollegeOrCoporateOpenFoodcourt = this.coLivingCollegeOrCoporateOpenFoodcourt[0].value;
      this.selectedPartnershipType = this.selectedCodiningBuilderPartnershipType;
      this.selectedPropertyType = this.coDiningBuildersorDeveloperandLeaseRealEstate[0].value;
    }
    else {
      this.topButtons.forEach((elem: any) => {
        if (elem.value === this.data.data.type) {
          elem.status = true;
          this.propertyButtons = elem.value;
          if (this.data.data.type === 'CO_LIVING') {
            this.selectedColivingPartnerType = this.coLivingPartnerType[0].value;
            this.selectedPartnershipType = this.coLivingBuilderPartnershipType[0].value;
            this.selectedPropertyType = this.coLivingBuildersorDeveloperandLeaseRealEstate[0].value;
          }
          else {
            this.selectedCodiningPartnerType = (this.data.data.additionalInfo == 'BUILDER' ? this.coDiningPartnerType[0].value : (this.data.data.additionalInfo === 'FOOD_BRAND' ? this.coDiningPartnerType[2].value : this.coDiningPartnerType[1].value))
            this.selectedPartnershipType = this.coDiningBuilderPartnershipType[0].value;
            this.selectedPropertyType = this.coDiningBuildersorDeveloperandLeaseRealEstate[0].value;
          }
        }
        else {
          elem.status = false
        }
      });
    }

    // console.log(this.data.data)
  }

  topButtons: any = [
    {
      name: 'Co-dining',
      value: 'CO_DINING',
      status: true
    },
    {
      name: 'co-living',
      value: 'CO_LIVING',
      status: false
    }
  ]

  coDiningPartnerType: any = [
    {
      name: 'Property Owners',
      value: 'BUILDERS_DEVELOPERS',
      status: true
    },
    {
      name: 'College/Corporate',
      value: 'COLLEGE_CORPORATE',
      status: false
    },
    {
      name: 'Food Brand Partner',
      value: 'FOOD_VENDORS',
      status: false
    }
  ]

  coLivingPartnerType: any = [
    {
      name: 'Property Owners',
      value: 'BUILDERS_DEVELOPERS',
      status: true
    }
    // {
    //   name:'College',
    //   value:'COLLEGE_CORPORATE',
    //   status : false
    // }
  ]

  coDiningBuilderPartnershipType: any = [
    {
      name: 'Lease real estate for food court',
      value: 'Lease real estate for food court',
      status: true
    },
    {
      name: 'To Operate existing food court',
      value: 'To Operate existing food court',
      status: false
    },
    {
      name: 'Build To suite to start food court',
      value: 'Build To suite to start food court',
      status: false
    }
  ];

  coDiningCollegePartnershipType: any = [
    {
      name: 'Operate food court',
      value: 'Operate food court',
      status: true
    }
  ]

  coDiningFoodbrandPartnershipType: any = [
    {
      name: 'franchise owned',
      value: 'franchise owned'
    },
    {
      name: 'self owned',
      value: 'self owned'
    }
  ];


  coDiningBuildersorDeveloperandLeaseRealEstate: any = [
    {
      name: 'Shopping Mall',
      value: 'Shopping Mall'
    },
    {
      name: 'High street',
      value: 'High street'
    },
    {
      name: 'Fine Codine',
      value: 'Fine Codine'
    },
    {
      name: 'travel plaza',
      value: 'travel plaza'
    }
  ]

  coDiningCollegeOrCoporateOpenFoodcourt: any = [
    {
      name: 'Education Institute',
      value: 'Education Institute'
    },
    {
      name: 'Tech Park',
      value: 'Tech Park'
    },
    {
      name: 'Individual complex',
      value: 'Individual complex'
    }
  ];



  // coliving related arrays

  coLivingBuilderPartnershipType: any = [
    {
      name: 'Lease real estate for co-living',
      value: 'Lease real estate for co-living',
      status: true
    },
    {
      name: 'To Operate existing co-lving',
      value: 'To Operate existing co-lving',
      status: false
    },
    {
      name: 'Build To suite to start co-lving',
      value: 'Build To suite to start co-lving',
      status: false
    }
  ];

  coLviingCollegePartnershipType: any = [
    {
      name: 'Operate co-living',
      value: 'Operate co-living',
      status: true
    }
  ];


  coLivingBuildersorDeveloperandLeaseRealEstate: any = [
    {
      name: 'Flat',
      value: 'Flat'
    },
    {
      name: 'Building',
      value: 'Building'
    },
    {
      name: 'PG / Hostel',
      value: 'PG / Hostel'
    }
  ];
  coLivingCollegeOrCoporateOpenFoodcourt: any = [
    {
      name: 'Off Campus',
      value: 'Off Campus'
    },
    {
      name: 'On Campus',
      value: 'On Campus'
    }
  ];



  propertyTypeListing: any[] = [
    { name: 'Flat', value: 'FLAT' },
    { name: 'Building', value: 'BUILDING' },
    { name: 'PG / Hostel', value: 'PG_HOSTEL' },
  ];


  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenWidth() {
    this.screenWidth = window.innerWidth;
  }

  // closeDialogBox() {
  //   if (this.screenWidth > 480) {
  //     this.dialogRef.close();
  //   } else {
  //     this.dialogBottomSheetRef.dismiss();
  //   }
  // }

  closeDialogBox() {
    this.dialogRef.close();
  }

  changeButtonType(item: any) {
    this.topButtons.forEach((elem: any) => {
      if (elem.value === item.value) {
        elem.status = true;
        this.propertyButtons = elem.value;
        if (this.propertyButtons === 'CO_LIVING') {
          this.selectedColivingPartnerType = this.coLivingPartnerType[0].value;
          this.selectedPartnershipType = this.coLivingBuilderPartnershipType[0].value;
          this.selectedPropertyType = this.coLivingBuildersorDeveloperandLeaseRealEstate[0].value;
        }
        else {
          this.selectedCodiningPartnerType = this.coDiningPartnerType[0].value;
          this.selectedPartnershipType = this.selectedCodiningBuilderPartnershipType;
          this.selectedPropertyType = this.coDiningBuildersorDeveloperandLeaseRealEstate[0].value;
        }
      }
      else {
        elem.status = false;
      }
    });
    // console.log(this.selectedPartnershipType);
  }

  selectingPartnershipType(ev: any, type: any) {
    if (type === 'CO_LIVING') {
      if (ev === 'BUILDERS_DEVELOPERS') {
        this.selectedPartnershipType = this.coLivingBuilderPartnershipType[0].value;
        this.selectedPropertyType = this.coLivingBuildersorDeveloperandLeaseRealEstate[0].value;
      }
      else {
        this.selectedPartnershipType = this.coLviingCollegePartnershipType[0].value;
        this.selectedPropertyType = this.coLivingCollegeOrCoporateOpenFoodcourt[0].value;
      }
    }
    else {
      if (ev === 'BUILDERS_DEVELOPERS') {
        this.selectedPartnershipType = this.coDiningBuilderPartnershipType[0].value;
        this.selectedPropertyType = this.coDiningBuildersorDeveloperandLeaseRealEstate[0].value;
      }
      else if (ev === 'COLLEGE_CORPORATE') {
        this.selectedPartnershipType = this.coDiningCollegePartnershipType[0].value;
        this.selectedPropertyType = this.coDiningCollegeOrCoporateOpenFoodcourt[0].value;
      }
      else {
        this.foodBusinessType = this.coDiningFoodbrandPartnershipType[0].value;
        this.selectedPropertyType = ''
        this.selectedPartnershipType = '';
      }
    }
  }

  selectingPropetyType(ev: any, partnerType: any, btnType: any) {
    if (btnType === 'CO_LIVING') {
      if (partnerType === 'BUILDERS_DEVELOPERS') {
        if (ev === 'Lease real estate for co-living') {
          this.selectedPropertyType = this.coLivingBuildersorDeveloperandLeaseRealEstate[0].value;
        }
        else {
          this.selectedPropertyType = '';
        }
      }
      else {
        if (ev === 'Operate co-living') {
          this.selectedPropertyType = this.coLivingCollegeOrCoporateOpenFoodcourt[0].value;
        }
      }
    }
    else {
      if (partnerType === 'BUILDERS_DEVELOPERS') {
        if (ev === 'Lease real estate for food court') {
          this.selectedPropertyType = this.coDiningCollegeOrCoporateOpenFoodcourt[0].value;
        }
        else {
          this.selectedPropertyType = '';
        }
      }
      else if (partnerType === 'COLLEGE_CORPORATE') {
        if (ev === 'Operate food court') {
          this.selectedPropertyType = this.coDiningCollegeOrCoporateOpenFoodcourt[0].value;
        }
      }
      else {
        this.selectedPropertyType = ''
      }
    }
    // console.log(this.selectedPropertyType);
  }


  submitLoader: boolean = false;

  submit() {
    this.submitLoader = true;
    let data: any;
    if (this.propertyButtons === 'CO_LIVING') {
      data = {
        agentOrOwner: this.selectedRadioButton,
        email: this.emailId,
        mobile: this.mobileNumber,
        name: this.name,
        description: this.description,
        partnershipVertical: 'CO_LIVING',
        partnerType: this.selectedColivingPartnerType,
        partnerShipType: this.selectedPartnershipType,
        propertyType: this.selectedPropertyType,
        currentTurnOver: this.turnOver,
        cuisineType: this.cusineType,
        areaInSqFeet: this.area,
        foodBusinessType: this.foodBusinessType
      }
    }
    else {
      data = {
        agentOrOwner: this.selectedRadioButton,
        email: this.emailId,
        mobile: this.mobileNumber,
        name: this.name,
        description: this.description,
        partnershipVertical: 'CO_DINING',
        partnerType: this.selectedCodiningPartnerType,
        partnerShipType: this.selectedPartnershipType,
        propertyType: this.selectedPropertyType,
        currentTurnOver: this.turnOver,
        cuisineType: this.cusineType,
        areaInSqFeet: this.area,
        foodBusinessType: this.foodBusinessType
      }
    }

    this.shareService.createpartnerShipRequest(data).subscribe(
      (res: any) => {
        // console.log(res.data);
        this._snackbar.open(
          `${res.data}`,
          'Ok',
          {
            duration: 1500
          }
        );
        this.dialogRef.close()
        this.submitLoader = false;
      },
      (err) => {
        this.submitLoader = false;
        // console.log(err.error.message)
      }
    )
  }
}
