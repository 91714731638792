import { ITEM_STATUS, ITEM_TYPE, Item } from "./Item.model";
import { OrderedItemCustomizations } from "./OrderedItemCustomization.model";

export class CartItem {

  "isCartObject": boolean = true;
  "customizationPrice": number = 0;
  "itemCategory": string;
  "itemId": string;
  "appItemId": string;
  "itemMealCategory": "BREAKFAST" | "LUNCH" | "SNACKS" | "DINNER";
  "itemName": string;
  "name": string;
  "itemQuantity": number;
  "itemStatus": ITEM_STATUS;
  "price": number;
  "markUpPrice": number = 0;
  "markUpTaxValue": number = 0;
  "markUpTotalPrice": number = 0;
  "markUpTotalTax": number = 0;
  "markUpTaxPerItem": number = 0;
  "packagingCharge": number;
  "veg": boolean;
  "itemType": "VEG" | "NON_VEG" = "VEG";
  "vendorId": string;
  "vendorName": string;
  "taxPerItem": number = 0;
  "taxPercentPerItem": number = 0;
  "taxCategory": "MRP" | "NON_MRP";
  "preOrder": boolean;
  "preOrderDate": Date;
  "preOrderStartTime": Date;
  "preOrderEndTime": Date;
  "isCustomizationRequired": boolean = false;
  "orderedItemCustomizations": any;
  "cookingInstruction": string;

  constructor(id?: string, name?: string, price?: number, itemCategory?: string, itemImageUrl?: string, itemStatus?: ITEM_STATUS, itemType?: ITEM_TYPE, mrpItem?: boolean, vendorName?: string, itemQuantity?: number, taxPercentPerItem?: number, taxPerItem?: number, orderedItemCustomizations?: OrderedItemCustomizations[]) {
    this.itemId = id || "";
    this.appItemId = "";
    this.itemName = name || "";
    this.price = price || 0;
    this.itemCategory = itemCategory || "";
    this.itemStatus = itemStatus || ITEM_STATUS.VENDOR_AVAILABLE;
    this.veg = itemType === ITEM_TYPE.VEG;
    this.vendorName = vendorName || "";
    this.taxPercentPerItem = taxPercentPerItem || 0;
    this.taxPerItem = taxPerItem || 0;
    this.itemQuantity = itemQuantity || 0;
  }

  convertToCartItem(itemDetails: Item) {
    this.itemName = itemDetails.name || itemDetails.itemName;
    this.itemQuantity = itemDetails.itemQuantity;
    this.itemId = itemDetails.id || itemDetails.itemId;
    this.itemCategory = itemDetails.itemCategory;
    this.itemStatus = itemDetails.itemStatus;
    this.price = itemDetails.price;
    this.markUpPrice = itemDetails.markUpPrice;
    this.markUpTaxPerItem = itemDetails.markUpTaxValue;
    this.markUpTaxValue = itemDetails.markUpTaxValue;
    this.markUpTotalPrice = itemDetails.markUpPrice * itemDetails.itemQuantity;
    this.markUpTotalTax = itemDetails.markUpTaxValue * itemDetails.itemQuantity;
    this.packagingCharge = itemDetails.packagingCharge;
    this.veg = itemDetails.itemType === ITEM_TYPE.VEG;
    this.vendorId = itemDetails.vendorId;
    this.vendorName = itemDetails.vendorName;
    this.taxPerItem = itemDetails.price * (itemDetails.taxPercentPerItem/100);
    this.taxCategory = itemDetails.taxCategory;
    this.appItemId = itemDetails.appItemId;
    this.itemMealCategory = "BREAKFAST";
    this.taxPercentPerItem = itemDetails.taxPercentPerItem;
    this.orderedItemCustomizations = itemDetails.orderedItemCustomizations;
    // this.preOrder = ;
    // this.preOrderDate = ;
    // this.preOrderStartTime = ;
    // this.preOrderEndTime = ;
  }
}
