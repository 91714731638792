<section class="enquiry-dialog-main-section">
  <div class="enquiry-mobile-header-section" *ngIf="screenWidth <= 480">
    <div class="mobile-main-heading">
      Enquiry Details
    </div>
    <div class="close-mobile-image">
      <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="close" (click)="closeDialog()">
    </div>
  </div>
  <div class="enquiry-dialog-left-section">
    <!-- <div class="enquiry-month-image-section">
      <img src="../../../assets/common/enquiry-month.png" alt="month" />
    </div> -->
    <div class="enquiry-form-section">
      <div class="enquiry-form-header" *ngIf="screenWidth > 480">
        <div class="main-heading">
          Enquiry
        </div>
        <div class="description">
          Got questions or need more information? Feel free to enquire. We're here to assist you!
        </div>
      </div>
      <div class="form-section">
        <div class="form-container">
          <div class="form-label">Your Name <sup>*</sup></div>
          <input type="text" placeholder="Enter your name" [(ngModel)]="enquiryDetails.name">
        </div>
        <div class="form-container">
          <div class="form-label">Locality <sup>*</sup></div>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="enquiryDetails.localityname">
              <mat-option value="">--Select--</mat-option>
              <mat-option [value]="locality.name" *ngFor="let locality of localityList">{{ locality.name | titlecase
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-container">
          <div class="form-label">Mobile number <sup>*</sup></div>
          <input type="number" placeholder="Enter Mobile number" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
            onKeyPress="if(this.value.length==10) return false;" [(ngModel)]="enquiryDetails.mobileno">
        </div>
        <div class="form-container">
          <div class="form-label">Description</div>
          <textarea type="text" placeholder="Describe your enquiry"
            [(ngModel)]="enquiryDetails.description"> </textarea>
        </div>
        <div class="footer-section">
          <button class="submit-btn" (click)="submitEnquiry()" *ngIf="!paymentButtonLoader" [disabled]="enquiryDetails.name === null || enquiryDetails.mobileno === null
          || enquiryDetails.localityname === ''">Submit
          </button>
          <button class="submit-btn" *ngIf="paymentButtonLoader">Loading...</button>
        </div>

      </div>
    </div>
  </div>
  <div class="enquiry-dialog-right-section">
    <div class="enquiry-rightside-image">
      <img src="../../../assets/common/enquiry-right-image.png" alt="right-enquiry" />
    </div>
    <div class="enquiry-celebrate-section">
      <img src="../../../assets/common/enquiry-celebrate.png" alt="celebrate" />
    </div>
    <div class="close-image">
      <img loading="lazy" src="../../../assets/common/close-black-btn.png" alt="close" (click)="closeDialog()">
    </div>
  </div>
</section>