import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CartPageService {

  constructor(
    private http: HttpClient
  ) { }

  getVendorDetails(vendorId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/vendor/ist/vendor/${vendorId}`);
  }

  doCashFreePayment(payload: any) {
    return this.http.post(environment.baseUrl + `/foodcourt/payment/ist/order`, payload);
  }

  payWithIstharaPoints(userId: string, note: any) {
    return this.http.post(environment.baseUrl + `/foodcourt/ist/order/create/${userId}`, note);
  }

  removeCouponFromUserCart(userId: string) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/discount/ist/v1/${userId}/remove`,
      {}
    );
  }

  getCartData(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }
}
