export class OrderedItemCustomizations {
    active: boolean;
    markupPrice: number;
    categoryId: string;
    name: string;
    packagingCharge: number;
    price: number;
    type: "ADD_ON" | "VARIENT";

    constructor() {
        this.active = true;
        this.markupPrice = 0;
        this.categoryId = "";
        this.name = "";
        this.packagingCharge = 0;
        this.price = 0;
        this.type = "ADD_ON";
    }
}