import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodiningService } from '../../codining.service';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { OrderService } from '../../shared-services/order-service.service';

enum VIEW_PAGE {
  ADDRESS_PAGE = "ADDRESS_PAGE",
  ENTER_ADDRESS = "ENTER_ADDRESS"
}

@Component({
  selector: 'app-delivery-address-page',
  templateUrl: './delivery-address-page.component.html',
  styleUrls: ['./delivery-address-page.component.scss', './mq-delivery-address-page.component.scss']
})
export class DeliveryAddressPageComponent implements OnInit {

  constructor(
    private router: Router,
    private codiningService: CodiningService,
    private orderService: OrderService,
    private sharedLocalStorageService: SharedService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private _map: MapsAPILoader,
    private activatedRoute: ActivatedRoute
  ) {
    this.mapsAPILoader = mapsAPILoader;
    this.ngZone = ngZone;
    this.wrapper = wrapper;
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  geocoder: any;
  ngOnInit(): void {
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    this.getUserAddress();
    this.getScreenSize();
    this.checkParam();
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  currentType: 'true' | 'false' = 'false';
  commingFromPage: string | null = null;
  checkParam() {
    this.activatedRoute.queryParams.subscribe((qParam: any) => {
      this.currentType = qParam['static'];
      this.commingFromPage = qParam['comingFrom'];
    })
  }

  presentPage: VIEW_PAGE = VIEW_PAGE.ADDRESS_PAGE;
  userId: string | null = null;
  userAddressList: any;
  screenLoading: boolean = false;

  selectedAddressType: string = "WORK";
  addressTypeChips = ["WORK", "COLLEGE", "HOSTEL", "OTHER"];

  username: string | null = null;
  usermobile: string | null = null;

  getUserAddress() {

    if (!this.userId)
      return;

    this.screenLoading = true;
    this.codiningService.getUserAddress(this.userId).subscribe((response: any) => {
      this.userAddressList = response.data;
      this.screenLoading = false;
    }, (error: any) => {

    })
  }

  deliveryAddress: any = null;
  selectAddress(address: any) {
    let data = {
      "address": address,
      "delivery": true,
      "orderType": "DELIVERY",
      "userId": this.userId
    }
    this.orderService.changeOrderStatus(data).subscribe((response: any) => {
      this.deliveryAddress = response.data.address;
      this.goToCartPage();
    }, (error: any) => {
      this.sharedLocalStorageService.openSnackBar("Failed to Select Address");
    })
  }

  changeView(viewPoint: string) {
    if (viewPoint === "ENTER_ADDRESS")
      this.presentPage = VIEW_PAGE.ENTER_ADDRESS;
    else
      this.presentPage = VIEW_PAGE.ADDRESS_PAGE;
  }

  locationDetail = {
    addressLine: '',
    geoLocation: {
      latitude: 17.385,
      longitude: 78.4867,
      draggable: true,
      label: '',
      title: 'College',
    },
  };

  icon = {
    url: '../../../../../assets/common/location-icon.svg',
    scaledSize: { height: 40, width: 40 },
  };
  onMapReady(map: any) {
    //console.log('EVENT', map);
    map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
        style: google.maps.ZoomControlStyle.DEFAULT,
      },
    });
    //console.log(map);
  }



  @ViewChild('AgmMap') AgmMap: any;
  @ViewChild('addresstext') addresstext: ElementRef | undefined;

  manualLat: any;
  manualLng: any;
  markerDragEnd(m: any) {
    this.manualLat = m.coords.lat;
    this.manualLng = m.coords.lng;
    this.locationDetail.geoLocation.latitude = m.coords.lat;
    this.locationDetail.geoLocation.longitude = m.coords.lng;
    this.findAddressByCoordinates();
  }

  findAddressByCoordinates() {
    this.geocoder.geocode(
      {
        location: {
          lat: this.locationDetail.geoLocation.latitude,
          lng: this.locationDetail.geoLocation.longitude,
        },
      },
      (results: any, status: any) => {
        // this.decomposeAddressComponents(results);
      }
    );
    //console.log(this.geocoder);
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locationDetail.geoLocation.longitude = position.coords.longitude;
          this.locationDetail.geoLocation.latitude = position.coords.latitude;
        },
        () => {
          //console.log('IMPORTANT: User has blocked geolocation');
          // this.userPosition.userLatitute = 0;
          // this.userPosition.userLongitute = 0;
        }
      );
    } else {
      //console.log('No support for geolocation');
    }
  }

  loading: boolean = false;
  addNewAddress() {
    this.loading = true;

    let data = {
      address: this.locationDetail.addressLine,
      addressTitle: this.locationDetail.geoLocation.title,
      geolocation: {
        latitude: this.locationDetail.geoLocation.latitude,
        longitude: this.locationDetail.geoLocation.longitude,
      },
      mobile: this.usermobile,
      name: this.username,
      userId: this.userId,
    };
    //console.log(data);

    this.codiningService.addNewAddressOfuser(data).subscribe(
      (res: any) => {
        this.deliveryAddress = res.data;
        this.goToCartPage();
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  goToCartPage() {

    if (this.commingFromPage === "ORDER_SUMMARY") {
      this.sharedLocalStorageService.storeInLocalStorage("userAddressId", this.deliveryAddress?.addressId);
      if (this.screenWidth <= 480)
        this.router.navigate(['/profile/order-summary']);
      else
        this.router.navigate(['/profile/tab'], { queryParams: { type: "ORDER_SUMMARY" } });
    } else {
      if (this.screenWidth <= 480)
        this.router.navigate(['/mbd/cart']);
      else
        this.router.navigate(['/mbd/cart'], { queryParams: { static: this.currentType === 'true' ? 'false' : 'true' } });

    }

  }
}
