import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { CodiningService } from '../../codining.service';
import { HomePageService } from '../home-page/home-page.service';
import { PartnershipFormComponent } from '../../../website/shared-component/partnership-form/partnership-form.component';

@Component({
  selector: 'app-codining-landing-page',
  templateUrl: './codining-landing-page.component.html',
  styleUrls: ['./codining-landing-page.component.scss', './mq-codining-landing-page.component.scss']
})
export class CodiningLandingPageComponent implements OnInit, OnDestroy {

  constructor(
    private sharedLocalStorageService: SharedService,
    private router: Router,
    private _sharedService: SharedService,
    private codiningService: CodiningService,
    private homePageService: HomePageService
  ) {
  }

  slideConfig = {
    "slidesToShow": 1,
    "centerMode": true,
    "slidesToScroll": 1,
    "infinite": true,
    "autoplay": true,
    "variableWidth": true,
    "autoplaySpeed": 3000,
    "dots": true,
    "arrows": true,
    "pauseOnHover": false
  };


  slideConfigs = {
    "slidesToShow": 1,
    "centerMode": true,
    "slidesToScroll": 1,
    "infinite": true,
    "autoplay": true,
    "variableWidth": true,
    "autoplaySpeed": 5000,
    "dots": true,
    "arrows": false,
    "pauseOnHover": false
  };
  foodCourtId: string | null = null;
  foodCourtName: string | null = null;
  techIntegrationLeft: number = 0;

  bannerImageFromLeft: number = 0;

  // promotionalBannerList: any[] = [
  //   { bannerImageWeb: "isthara-banner-1-tab", bannerImage: "isthara-banner-1" },
  //   { bannerImageWeb: "isthara-banner-2-tab", bannerImage: "isthara-banner-2" },
  //   { bannerImageWeb: "isthara-banner-3-tab", bannerImage: "isthara-banner-3" },
  // ]


  bannerImageRef: any;
  techIntegrationRef: any;

  ngOnDestroy() {
    clearInterval(this.bannerImageRef);
    clearInterval(this.techIntegrationRef);
  }

  ngOnInit(): void {

    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    this.foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");

    const userId = this.sharedLocalStorageService.getFromLocalStorage("userId");

    this.positionForTestimonialsList = (this.testimonials.length - 1) * 50;
    this.nextTestimonialsButton = this.testimonials.length > 1;

    this.bannerImageRef = setInterval(() => {
      this.techIntegrationLeft -= 100;
    }, 3000);
    this.techIntegrationRef = setInterval(() => {
      this.bannerImageFromLeft += 100;
    }, 7000);

    if (this.foodCourtId) {
      this.getFoodCourtDetails();
    }
    if (this.foodCourtId) {
      this.getPromotionalDataListing();
    }
    else {
      this.getPromotionalBannerList();
    }


    this.getScreenWidth();
  }

  screenWidth: number = 0;

  techIntegrationFeatures = [
    { name: "Queueless Ordering", description: 'Experience the queueless ordering. goodbye lines, hello hassle-free dining.', imgUrl: "queueless-ordering" },
    { name: "Multi-Ordering System", description: 'Experience orders from multiple restaurants in one go! No more app switching or separate deliveries.', imgUrl: "multi-ordering-system" },
    { name: "Multiple-Payments", description: 'Your convenience, our priority, make multiple payments and real-time updates for seamless experiences.', imgUrl: "multiple-payments" },
  ]
  latestFeatures: any = [];

  eventFeatures = [
    {
      status: "Upcoming", primaryText: "Ganesh Chaturthi Celebrations",
      timing: "19th Sep, 11:00 am", location: "Isthara Coliving Lakshmi, Hyderabad",
      imgUrl: "events-1"
    },
    {
      status: "Upcoming", primaryText: "The Ultimate Music Concert",
      timing: "25th Sep, 10:00 am", location: "Isthara Co-dining, Hyderabad",
      imgUrl: "events-2"
    }
  ]

  @HostListener('window:resize', ['$event'])
  getScreenWidth() {
    this.screenWidth = window.innerWidth;
  }

  getFoodCourtDetails() {
    if (!this.foodCourtId)
      return;

    this.codiningService.getFoodocurtByFoodcourtId(this.foodCourtId).subscribe((response: any) => {
      let foodCourtName = response.data.foodCourtName;
      let siteName = response.data.siteName;
      let siteId = response.data.siteId;

      this.sharedLocalStorageService.storeInLocalStorage('foodCourtName', foodCourtName);
      this.sharedLocalStorageService.storeInLocalStorage('siteName', siteName);
      this.sharedLocalStorageService.storeInLocalStorage('siteId', siteId);
      this.router.navigate([`/mbd/${foodCourtName}`]);
    }, (error: any) => {
      this.sharedLocalStorageService.storeInLocalStorage('foodCourtName', null);
      this.router.navigate([`/mbd`]);
    })
  }


  promotionalBannerList: any = [];
  getPromotionalDataListing() {

    let foodCourtId: any | null = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    let userId: string | null = this.sharedLocalStorageService.getFromLocalStorage("userId");

    this.homePageService.getHomePageResponse(foodCourtId, userId).subscribe((response: any) => {
      this.promotionalBannerList = response.data.promotionalBannerList.filter((item: any) => (item.platform.includes('WEB') || item.platform.includes('MOBILE')) && (item.bannerVertical === 'BOTH' || item.bannerVertical === 'FOOD_COURT'));
    })
  }

  vertical: any = 'BOTH';
  getPromotionalBannerList() {
    this.codiningService.getPromotionbannerlist(this.vertical).subscribe((response: any) => {
      this.latestFeatures = response.data.filter((item: any) => (item.platform.includes('WEB') || item.platform.includes('MOBILE')) && (item.bannerVertical === 'BOTH' || item.bannerVertical === 'FOOD_COURT'));
    })
  }

  openEnquiryFormDialog() {
    this.router.navigate(['/enquiry']);
  }

  openPartnershipForm(item: any) {
    if (this.screenWidth > 480) {
      this._sharedService.openDialogConfig(
        PartnershipFormComponent,
        '630px',
        '660px',
        '80vw',
        { data: item }
      );
    } else {
      this._sharedService.openDialogConfig(
        PartnershipFormComponent,
        '100vh',
        '100%',
        '100vw',
        { data: item }
      );
    }
  }



  // testimonial section 

  // scroll to previous testimonial
  previousTestimonial() {
    this.positionForTestimonialsList += 100;

    if (!this.nextTestimonialsButton) this.nextTestimonialsButton = true;
    if (
      this.positionForTestimonialsList ===
      (this.testimonials.length - 1) * 50
    )
      this.prevTestimonialsButton = false;
  }

  // scroll to next testimonial
  nextTestimonial() {
    this.positionForTestimonialsList -= 100;

    if (!this.prevTestimonialsButton) this.prevTestimonialsButton = true;
    if (
      Math.abs(this.positionForTestimonialsList) ===
      (this.testimonials.length - 1) * 50
    )
      this.nextTestimonialsButton = false;
  }

  // For Testimonials
  positionForTestimonialsList: number = 0;
  nextTestimonialsButton: boolean = false;
  prevTestimonialsButton: boolean = false;

  testimonials: any[] = [
    {
      name: 'Muneer Ahmed',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-1.svg',
      occupation: ' KPHB Vidhura complex',
      text: "I came here in July 2023 and my fear was whether I will get a good place to stay. But to my surprise the rooms and the facility provided to me by Shashi and Harish along with one sales person Habeeb was excellent, I checked in with the Wifi login and entering the room I found the cleaning and temperature of the room pleasent. The air condition was not working though but they fixed it on time. It's been 2 months now the housekeeping and hospitality is regularly maintained. The laundry service is regularly taken and delivered on time. I have come here from North part of India as a routine transfer of job and position. But I feel very safe being a stranger to Hyderabad I didn't feel the urge of my city atmosphere the water supply and cleaning of the rooms are done neatly and regularly. The support from after sales is instant.  Cheers to the team Isthara Co-living at Chelsea.",
    },
    {
      name: 'Shivangi',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-3.svg',
      occupation: 'PV3 Siri Nilayam',
      text: "My experience staying at Isthara in rented room has been nothing short of excellent. The room came with all the essential facilities, including a comfortable bed, a well-equipped common kitchen and gym , and a clean private bathroom. The value for money is exceptional, as I've been able to enjoy a prime location without the hefty price tag of a full apartment. Overall, it's been a hassle-free and cost-effective living arrangement that I highly recommend to anyone seeking convenience and affordability.",
    },
    {
      name: 'Saikiran Arani',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-2.svg',
      occupation: 'Sri Tirumala Edifice',
      text: 'I have been with isthara almost close to 3.5 years .! Firstly i joined isthara in 2019 and have been with them for almost 2 years before covid.! Isthara was one of first corporate to provide paying Guest accomdation on single sharing/double/triple/coliving with all best in class aminities like gym,table tennis,carroms etc what not everyting was there.!',
    },
    {
      name: 'Neha',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-4.svg',
      occupation: 'Jayaberi Villa3',
      text: 'I am staying in isthara from past 5+ years and the stay has been really hassle free.It’s just like second home  so never felt like shifting. The services provided are good and quick also never had to remind them twice for the same. The cleanliness and hygiene is commendable also staff are very humble and kind would highly recommend this place who are looking for homely stay .',
    },
    {
      name: 'Humera Sayed',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-5.svg',
      occupation: 'DSR Nilayam',
      text: 'Hostel in the beginning is a place which we feel far off from our home and lonely. But, over the time the same place becomes crucial part of our lives,  a home away from home.  Hostel life is always said to be the best source of wonderful endless memories and experiences.Isthara  is absolutely perfect choice to help us create those memories . Superb hostel with the staff who are very friendly, helpful and go the extra mile.The facilities are cleaned, rooms are spacious. Most of their hostels has common area for  every three rooms. This gives you a “staying in a flat” like feeling. Another good point which is worth mentioning is their time in resolving the tickets. For any issues or concern a ticket is raised. The staff makes sure and try their best to tend to your issues immediately and resolve it with in 24 hours mostly (sometime even earlier).If you are planning to become part of it then definitely you have made a better choice  than many others. So relax! and enjoy every moment of your Isthara life ',
    },
    {
      name: 'Jyoti ',
      imageUrl: '../../../../assets/coliving/property-home/testimonial-6.svg',
      occupation: 'Jayaberi Villa3',
      text: 'I am staying in Isthara from past few years and my stay here is quite a really hassle free. The cleanliness and hygiene is commendable and also staff is very humble. The services provided here are good and quick. I feel like home living here in Isthara and would  recommend this place to all, who are looking for homely stay.',
    },
  ];

}
