import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimText'
})
export class TrimTextPipe implements PipeTransform {

  transform(text: string | undefined, length: number = 20): string {
    if (text === undefined)
      return "";
      
    return (text.length > length ? text.substring(0, length) + "..." : text);
  }

}
