<section class="notification-main-page">
  <div class="notification-header" *ngIf="screenWidth <= 480">
    <div class="notification-header__left-sec">
      <img loading="lazy" src="../../../../assets/codining/images/common/back_arrow.svg" alt="back"
        (click)="goToProfilePage()">
    </div>
    <div class="notification-header__right-sec">
      <div class="notification-header-text">Notifications</div>
      <!-- <div class="notification-header-sub-text"
        *ngIf="notificationData[0].list.length > 0 || notificationData[1].list.length > 0 || notificationData[2].list.length > 0">
        You have <span class="sub-text-data">{{notificationData[0].list.length + notificationData[1].list.length +
          notificationData[2].list.length + oldNotificationData.length}}</span> new notifications</div> -->
    </div>
  </div>

  <div class="notification-header" *ngIf="screenWidth > 480">
    <div class="notification-header__left-sec">
      Notifications
    </div>
    <div class="notification-header__right-sec">
      <img src="../../../../assets/codining/images/common/close.svg" alt="close.svg" (click)="closeButton()" />
      <!-- <div class="notification-header-sub-text"
        *ngIf="notificationData[0].list.length > 0 || notificationData[1].list.length > 0 || notificationData[2].list.length > 0">
        You have <span class="sub-text-data">{{notificationData[0].list.length + notificationData[1].list.length +
          notificationData[2].list.length + oldNotificationData.length}}</span> new notifications</div> -->
    </div>
  </div>

  <div class="notification-type-data">
    <div class="notification-status" *ngFor="let notificationStatus of notificationStatusListing"
      (click)="getOrderListByOrderStatus(notificationStatus)"
      [ngClass]="{'notification-status__active': notificationStatus.status}">{{ notificationStatus.name | titlecase }}
    </div>
  </div>

  <main class="main-section">
    <ng-container *ngIf="!showLoader; else loaderScreen">
      <ng-container
        *ngIf="(oldNotificationData.length > 0 ) || (notificationData[0].list.length > 0 || notificationData[1].list.length > 0 || notificationData[2].list.length > 0 ); else emptyScreen">

        <!-- codining section  -->
        <ng-container *ngIf="selectedNotificationStatusType === 'MBD'">
          <ng-container *ngFor="let element of notificationData">
            <ng-container *ngIf="element.list.length > 0">
              <h1>{{element.title | titlecase }}</h1>
              <ng-container *ngFor="let item of element.list; index as notifyIndex">
                <div class="card-section" [ngStyle]="{'background-color' : item.clicked ? 'white' : '#f0f0f0'}">
                  <div class="card-image">
                    <img src="../../../../assets/codining/images/notification/welcome-icon.svg" alt="Notification_Image"
                      *ngIf="item.customNotificationRequest.notificationType === 'WELCOME'">
                    <img src="../../../../assets/codining/images/notification/pay-dues-icon.svg" alt="event_Image"
                      *ngIf="item.customNotificationRequest.notificationType === 'EVENTS'">
                    <img src="../../../../assets/codining/images/notification/app-update-icon.svg" alt="Update_Image"
                      *ngIf="item.customNotificationRequest.notificationType ==='APP_UPDATES'">
                    <img src="../../../../assets/codining/images/notification/order-placed.svg" alt="Update_Image"
                      *ngIf="item.customNotificationRequest.notificationType ==='ORDERS'">
                    <span *ngIf="!item.clicked"></span>
                  </div>
                  <div class="card-main">
                    <div class="card-top">
                      <h2 class="card-title">{{ item.customNotificationRequest.title | titlecase }}</h2>
                      <span class="card-timing">{{ item.updateTimeStamp | date:'shortTime' }}</span>
                    </div>
                    <div class="card-middle">
                      <p class="card-content" *ngIf="!item.showMore">
                        {{ (item.customNotificationRequest.message.substring(0, 99) | titlecase) +
                        (item.customNotificationRequest.message.length > 100 ? '...' : '')}}
                      </p>
                      <p class="card-content" *ngIf="item.showMore">
                        {{ item.customNotificationRequest.message | titlecase}}
                      </p>
                      <!-- <span class="card-action">
                        <img src="../../../../../assets/images/notification/three_dots.svg" alt="Action"
                           class="dots-icon">
  
                        <div class="action-popup" *ngIf="item.showActionIcon">
                          <div class="action-icons">
                            <span><img src="../../../../../assets/images/notification/delete_icon.svg"
                                alt="Delete"></span>
                            <span>Delete</span>
                          </div>
                        </div>
                      </span> -->
                    </div>
                    <div class="card-bottom" (click)="showNotificationDetails(item)">
                      <span *ngIf="!item.showMore">View More</span>
                      <span *ngIf="item.showMore">View Less</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- coliving section  -->
        <ng-container *ngIf="selectedNotificationStatusType === 'COLIVING'">
          <div class="main-coliving-notification-section" *ngFor="let item of oldNotificationData">
            <div class="main-coliving-left-notification">
              <div class="image-section">
                <img src="../../../../assets/codining/images/side-nav/notification-icon.svg" alt="" />
              </div>
              <div class="notification-coliving-date">
                {{item?.CreatedDate | date:'mediumDate'}}
              </div>
            </div>
            <div class="main-coliving-right-notification">
              <div class="main-coliving-heading">
                {{item?.TitleMessage}}
              </div>
              <!-- <div class="main-coliving-sub-heading">
          {{item?.BodyContent}}
        </div> -->
              <div class="main-coliving-sub-heading">
                <p class="card-coliving" *ngIf="!item.showMore">
                  {{ (item.BodyContent.substring(0, 99)) +
                  (item.BodyContent.length > 100 ? '...' : '')}}
                </p>
                <p class="card-coliving" *ngIf="item.showMore">
                  {{ item.BodyContent }}
                </p>
              </div>
              <div class="card-bottom" (click)="showOldNotificationDetails(item)">
                <span *ngIf="!item.showMore">View More</span>
                <span *ngIf="item.showMore">View Less</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </main>
</section>

<ng-template #emptyScreen>
  <div class="empty-part">
    <div class="no-image">
      <img src="../../../../assets/codining/images/notification/notification-empty.svg" alt="empty-image" />
    </div>
    <div class="empty-heading">No Notifications</div>
    <div class="emptyrules-text">Stay tuned for updates and announcements!</div>
  </div>
</ng-template>

<ng-template #loaderScreen>
  <div class="loader-img-container">
    <img src="../../../../assets/common/istharaloader.gif" alt="" />
  </div>
</ng-template>