<section class="help-section">
    <div class="help-heading-sec">
        <div class="heading-text">Help</div>
        <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="close"
            (click)="closeDialogBox()">
    </div>
    <div class="contact-list">
        <ng-container *ngFor="let contactDetail of helpContactList">
            <div class="contact-container">
                <div class="contanct-key-value">
                    <div class="contact-key">Name:</div>
                    <div class="contact-value">{{ contactDetail.staffName | titlecase }}</div>
                </div>
                <div class="contanct-key-value">
                    <div class="contact-key">Phone:</div>
                    <div class="contact-value">{{ contactDetail.mobile.mobile }}</div>
                </div>
                <div class="contanct-key-value">
                    <div class="contact-key">Email:</div>
                    <div class="contact-value">{{ contactDetail.emailId }}</div>
                </div>
            </div>
            <div class="contact-sec-divider"></div>
        </ng-container>

        <!-- Isthara Contact Info -->
        <div class="contact-container">
            <div class="contanct-key-value">
                <div class="contact-key">Name:</div>
                <div class="contact-value">Isthara FoodCourt</div>
            </div>
            <div class="contanct-key-value">
                <div class="contact-key">Phone:</div>
                <div class="contact-value">8790858581</div>
            </div>
        </div>
        <!-- Isthara Contact Info -->
    </div>
</section>