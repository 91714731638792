import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CodiningService {

  constructor(
    private http: HttpClient
  ) { }

  getDiscountDetails(discountId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/discount/ist/v1/${discountId}`
    );
  }

  getAllFoodCourts() {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/foodCourts`
    );
  }

  getUserAddress(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/settings/delivery/address?userId=${userId}`
    )
  }

  addNewAddressOfuser(data: any) {
    return this.http.post(
      environment.baseUrl + `/foodcourt/user/ist/settings/delivery/address`,
      data
    );
  }

  getFoodocurtByFoodcourtId(foodcourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/foodCourt/ist/${foodcourtId}`
    );
  }

  getPromotionbannerlist(vertical:string){
    return this.http.get(
      environment.baseUrl + `/foodcourt/promotion/banner/ist/promotional/banners/vertical/list/all?vertical=${vertical}`
    )
  }


}
