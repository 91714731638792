<div class="section1">

  <div class="heading">
    <h3>"Isthara raises $10 million in fresh funding"</h3>
    <p>Hindustan Times,Nov 2022</p>
  </div>

  <div class="imaage">
    <img loading="lazy" src="../../../../assets/common/content-image.svg">
  </div>

  <div class="text">
    <p><a>Ever since the New York Times published its article on Google Project Aristotle, the world has become
        increasingly aware of the pivotal role that “psychological safety” plays in enabling teams to excel.
        In a nutshell, a year-long study of hundreds of Google teams showed that the teams that consistently
        performed
        best were those that had a high degree of psychological safety defined as “team members feeling safe to
        take
        risks and be vulnerable in front of each other”.</a>
    </p>
    <p> <a>
        As we look towards creating the next generation of post-pandemic workplaces, it is more important than
        ever to
        create a workplace experience that helps build psychological safety and enables teams to excel together.
      </a>
    </p>
    <p>
      Beyond the obvious common denominator of food, what both these examples have in common is the fact that
      people are coming together over a shared activity and that the “networking” is secondary. This takes out a
      lot of the social awkwardness and pressure often associated with networking or teambuilding.
    </p>
  </div>

  <div class="bold-content">
    "Remember to investigate what resonates best with the organizational culture you want to create and make sure
    that you bring your colleagues along on the journey through a well-designed and supported change management
    process.Choose the type of space that works best for you and your organizational culture".
  </div>

  <div class="text">
    <p>A walk-and-talk is one of the best ways of doing this. Having a conversation while going for a walk enables
      participants to look elsewhere instead of directly at each other and can help make the situation less
      confrontational. Circular routes or loops can help tremendously by taking the need to navigate out of the
      equation and allowing people to wander off in circles until they have gotten what they needed off their
      chests. </p>
  </div>

  <div class="border-container">
    <div class="border-line"></div>
  </div>

  <div class="relative">
    <p> 2 Comments</p>

  </div>
</div><!---->


<!--section 2-->
<div class="section2">
  <div class="comment-content">
    <div class="seection">
      <img loading="lazy" class="logo" src="../../../../assets/common/profile-image.svg">
    </div>

    <div class="center-text">
      <p>write something....</p>
    </div>

    <p class="add-comment">Add Comment</p>
  </div><br><br>


  <div class="next-comment">
    <div class="seection">
      <img loading="lazy" class="logo" src="../../../../assets/common/profile-image.svg">
    </div>
    <div class="side-text">
      <p>Billu Raj</p>
      <span class="subtext">29 Aug 2023,12.00pm</span><br><br>
      <span class="paragraph">Great article and advice!

        The chef’s corner in LEGO is such a great example of a space that encourages building relationships,
        breaking down barriers and feel comfortable with each other 🤩</span>
    </div>


    <p class="add-comment">Reply</p>

  </div><br><br>
  <div class="next-comment">
    <div class="seection">
      <img loading="lazy" class="logo" src="../../../../assets/common/profile-image.svg">
    </div>
    <div class="side-text">
      <p>yugi37</p>
      <span class="subtext">3 Jul 2023,9.00am</span><br><br>
      <span class="paragraph">Great article!

        The chef’s corner in LEGO is such a great example of a space</span>
    </div>


    <p class="add-comment">Reply</p>

  </div>

</div><!---->