import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';

const MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatBottomSheetModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatIconModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatSliderModule,
  MatSelectModule,
  MatGridListModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatCardModule,
  MatNativeDateModule,
  DragDropModule,
  MatTooltipModule,
  MatDialogModule,
  AutocompleteLibModule,
  ReactiveFormsModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...MODULES],
  exports: MODULES
})
export class AngularMaterialModule { }
