import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "./../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private http: HttpClient
  ) { }

  openDialog(componentName: any, height?: any, width?: any,maxWidth?:any, data?: any) {
    return this.dialog.open(componentName, { width: width, height: height,  maxWidth:maxWidth, data: data });
  }
  openBottomSheet(component: any, data?: any) {
    return this.bottomSheet.open(component, { data: data, });;
  }

  createpartnerShipRequest(data:any){
    return this.http.post(
      environment.baseUrl+`/foodcourt/co-dining/ist/partnership/request/create`,data
    )
  }


}
