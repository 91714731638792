import { Component } from '@angular/core';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss' , './mq-media-page.component.scss']
})
export class MediaPageComponent {

}
