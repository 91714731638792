<section class="guest-order-section">
  <div class="guest-order-heading">
    <div class="back-btn" (click)="goToHomePage()">
      <img loading="lazy" src="../../../../../assets/codining/images/common/back_arrow.svg" alt="">
    </div>
    <div class="header-text">Guest Ordering</div>
  </div>
  <ng-container *ngIf="!screenLoading; else ShowLoadingPage">
    <div [ngSwitch]="CURRENT_PAGE">
      <div class="menu-listing" *ngSwitchCase="'ITEM_LISTING'">
        <ng-container *ngFor="let mealDeatils of guestOrderMenu">
          <div class="menu-details"
            [style.backgroundColor]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#EEF9FF' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#EDFFFA' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '#F5ECFF' : '#FFF2F4')) )">
            <div class="menu-title">
              <div class="menu-name"
                [style.color]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#219DD8' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#097D5F' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '$theme-primary' : '#EB4B4B')) )">
                {{ mealDeatils.mealCategory[0] | uppercase }}</div>
              <div class="menu-type">
                <ng-container *ngIf="mealDeatils.itemType === 'VEG'">
                  <img loading="lazy" src="../../../../../assets/codining/images/common/veg-icon.svg" alt="veg">
                  <span>Veg</span>
                </ng-container>
                <ng-container *ngIf="mealDeatils.itemType === 'NON_VEG'">
                  <img loading="lazy" src="../../../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg">
                  <span>Non-Veg</span>
                </ng-container>
              </div>
            </div>
            <div class="menu-item-list">
              <ul>
                <li>{{ mealDeatils.name | titlecase }}</li>
              </ul>
            </div>
            <div class="menu-footer">
              <div class="footer__left-sec">
                <div class="selling-price">Rs. {{
                  (((mealDeatils.markUpPrice >= mealDeatils.employerMenu[0].paidByEmployer) && (mealDeatils.orderCount <
                    mealDeatils.employerMenu[0].threshold)) ? (mealDeatils.markUpPrice -
                    mealDeatils.employerMenu[0].paidByEmployer) : (mealDeatils.markUpPrice)) | number: '1.0-2' }}</div>
                    <div class="actual-price">Rs. {{ mealDeatils.markUpPrice | number: '1.0-2' }}</div>
                </div>
                <div class="footer__right-sec">
                  <div class="add-btn" *ngIf="!mealDeatils.quantity"
                    (click)="addItemGuestOrder(mealDeatils, 'INCREASE')">Add</div>
                  <div class="add-btn" *ngIf="mealDeatils.quantity">
                    <div class="decrease-btn" (click)="addItemGuestOrder(mealDeatils)">-</div>
                    <div class="item-quantity">{{ mealDeatils.quantity }}</div>
                    <div class="increase-btn" (click)="addItemGuestOrder(mealDeatils, 'INCREASE')">+</div>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
      </div>
      <div class="guest-listing" *ngSwitchCase="'GUEST_DETAILS'">
        <div class="guest-general-form">
          <div class="guest-form">
            <div class="guest-form-label">No. of Guests</div>
            <input type="number" placeholder="Enter no of guests" [(ngModel)]="guestCount"
              (ngModelChange)="showGuestList()">
          </div>
          <div class="guest-form">
            <div class="guest-form-label">Reason</div>
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="guestReasons">
                <mat-option (click)="showGuestList()" [value]="reason.value" *ngFor="let reason of guestReasonList">
                  {{ reason.name | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="guest-details-list" *ngIf="showGuestListing">
          <div class="guest-detail-heading">Enter Guest Details</div>
          <ng-container *ngFor="let guestDetail of guestDetailsList; let idx = index">
            <div class="guest-details">
              <ng-container *ngIf="idx != 0">
                <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt=""
                  (click)="removeGuestFromList(idx)">
              </ng-container>
              <div class="guest-form">
                <div class="guest-form-label">Guest Name</div>
                <input type="text" placeholder="Eg. Ramesh" [(ngModel)]="guestDetail.name">
              </div>
              <div class="guest-form">
                <div class="guest-form-label">Mobile Number</div>
                <input type="number" placeholder="Eg. 9907400401"  pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
                onKeyPress="if(this.value.length==10) return false;"[(ngModel)]="guestDetail.mobile">
              </div>
              <div class="guest-form">
                <div class="guest-form-label">Email</div>
                <input type="email" placeholder="Eg. abc@gmail.com" 
                pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$" [(ngModel)]="guestDetail.email">
              </div>
              <div class="form-divider"></div>
            </div>
          </ng-container>
          <div class="add-more-guest-btn" (click)="addGuest()">+Add other</div>
        </div>
      </div>
      <div class="preview-page" *ngSwitchCase="'PREVIEW_PAGE'">
        <div class="selected-menu">
          <div class="preview-header">
            <div class="preview-title">Food Details</div>
            <div class="edit-btn" (click)="changeView('ITEM_LISTING')">Edit</div>
          </div>
          <div class="selected-menu-list">
            <ng-container *ngFor="let mealDeatils of addedGuestMenu">
              <div class="menu-details"
                [style.backgroundColor]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#EEF9FF' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#EDFFFA' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '#F5ECFF' : '#FFF2F4')) )">
                <div class="menu-title">
                  <div class="menu-name"
                    [style.color]="(mealDeatils.mealCategory[0] === 'BREAKFAST' ? '#219DD8' : (mealDeatils.mealCategory[0] === 'LUNCH' ? '#097D5F' : (mealDeatils.mealCategory[0] === 'SNACKS' ? '$theme-primary' : '#EB4B4B')) )">
                    {{ mealDeatils.mealCategory[0] | uppercase }}</div>
                  <div class="menu-type">
                    <ng-container *ngIf="mealDeatils.itemType === 'VEG'">
                      <img loading="lazy" src="../../../../../assets/codining/images/common/veg-icon.svg" alt="veg">
                      <span>Veg</span>
                    </ng-container>
                    <ng-container *ngIf="mealDeatils.itemType === 'NON_VEG'">
                      <img loading="lazy" src="../../../../../assets/codining/images/common/non-veg-icon.svg"
                        alt="non veg">
                      <span>Non-Veg</span>
                    </ng-container>
                  </div>
                </div>
                <div class="menu-item-list">
                  <ul>
                    <li>{{ mealDeatils.name | titlecase }}</li>
                  </ul>
                </div>
                <div class="menu-footer">
                  <div class="footer__left-sec">
                    <div class="selling-price">Rs. {{
                      (((mealDeatils.markUpPrice >= mealDeatils.employerMenu[0].paidByEmployer) &&
                      (mealDeatils.orderCount < mealDeatils.employerMenu[0].threshold)) ? (mealDeatils.markUpPrice -
                        mealDeatils.employerMenu[0].paidByEmployer) : (mealDeatils.markUpPrice)) | number: '1.0-2'
                        }}</div>
                        <div class="actual-price">Rs. {{ mealDeatils.markUpPrice | number: '1.0-2' }}</div>
                    </div>
                    <div class="footer__right-sec">

                      <div class="add-btn" [style.opacity]="0.5" *ngIf="mealDeatils.quantity">
                        <div class="decrease-btn">-</div>
                        <div class="item-quantity">{{ mealDeatils.quantity }}</div>
                        <div class="increase-btn">+</div>
                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </div>
        <div class="added-guest-detail">
          <div class="preview-header">
            <div class="preview-title">Guest Details</div>
            <div class="edit-btn" (click)="changeView('GUEST_DETAILS')">Edit</div>
          </div>
          <ng-container *ngFor="let guestDetail of guestDetailsList">
            <div class="guest-form">
              <div class="guest-form-label">Guest Name</div>
              <input type="text" placeholder="Eg. Ramesh" [disabled]="true" [(ngModel)]="guestDetail.name">
            </div>
            <div class="guest-form">
              <div class="guest-form-label">Mobile Number</div>
              <input type="number" placeholder="Eg. 9907400401" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
              onKeyPress="if(this.value.length==10) return false;"
               [disabled]="true" [(ngModel)]="guestDetail.mobile">
            </div>
            <div class="guest-form">
              <div class="guest-form-label">Email</div>
              <input type="email" placeholder="Eg. abc@gmail.com" [disabled]="true" [(ngModel)]="guestDetail.email" 
              pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$">
            </div>
            <div class="form-divider"></div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="guest-order-footer">
      <button (click)="goToGuestDetailsPage()" *ngIf="CURRENT_PAGE === 'ITEM_LISTING'"
        [disabled]="addedGuestMenu.length === 0">Proceed</button>
      <button (click)="goToPreviewPage()" *ngIf="CURRENT_PAGE === 'GUEST_DETAILS'"
        [disabled]="guestDetailsList.length === 0">Continue</button>
      <button (click)="raiseGuestRequest()" *ngIf="CURRENT_PAGE === 'PREVIEW_PAGE'">Request Order</button>
    </div>
  </ng-container>
  <ng-template #ShowLoadingPage>
    <div class="loading-screen">
      <div class="loading-screen-container">
        <img loading="lazy" src="../../../assets/codining/images/common/burger_loader.gif" alt="">
      </div>
    </div>
  </ng-template>
</section>