import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared-services/shared.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService
  ) { }

  @Output() closeSideBar = new EventEmitter<any>();
  @Output() moduleSelected = new EventEmitter<string>();

  previousPage: string | null = null;
  userId: string | null = null;
  @Input() triggerUserId: string | null = null;


  ngOnInit(): void {
    // this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
  }

  routePath(url: string) {
    let path: string = 'isthara';

    if (url === "COLIVING")
      path = 'coliving';
    // else if (url === "DINE_WITH_US")
    //   path = 'home/dine-with-us';
    else if (url === "MBD")
      path = 'foodcourt';
    else if (url === "HOME")
      path = 'home';
    else if (url === "ACCOUNT")
      path = 'profile'
    else if (url === "LOGIN")
      path = 'auth'
    else if (url === "ABOUT_US")
      path = 'home/about-us'
    else if (url === "PARTNERSHIP")
      path = 'home/partner-with-us'
    else if (url === 'ENQUIRE')
      path = 'home/enquire';
    else if (url === 'NOTIFICATION')
      path = '/profile/notification'

    let queryPath: string | null = null;
    // const getQueryParam = () => {
    const currentUrl = this.router.url;
    if (currentUrl === '/coliving')
      queryPath = "COLIVING";
    else if (currentUrl === '/mbd')
      queryPath = "MBD";
    // else if (currentUrl === '/home/dine-with-us')
    //   queryPath = "DINE_WITH_US"
    // };
    // console.log(queryPath);


    this.closeSideBar.emit();
    this.moduleSelected.emit(path);
    if (queryPath && url === "ACCOUNT")
      this.router.navigate([`/${path}`], { queryParams: { pp: queryPath } });
    else
      this.router.navigate([`/${path}`]);
  }

}

