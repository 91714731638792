<!-- <section class="enquiry-form-section" *ngIf="!showImage">
  <div class="form-header">
    <div class="form-heading-text">Enquiry details</div>
    <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="close" (click)="closeDialog()">
  </div>
  <div class="form-section-divider"></div>

  <div class="form-section" *ngIf="!screenLoading; else showLoadingScreen">
    <div class="form-container">
      <div class="form-label">Your Name <sup>*</sup></div>
      <input type="text" placeholder="Enter your name" [(ngModel)]="enquiryDetails.name">
    </div>
    <div class="form-container">
      <div class="form-label">Mobile number <sup>*</sup></div>
      <input type="text" placeholder="Enter Mobile number" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
        onKeyPress="if(this.value.length==10) return false;" [(ngModel)]="enquiryDetails.mobileno">
    </div>
    <div class="form-container">
      <div class="form-label">Email <sup>*</sup></div>
      <input type="email" placeholder="Enter your email id"
        pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$"
        [(ngModel)]="enquiryDetails.emailId" >
    </div>
    <div class="form-container" *ngIf="!propertyId">
      <div class="form-label">City <sup>*</sup></div>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="enquiryDetails.cityname" (ngModelChange)="findAllLocalityByCity()">
          <ng-container *ngFor="let city of cityList">
            <mat-option [value]="city.city">{{ city.city | titlecase }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-container" style="margin-top: -25px;" *ngIf="!propertyId">
      <div class="form-label">Locality <sup>*</sup></div>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="enquiryDetails.localityname">
          <ng-container *ngFor="let locality of localityList">
            <mat-option [value]="locality.name">{{ locality.name | titlecase }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-container" [style.marginTop]="propertyId ? '0px' : '-25px'">
      <div class="form-label">Description</div>
      <textarea type="text" placeholder="Describe your enquiry"> </textarea>
    </div>

    <div class="footer-section">

      <button class="submit-btn" (click)="submitEnquiry()"  *ngIf="!paymentButtonLoader"
        [disabled]="!enquiryDetails.name || !enquiryDetails.name || !enquiryDetails.emailId || !enquiryDetails.localityname || !enquiryDetails.emailId.includes('@') ">Submit
      </button>
      <button class="submit-btn" *ngIf="paymentButtonLoader" >Loading...</button>
    </div>

  </div>

  <ng-template #showLoadingScreen>
    <div class="screen-loading-container">
      <img loading="lazy" src="../../../assets/common/istharaloader.gif" alt="">
    </div>
  </ng-template>
</section>
<section class="post-enquiry-section" *ngIf="showImage">
  <div class="cross-mark">
    <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="close" (click)="closeDialog()">
  </div>
  <div class="enquiry-show">
    <img src="../../../assets/common/enquiry-submitted.svg">
  </div>
  <div class="main-text">
    Enquiry Submitted!
  </div>
  <div class="sub-text">
    We will reach out to you shortly
  </div>
</section> -->


<section class="enquiry-form-section">
  <img loading="lazy" class="close-btn" src="../../../assets/codining/images/common/close.svg" alt="close"
    (click)="closeDialog()" *ngIf="screenWidth <= 480">
  <div class="section_left_part" *ngIf="screenWidth > 480">
    <div class="left_image_part">
      <img loading="lazy" src="../../../assets/common/enquiry-submitted.svg" alt="succes_image">
    </div>
  </div>
  <div class="section_right_part">
    <div class="form-heading-text">Enquiry Form</div>
    <div class="form-section">
      <div class="form-container">
        <div class="form-label">Your Name <sup>*</sup></div>
        <input type="text" placeholder="Enter your name" [(ngModel)]="enquiryDetails.name">
      </div>
      <div class="form-container">
        <div class="form-label">Mobile number <sup>*</sup></div>
        <input type="number" placeholder="Enter Mobile number" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
          onKeyPress="if(this.value.length==10) return false;" [(ngModel)]="enquiryDetails.mobileno">
      </div>
      <div class="form-container">
        <div class="form-label">Email</div>
        <input type="email" placeholder="Enter your email id"
          [(ngModel)]="enquiryDetails.emailId">
      </div>
      <!-- <div class="form-container" *ngIf="!propertyId">
        <div class="form-label">City <sup>*</sup></div>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="enquiryDetails.cityname" (ngModelChange)="findAllLocalityByCity()">
            <ng-container *ngFor="let city of cityList">
              <mat-option [value]="city.city">{{ city.city | titlecase }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="form-container" *ngIf="!propertyId">
        <div class="form-label">Locality <sup>*</sup></div>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="enquiryDetails.localityname">
            <!-- <ng-container > -->
              <mat-option value="">--Select--</mat-option>
              <mat-option [value]="locality.name" *ngFor="let locality of localityList">{{ locality.name | titlecase }}</mat-option>
            <!-- </ng-container> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-container" [style.marginTop]="propertyId ? '0px' : '0px'">
        <div class="form-label">Description</div>
        <textarea type="text" placeholder="Describe your enquiry"> </textarea>
      </div>

      <div class="footer-section">

        <button class="submit-btn" (click)="submitEnquiry()" *ngIf="!paymentButtonLoader"
          [disabled]="!enquiryDetails.name || !enquiryDetails.mobileno || enquiryDetails.localityname === '' ">Submit
        </button>
        <button class="submit-btn" *ngIf="paymentButtonLoader">Loading...</button>
      </div>
    </div>
  </div>
</section>