<!-- <section class="success-popup">
  <div class="popup-header">
    <div class="primary-text">Successfully Ordered</div>
    <div class="close-img" (click)="closeDialogBox()">
      <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
    </div>
  </div>
  <div class="popup-content">
    <img loading="lazy" src="../../../../../assets/codining/images/common/success-icon.svg" alt="success icon">
    <div class="secondary-text">Your Order is Successfully Placed</div>
  </div>
</section> -->
<section [ngSwitch]="viewPort">

  <section class="success_popup" *ngSwitchCase="'ORDER_FAILED'">
    <div class="popup_top_header">
      <div class="close-img" (click)="closeDialogBox()">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
      </div>
    </div>
    <div class="popup_body">
      <div class="body_image">
        <img src="./../../../../../assets/common/close-payment.jpg" alt="success_popup_image" loading="lazy">
      </div>
      <div class="success_heading">
        Order Failed!
      </div>
      <div class="success_description">
        Your Payment Failed please try again
      </div>
      <div class="sucess_button" (click)="closeDialogBox()">
        <button>Go To Cart</button>
      </div>
    </div>
  </section>
  <section class="success_popup" *ngSwitchCase="'ORDER_PLACED'">
    <div class="popup_top_header">
      <div class="close-img" (click)="closeDialogBox()">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
      </div>
    </div>
    <div class="popup_body">
      <div class="body_image">
        <img src="./../../../../../assets/common/success_popup_image.svg" alt="success_popup_image" loading="lazy">
      </div>
      <div class="success_heading">
        Order Placed!
      </div>
      <div class="success_description">
        Your order will be delivered shortly
      </div>
      <div class="sucess_button" (click)="goToOrderDetails()">
        <button>View Order Details</button>
      </div>
    </div>
  </section>
  <section class="success_popup" *ngSwitchCase="'POINTS_RECHARGED'">
    <div class="popup_top_header">
      <div class="close-img" (click)="closeDialogBox()">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
      </div>
    </div>
    <div class="popup_body">
      <div class="body_image">
        <img src="./../../../../../assets/common/points-recharge-successfully.svg" alt="success_popup_image"
          loading="lazy">
      </div>
      <div class="success_heading">
        Points Recharged
      </div>
      <div class="success_description">
        Check your wallet and use it for your orders
      </div>
      <div class="sucess_button" (click)="goToRechargeDetails()">
        <button>View Recharge Details</button>
      </div>
    </div>
  </section>
  <section class="success_popup" *ngSwitchCase="'POINTS_RECHARGED_FAILED'">
    <div class="popup_top_header">
      <div class="close-img" (click)="closeDialogBox()">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
      </div>
    </div>
    <div class="popup_body">
      <div class="body_image">
        <img src="./../../../../../assets/common/close-payment.jpg" alt="success_popup_image" loading="lazy">
      </div>
      <div class="success_heading">
        Points Recharged Failed
      </div>
      <div class="success_description">
        Payment Failed for Recharge Wallet
      </div>
      <div class="sucess_button" (click)="goToRechargeDetails()">
        <button>View Recharge Details</button>
      </div>
    </div>
  </section>

</section>


<div class="success-enquiry-main-section" *ngIf="matDialogData.type === 'EQUIRYFORM'">
  <div class="main-container">
    <div class="main-image">
      <img src="../../../../../assets/common/enquiry-submitted.svg" alt="equiry.svg" />
    </div>
    <div class="close-image">
      <img src="../../../../../assets/codining/images/common/close.svg" alt="close.svg" (click)="closeButton()" />
    </div>
    <div class="main-heading">
      Enquiry is submitted, will get to you soon.
    </div>
  </div>
</div>