<section id="navbar">
  <div class="close-img" (click)="closeSideBar.emit()">
    <img loading="lazy" src="../../../assets/codining/images/side-nav/close.svg" alt="Close">
  </div>

  <div class="navlinks__wrapper">
    <div class="nav-link" (click)="routePath('HOME')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/home.svg" alt="home">
      </div>
      <div class="nav-item">Home</div>
    </div>

    <div class="nav-link" (click)="routePath('MBD')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/food-courts.svg"
          alt="co-dining"></div>
      <div class="nav-item">Multi Brand Diner</div>
      <!-- <div class="nav-arrow"><mat-icon>keyboard_arrow_right</mat-icon></div> -->
    </div>

    <!-- <div class="nav-link" (click)="routePath('DINE_WITH_US')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/codining.svg"
          alt="co-dining"></div>
      <div class="nav-item">Co - Dining</div>
      <div class="nav-arrow"><mat-icon>keyboard_arrow_right</mat-icon></div>
    </div> -->

    <div class="nav-link" (click)="routePath('COLIVING')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/home.svg" alt="co-living">
      </div>
      <div class="nav-item active">Co - Living</div>
      <div class="nav-arrow"><mat-icon>keyboard_arrow_right</mat-icon></div>
    </div>

    <div class="nav-link" (click)="routePath('NOTIFICATION')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/notification-icon.svg" alt="notification"></div>
      <div class="nav-item active">Notifications</div>
      <div class="nav-arrow"><mat-icon>keyboard_arrow_right</mat-icon></div>
    </div>

    <div class="nav-link" (click)="routePath('PARTNERSHIP')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/partner-with-us.svg"
          alt="partner"></div>
      <div class="nav-item">Partnership With Us</div>
      <div class="nav-arrow"><mat-icon>keyboard_arrow_right</mat-icon></div>
    </div>

    <div class="nav-link" (click)="routePath('ABOUT_US')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/about-us.svg"
          alt="about-us"></div>
      <div class="nav-item">About Us</div>
    </div>

    <div class="nav-link" (click)="routePath('ENQUIRE')">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/enquiry.svg"
          alt="enquire"></div>
      <div class="nav-item">Enquire</div>
    </div>

    <div class="nav-link" (click)="routePath('ACCOUNT')" *ngIf="triggerUserId">
      <div class="nav-icon"><img loading="lazy" src="../../../assets/codining/images/side-nav/profile.svg"
          alt="account"></div>
      <div class="nav-item">Account</div>
    </div>

    <div class="nav-link" *ngIf="!triggerUserId" (click)="routePath('LOGIN')">
      <div class="login-btn">Login</div>
    </div>


  </div>

  <div class="circle-top-right"></div>
</section>