import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { CartService } from './cart.service';
import { SharedService } from '../../../shared-services/shared.service';
import { StorageServiceService } from './storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class CodiningLandingService {

  constructor(
    private cartService: CartService,
    private sharedLocalStorageService: SharedService,
    private storageService: StorageServiceService
  ) {
    const userId = this.sharedLocalStorageService.getFromLocalStorage('userId');
    if (userId) {
      this.getCartData(userId);
    }
  }

  getCartData(userId: string) {
    this.cartService.getCartData(userId).subscribe((response: any) => {
      if (response.data && response.data.itemsList.length > 0) {
        this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", response.data.foodCourtId);
        response.data?.itemsList.forEach((item: any) => {
          this.storageService.addItem(item, false);
        })
      }
    });
  }

}
