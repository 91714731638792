import { Component, OnInit, OnDestroy, HostListener, Optional } from '@angular/core';
import { CodiningService } from '../../shared-services/codining-service.service';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { StorageServiceService } from '../../shared-services/storage-service.service';
import { Item } from '../../entities/Item.model';
import { ItemCustomizationComponent } from '../../shared-components/item-customization/item-customization.component';

@Component({
  selector: 'app-cuisine-resturant',
  templateUrl: './cuisine-resturant.component.html',
  styleUrls: ['./cuisine-resturant.component.scss', './mq-cuisine-resturant.component.scss']
})
export class CuisineResturantComponent implements OnInit, OnDestroy {

  // constructor(
  //   private codiningService: CodiningService,
  //   private router: Router,
  //   private sharedLocalStorageService: SharedService,
  //   @Optional() public dialogRef: MatDialogRef<CuisineResturantComponent>
  // ) { }

  selectedCuisineDetails: any;
  // foodCourtName: string | null = null;
  // // vendorDiscountPositionFromLeft: number = 0;
  // interval: any;

  ngOnDestroy(): void {
    // clearInterval(this.interval);
  }

  // ngOnInit(): void {
  // this.selectedCuisineDetails = this.codiningService.getSelectedCuisineDetails();
  // this.foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
  // if (!this.selectedCuisineDetails) {
  //   this.router.navigate([`/codining/${this.foodCourtName}`]);
  // }
  //   this.getScreenSize();

  //   // this.interval = setInterval(() => {
  //   //   this.vendorDiscountPositionFromLeft += 100;
  //   // }, 3000);
  // }

  // goToHomePage() {
  //   if (this.screenWidth <= 480)
  //     this.router.navigate([`/codining/${this.foodCourtName}`]);
  //   else
  //     this.dialogRef.close();
  // }

  // goToResturantPage(vendorDetails: any) {
  //   this.sharedLocalStorageService.storeInLocalStorage("vendorId", vendorDetails.vendorId);
  //   const foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
  //   this.router.navigate([`/codining/${foodCourtName}/resturant`]);
  //   if (this.screenWidth > 480)
  //     this.dialogRef.close();
  // }

  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService,
    private sharedUtilsService: SharedUtilsService,
    private storageService: StorageServiceService,
    private codiningService: CodiningService
  ) {
    this.getScreenSize();
  };

  menuItemListLoader: boolean = true;
  searchText: string = "";

  foodcourtName: string | null = null;
  foodCourtId: string | null = null;

  userCartData: Item[] = [];

  ngOnInit() {

    this.foodcourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");

    this.selectedCuisineDetails = this.codiningService.getSelectedCuisineDetails();

    if (!this.selectedCuisineDetails) {
      this.router.navigate([`/mbd/${this.foodcourtName}`]);
    }
    this.getUserCart();
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  cuisineItemList: any[] = [];

  getItemsByCuisineName() {
    let sendingPayload = {
      "cuisineCategory": this.selectedCuisineDetails.cuisineName,
      "foodCourtId": this.foodCourtId,
      "itemType": null,
      "mealCategory": null,
      "name": this.searchText
    }
    this.menuItemListLoader = true;
    this.codiningService.getItemByCuisines(sendingPayload).subscribe((response: any) => {

      this.cuisineItemList = response.data.map((item: any) => {
        return { ...item, itemQuantity: 0, showItemQuantity: 0, orderedItemCustomizations: [] }
      });
      this.menuItemListLoader = false;
    }, (error: any) => {
      this.menuItemListLoader = false;
    }, () => {
      this.cuisineItemList.forEach((item: any) => {
        this.userCartData.forEach((cartItem: any) => {
          if (item.id === cartItem.id) {
            item.itemQuantity = cartItem.itemQuantity;
            item.showItemQuantity += cartItem.itemQuantity;
            item.orderedItemCustomizations = cartItem.orderedItemCustomizations;
          }
        })
      })
    })
  }

  handleFoodcourtSearch = this.sharedUtilsService.searchContent(() => this.getItemsByCuisineName(), 1000);


  getUserCart() {
    const request = this.storageService.getUserCart();
    request.onsuccess = (response: any) => {
      this.userCartData = request.result;
      this.getItemsByCuisineName();
    };
    request.onerror = (error: any) => { }
  }

  goToHomePage() {
    let foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "";
    this.router.navigate([`/mbd/${foodCourtName.replace(" ", "+")}`]);
  }

  selectedMealType: string | null = null;

  addItemToCart(itemDetails: any, operation: "INCREASE" | "DECREASE") {


    if (itemDetails.customizationRequired) {

      if (operation === "DECREASE") {
        this.router.navigate(['/mbd/cart']);
        return;
      }
      this.sharedUtilsService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails, type: operation }).afterDismissed().subscribe((response: any) => {
        if (response.status === "SUCCESS") {
          itemDetails.itemQuantity++;
          itemDetails.showItemQuantity++;
          if (response.createNewId)
            itemDetails.itemQuantity = 1;

          // itemDetails = {
          //   ...itemDetails,
          //   orderedItemCustomizations: response.selectedCustomizationList
          // };
          itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;

          if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId, true)) {
            itemDetails.itemQuantity--;
            itemDetails.showItemQuantity--;
          }
          // else {

          //   this.getUserCart();
          // }
          else {
            if (response.createNewId) {
              this.userCartData = [...this.userCartData, itemDetails];
            } else {
              if (this.userCartData.length > 0) {
                this.userCartData.forEach((item: any) => {
                  if (itemDetails.id === item.id)
                    item = itemDetails;
                });
              } else {
                this.userCartData = [itemDetails];
              }
              this.userCartData = [...this.userCartData];
            }
          }
        }
      });
    }
    else {

      if (operation === "INCREASE") itemDetails.itemQuantity++;
      else itemDetails.itemQuantity--;

      itemDetails.showItemQuantity = itemDetails.itemQuantity;


      this.userCartData = this.userCartData.filter(item => itemDetails.id !== item.id);

      if (itemDetails.itemQuantity > 0) {
        this.userCartData = [...this.userCartData, itemDetails];
        // this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, true).then((response: any) => {

        //   // if (!response) {
        //   //   itemDetails.itemQuantity--;
        //   //   itemDetails.showItemQuantity--;
        //   //   this.userCartData.pop();
        //   // }
        // });
      }

      this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, true);

      if (this.userCartData.length === 0) {
        this.storageService.clearAllItemsFromCart().then(() => {
          this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
        });
      }
    }
  }
}
