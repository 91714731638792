<section class="total-section" *ngIf="data.type === 'CODINING'">
  <div class="close-img">
    <img src="../../../../../assets/codining/images/common/close.svg" alt="Close" (click)="closeBottomSheet()">
  </div>
  <div class="notification-img">
    <img src="../../../../../assets/codining/images/notification/welcome-icon.svg" alt="Notification_Image"
      *ngIf="dataSource.notificationType === 'WELCOME'">
    <img src="../../../../../assets/codining/images/notification/pay-dues-icon.svg" alt="event_Image"
      *ngIf="dataSource.notificationType === 'EVENTS'">
    <img src="../../../../../assets/codining/images/notification/app-update-icon.svg" alt="Update_Image"
      *ngIf="dataSource.notificationType ==='APP_UPDATES'">
    <img src="../../../../../assets/codining/images/notification/order-placed.svg" alt="Update_Image"
      *ngIf="dataSource.notificationType ==='ORDERS'">
  </div>
  <div class="notification-details">
    <img [src]="dataSource.notificationImageUrl" alt="Notification Image" *ngIf="dataSource.notificationImageUrl">
    <h1 class="notification-title">{{ dataSource.title | titlecase }}</h1>
    <ng-container *ngIf="dataSource.notificationType === 'EVENTS'">
      <p class="event-date">{{ dataSource.eventDate | date:'mediumDate' }}</p>
      <h2 class="heading-event-timing">Timings</h2>
      <p class="event-timing">{{ dataSource.eventTime | date:'shortTime' }}</p>
      <p class="about-event">About Event</p>
    </ng-container>
    <p class="notification-text">{{ dataSource.message | titlecase }}</p>
    <button class="notification-action" *ngIf="dataSource.notificationType === 'APP_UPDATES'"
      (click)="goToStore()">Update</button>
    <!-- <button class="notification-action" *ngIf="dataSource.notificationType === 'EVENTS'"
        (click)="goToHomePage()">Explore</button> -->
    <button class="view-order" *ngIf="dataSource.notificationType === 'ORDERS'" (click)="goToOrderDetails()">View
      Order</button>
  </div>
</section>

<section class="total-section" *ngIf="data.type === 'COLIVING'">
  <div class="close-img">
    <img src="../../../../../assets/codining/images/common/close.svg" alt="Close" (click)="closeBottomSheet()">
  </div>
  <div class="notification-img">
    <img src="../../../../../assets/codining/images/side-nav/notification-icon.svg" alt="Notification_Image">
  </div>
  <div class="notification-details">
    <h1 class="notification-title">{{ dataSource.TitleMessage }}</h1>
    <p class="notification-text">{{ dataSource.BodyContent }}</p>

  </div>
</section>