// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
// import { SharedService } from '../../shared-services/shared.service';
// import { getMessaging, getToken } from '@firebase/messaging';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import { environment } from '../../../environments/environment';

// @Injectable({
//   providedIn: 'root',
// })
// export class NotificationService {
//   currentMessage = new BehaviorSubject(null);

//   constructor(
//     private angularFireMessaging: AngularFireMessaging,
//     private sharedService: SharedService
//   ) {
//     this.angularFireMessaging.messages.subscribe((_messaging: any) => {
//       _messaging.onMessage = _messaging.onMessage.bind(_messaging);
//       _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
//     });
//   }

//   // Get registration token. Initially this makes a network call, once retrieved
//   // subsequent calls to getToken will return from cache.

//   async requestPermission() {
//     // console.log(Notification.permission);
//     await this.angularFireMessaging.requestToken.subscribe(
//       async (response) => {
//         console.log('fbtoken: ', response);
//         // if (response === 'granted') {
//         const messaging = getMessaging();
//         //console.log('messagin', messaging);
//         await getToken(messaging, {
//           vapidKey: environment.firebase.vapidist,
//         })
//           .then((currentToken) => {
//             if (currentToken) {
//               // Send the token to your server and update the UI if necessary
//               // ...

//               console.log(currentToken);
//               this.sharedService.storeInLocalStorage('fb_token', currentToken);
//             } else {
//               // Show permission request UI
//               console.log(
//                 'No registration token available. Request permission to generate one.'
//               );
//               // ...
//             }
//           })
//           .catch((err) => {
//             this.sharedService.storeInLocalStorage('fb_token', null);
//             console.log('Unable to get permission to notify.', err);
//           });
//         // }
//       },
//       (error) => {
//         console.error('Permission denied: ', error);
//       }
//     );
//     // ));
//   }

//   receiveMessage() {
//     this.angularFireMessaging.messages.subscribe((payload: any) => {
//       // console.log('new message received. ', payload);
//       this.currentMessage.next(payload);
//     });

//     // if (!('Notification' in window)) {
//     //   alert('This browser does not support desktop notification');
//     // } else if (Notification.permission === 'granted') {
//     //   this.angularFireMessaging.requestPermission.subscribe(
//     //     (response) => {
//     //       //console.log(response);
//     //       // if(response === 'granted') {
//     //       const messaging = getMessaging();
//     //       //console.log('messagin', messaging);
//     //       getToken(messaging, {
//     //         vapidKey: environment.firebase.vapidist,
//     //       })
//     //         .then((currentToken) => {
//     //           if (currentToken) {
//     //             // Send the token to your server and update the UI if necessary
//     //             // ...

//     //             this.sharedService.storeInLocalStorage(
//     //               'fb_token',
//     //               currentToken
//     //             );
//     //           } else {
//     //             // Show permission request UI
//     //             //console.log(
//     //             //   'No registration token available. Request permission to generate one.'
//     //             // );
//     //             // ...
//     //           }
//     //         })
//     //         .catch((err) => {
//     //           console.log('An error occurred while retrieving token. ', err);
//     //           // if (this.sharedService.getFromLocalStorage('fb_token')) {
//     //           // } else {
//     //           //   this.sharedService.openSnackBar(
//     //           //     'Please allow notification to get notified about your order'
//     //           //   );
//     //           //   this.sharedService.storeInLocalStorage('fb_token', null);
//     //           // }

//     //           // ...
//     //         });
//     //     },
//     //     (err) => {
//     //       console.error('Unable to get permission to notify.', err);
//     //     }
//     //   );
//     // } else if (Notification.permission === 'denied') {
//     //   this.sharedService.openSnackBar('Notification Permission Denied.');
//     //   // Notification.requestPermission();
//     //   // this.requestPermission();
//     // } else if (Notification.permission === 'default') {
//     //   Notification.requestPermission();
//     //   this.requestPermission();
//     // }
//   }

//   // receiveMessage() {
//   //   this.angularFireMessaging.messages.subscribe((payload: any) => {
//   //     //console.log('new message received. ', payload);
//   //     this.currentMessage.next(payload);

//   //     this.showCustomNotification(payload);
//   //   });
//   // }

//   showCustomNotification(payload: any) {
//     let notify_data = payload['notification'];
//     let title = notify_data['title'];
//     let options = {
//       body: notify_data['body'],
//       icon: '../../../../../../assets/images/dummy/dummy_img.png',
//       badge: '',
//       image: '../../../../../../assets/images/dummy/dummy_img.png',
//       vibrate: [
//         500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110,
//         170, 40, 500,
//       ],
//     };
//     //console.log('new message recived', notify_data);

//     this.sharedService.openSnackBar(options.body);

//     // let notify: Notification = new Notification(title, options);
//     // //console.log(notify);

//     // notify.addEventListener('click', (event) => {
//     //   //console.log('events', event);
//     //   event.preventDefault();
//     //   window.location.href =
//     //     'https://isthara-food-court-dev.web.app/profile/user/order-summary';
//     // });
//   }
// }


import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { getMessaging, getToken } from 'firebase/messaging';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared-services/shared.service';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private sharedLocalStorageService: SharedService
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  async requestPermission() {
    await this.angularFireMessaging.requestPermission.subscribe(
      async (response) => {
        console.log(response);
        // if(response === 'granted') {
        const messaging = getMessaging();
        //console.log('messagin', messaging);
        await getToken(messaging, {
          vapidKey: environment.application_identity_Key,
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // ...

              console.log('FB Token', currentToken);
              this.sharedLocalStorageService.storeInLocalStorage("fb_token", currentToken);
            } else {
              // Show permission request UI
              //console.log(
              //   'No registration token available. Request permission to generate one.'
              // );
              // ...
            }
          })
          .catch((err) => {
            //console.log('An error occurred while retrieving token. ', err);
            if (this.sharedLocalStorageService.getFromLocalStorage("fb_token")) {
            } else {
              this.sharedLocalStorageService.openSnackBar(
                'Please allow notification to get notified about your order'
              );
              this.sharedLocalStorageService.storeInLocalStorage("fb_token", null);
            }

            // ...
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      //console.log('new message received. ', payload);
      this.currentMessage.next(payload);

      this.showCustomNotification(payload);
    });
  }

  showCustomNotification(payload: any) {
    let notify_data = "Order Details";
    let other_notify_data = "Your Order Status Changed";
    let title = "Order Notification";
    let options = {
      body: other_notify_data,
      icon: '../../../../../../assets/images/dummy/dummy_img.png',
      badge: '',
      image: '../../../../../../assets/images/dummy/dummy_img.png',
      vibrate: [
        500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110,
        170, 40, 500,
      ],
    };
    //console.log('new message recived', notify_data);

    // this.sharedLocalStorageService.openSnackBar(options.body);

    let notify: Notification = new Notification(title, options);
    // //console.log(notify);

    // notify.addEventListener('click', (event) => {
    //   //console.log('events', event);
    //   event.preventDefault();
    //   window.location.href =
    //     'https://isthara-food-court-dev.web.app/profile/user/order-summary';
    // });
  }
}

