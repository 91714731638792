import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  constructor(
    private dialogRef: MatDialogRef<ContactUsComponent>
  ) { }
  foodCourtList: any[] = [];
  ngOnInit(): void {
    this.getScreenSize();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
}
