import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
@Injectable({
    providedIn: "root"
})
export class CodiningSpacesService {

    constructor(
        private http: HttpClient
    ) {}

    getAllFoodCourts() {
        return this.http.get(
            environment.baseUrl + `/foodcourt/foodCourt/ist/foodCourts`
        );
    }
    
    getAllFoodCourtsByLocation() {

    }

  getSitesByLocation(data: any) {
    return this.http.put(
      environment.baseUrl + `/foodcourt/site/v2/ist/nearby/sites`,
      data
    );
  }
}