export enum PropertyFilter {
  "LOCALITY" = "LOCALITY",
  "PRICE" = "PRICE",
  "OCCUPANCY" = "OCCUPANCY",
  "GENDER" = "GENDER",
  "SORT_BY" = "SORT_BY",
}

export enum PropertyView {
  "HOME" = "HOME",
  "DETAILS" = "DETAILS"
}

export enum PropertySearchType {
  "PROPERTY_NAME" = "PROPERTY_NAME",
  "CITY" = "CITY",
  "LOCALITY" = "LOCALITY"
}


export interface SelectionType {
  name: string;
  value?: string;
  type?: PropertyFilter;
  show?: boolean;
  top?: string;
  left?: string;
}
export const occupancies: SelectionType[] = [
  { name: 'Single', value: 'SINGLE' },
  { name: 'Double', value: 'TWO' },
  { name: 'Triple', value: 'THREE' },
]

export const genders: SelectionType[] = [
  { name: 'Male', value: 'MALE' },
  { name: 'Female', value: 'FEMALE' },
  { name: 'Unisex', value: 'COLIVING' },
]

export interface CustomErrorResponse {
  status: number;
  message: string;
}

export interface Dialog {
  Component: any;
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string
  data: any;
  enterAnimationDuration: string;
  exitAnimationDuration: string;
}



// Codining Constants
export const ORDER_TYPE: any[] = [
  { name: "Dine In", value: "DINE_IN" },
  { name: "Self Pickup", value: "PICK_UP" },
  { name: "Delivery", value: "DELIVERY" },
]
