<section class="search-page-section">
  <div class="search-page-header" *ngIf="screenWidth <= 480">
    <img loading="lazy" src="../../../../../assets/codining/images/common/back_arrow.svg" alt="back"
      (click)="goToHomePage()">
    <div class="foodcourt-name">Search in {{ foodCourtName | titlecase }}</div>
  </div>
  <div class="search-page-header" *ngIf="screenWidth > 480">
    <div class="foodcourt-name">Search in {{ foodCourtName | titlecase }}</div>
    <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="back" (click)="goToHomePage()"
      class="close-image">
  </div>

  <div class="search-input-container" *ngIf="screenWidth <= 480">
    <input type="text" placeholder="Search for dishes and restaurants" [(ngModel)]="searchText"
      (ngModelChange)="handleFoodcourtSearch()">
    <img loading="lazy" src="../../../../../assets/codining/images/common/search-btn.svg" alt="back">
  </div>

  <div class="search-input-container" *ngIf="screenWidth > 480">
    <input type="text" placeholder="Search for dishes and restaurants " [(ngModel)]="searchText"
      (ngModelChange)="handleFoodcourtSearch()">
  </div>
  <div class="search-result-container">
    <div class="search-result-listing"
      *ngIf="(searchResultVendorList.length > 0 || searchResultItemList.length > 0); else showEmptyPage">
      <ng-container *ngFor="let vendor of searchResultVendorList">
        <div class="search-container" (click)="goToVendorPage(vendor)">
          <div class="search__left-section">
            <img loading="lazy" src="../../../../../assets/common/resturant-empty.svg" alt="resturant"
              *ngIf="!vendor.imgUrl">
            <img loading="lazy" [src]="vendor.imgUrl" alt="resturant" *ngIf="vendor.imgUrl">
          </div>
          <div class="search__right-section">
            <div class="search-result__primary-text">{{ vendor.brandName | titlecase }}</div>
            <div class="search-result__secondary-text">Resturant</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let item of searchResultItemList">
        <div class="item-container">
          <div class="item-container__left">
            <div class="item-meal-type">
              <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt="veg"
                *ngIf="item.itemType === 'VEG'">
              <img loading="lazy" src="../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg"
                *ngIf="item.itemType === 'NON_VEG'">
            </div>
            <div class="item-name">
              {{ (item.name.length > 30 ? item.name.substr(0, 30) + "..." : item.name ) | titlecase }}
            </div>
            <div class="item-iscustomizable" *ngIf="item.customizationRequired">Customisable</div>
            <div class="item-rating">
              <img loading="lazy" src="../../../assets/codining/images/resturant-page/rating-star-fill.svg" alt="">
              {{ item.rating | number: '0.0-1' }}
            </div>
            <div class="item-price">Rs : {{ item.markUpPrice | number:'1.0-2' }}</div>
          </div>
          <div class="item-container__right">
            <div class="item-image">
              <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg" *ngIf="!item.itemImageUrl"
                alt="item image">
              <img loading="lazy" [src]="item.itemImageUrl" *ngIf="item.itemImageUrl" alt="item image">
            </div>
            <ng-container
              *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OUT_OF_STOCK">
              <div class="add-to-cart-btn" (click)="addItemToCart(item, 'INCREASE')" *ngIf="!item.showItemQuantity">Add
              </div>
              <div class="add-to-cart-btn" *ngIf="item.showItemQuantity">
                <div class="decrease-item-count" (click)="addItemToCart(item, 'DECREASE')"> - </div>
                <div class="item-itemQuantity-count">{{ item.showItemQuantity }}</div>
                <div class="increase-item-count" (click)="addItemToCart(item, 'INCREASE')"> + </div>
              </div>
            </ng-container>
            <ng-template #OUT_OF_STOCK>
              <div class="out-of-stock">Out Of Stock</div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-template #showEmptyPage>
      <div class="empty-screen-container">
        <img loading="lazy" src="../../../../../assets/common/search-empty-img.svg" alt="empty" class="empty-image"
          *ngIf="screenWidth <= 480">
        <img loading="lazy" src="../../../../../assets/common/search-empty-image.svg" alt="empty" class="empty-image"
          *ngIf="screenWidth > 480">
        <div class="empty-screen__primary-text">Hungry for Something?</div>
        <div class="empty-screen__secondary-text">
          Looks like your taste buds are ready for a treat!
          Try Searching for your favourite dish or explore
          our curated selections below
        </div>
        <!-- <div class="empty-screen__button">Explore</div> -->
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="userCartData.length > 0">
    <app-cart-footer [cartDetails]="userCartData"></app-cart-footer>
  </ng-container>
</section>