import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  userId: string | null = null;
  dialogType: string = "LOGOUT";
  clickedBtn: string | null = null;

  ngOnInit(): void {
    this.dialogType = this.dialogData.type;
  }

  closeDialogBox(status: "LOGOUT" | "CLOSE" | "CANCEL" | "CHECKOUT") {
    if (status === "CLOSE")
      this.dialogRef.close();
    else if (status === "LOGOUT")
      this.dialogRef.close("LOGOUT");
    else if (status === "CANCEL")
      this.dialogRef.close("SUCCESS")
    else if (status === "CHECKOUT")
      this.dialogRef.close("CHECKOUT");
  }
}
