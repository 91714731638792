import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { CodiningService } from '../../shared-services/codining-service.service';
import { ItemCustomizationComponent } from '../../shared-components/item-customization/item-customization.component';
import { StorageServiceService } from '../../shared-services/storage-service.service';
import { Item } from '../../entities/Item.model';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss', './mq-search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService,
    private sharedUtilService: SharedUtilsService,
    private codiningService: CodiningService,
    private storageService: StorageServiceService
  ) { }

  foodCourtName: string | null = null;
  foodCourtId: string | null = null;
  searchText: string | null = null;

  ngOnInit(): void {
    this.foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName");
    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");
    this.getScreenSize();
    this.getUserCartData();
  }

  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  userCartData: any;
  getUserCartData() {
    const request = this.storageService.getUserCart();
    request.onsuccess = (response: any) => {
      this.userCartData = request.result;
    };
    request.onerror = (error: any) => { }
  }

  handleFoodcourtSearch = this.sharedUtilService.searchContent(() => this.searchResturant(), 1000);

  searchResultVendorList: any = [];
  searchResultItemList: any = [];

  searchScreenLoader: boolean = false;
  searchResturant() {

    if (!this.foodCourtId || !this.searchText)
      return;

    this.searchScreenLoader = true;
    this.codiningService.searchItemsRestaurants(this.foodCourtId, this.searchText).subscribe((response: any) => {
      this.searchScreenLoader = false;
      this.searchResultItemList = response.data.items.map((item: any) => {
        return {
          ...item,
          itemQuantity: 0,
          showItemQuantity: 0,
          orderedItemCustomizations: []
        }
      });
      this.searchResultItemList.forEach((item: any) => {
        this.userCartData.forEach((cartItem: any) => {
          if (item.id === cartItem.id) {
            item.itemQuantity = cartItem.itemQuantity;
            item.showItemQuantity += cartItem.itemQuantity;
            item.orderedItemCustomizations = cartItem.orderedItemCustomizations
          }
        })
      })
      this.searchResultVendorList = response.data.vendors;
    }, (error: any) => {
      this.searchScreenLoader = false;
    })
  }

  addItemToCart(itemDetails: any, operation: "INCREASE" | "DECREASE") {


    if (itemDetails.customizationRequired) {

      if (operation === "DECREASE") {
        this.router.navigate(['/mbd/cart']);
        return;
      }
      this.sharedUtilService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails, type: operation }).afterDismissed().subscribe((response: any) => {
        if (response.status === "SUCCESS") {
          itemDetails.itemQuantity++;
          itemDetails.showItemQuantity++;
          if (response.createNewId)
            itemDetails.itemQuantity = 1;

          // itemDetails = {
          //   ...itemDetails,
          //   orderedItemCustomizations: response.selectedCustomizationList
          // };
          itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;

          if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId, true)) {
            itemDetails.itemQuantity--;
            itemDetails.showItemQuantity--;
          }
          // else {

          //   this.getUserCart();
          // }
          else {
            if (response.createNewId) {
              this.userCartData = [...this.userCartData, itemDetails];
            } else {
              if (this.userCartData.length > 0) {
                this.userCartData.forEach((item: any) => {
                  if (itemDetails.id === item.id)
                    item = itemDetails;
                });
              } else {
                this.userCartData = [itemDetails];
              }
              this.userCartData = [...this.userCartData];
            }
          }
        }
      });
    }
    else {

      if (operation === "INCREASE") itemDetails.itemQuantity++;
      else itemDetails.itemQuantity--;

      itemDetails.showItemQuantity = itemDetails.itemQuantity;


      this.userCartData = this.userCartData.filter((item: any) => itemDetails.id !== item.id);

      if (itemDetails.itemQuantity > 0) {
        this.userCartData = [...this.userCartData, itemDetails];
        this.storageService.addObject({ ...itemDetails } as Item, itemDetails.itemQuantity === 1, false).then((response: any) => {

          // if (!response) {
          //   itemDetails.itemQuantity--;
          //   itemDetails.showItemQuantity--;
          //   this.userCartData.pop();
          // }
        });
      }

      if (this.userCartData.length === 0) {
        this.storageService.clearAllItemsFromCart().then(() => {
          this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
        });
      }
    }

    // if (itemDetails.customizationRequired) {

    //   this.sharedUtilsService.openBottomSheet(ItemCustomizationComponent, { data: itemDetails }).afterDismissed().subscribe((response: any) => {
    //     if (response.status === "SUCCESS") {
    //       itemDetails.itemQuantity++;
    //       // itemDetails = {
    //       //   ...itemDetails,
    //       //   orderedItemCustomizations: response.selectedCustomizationList
    //       // };
    //       itemDetails["orderedItemCustomizations"] = response.selectedCustomizationList;
    //       if (!this.storageService.addObject({ ...itemDetails } as Item, response.createNewId))
    //         itemDetails.itemQuantity--;
    //     }
    //   });
    // }
    // else {

    //   if (operation === "INCREASE") itemDetails.itemQuantity++;
    //   else itemDetails.itemQuantity--;


    //   this.userCartData = this.userCartData.filter(item => itemDetails.id !== item.id);

    //   if (itemDetails.itemQuantity > 0) {
    //     this.userCartData = [...this.userCartData, itemDetails];
    //     this.storageService.addObject({ ...itemDetails } as Item).then((response: any) => {
    //       if (!response) {
    //         itemDetails.itemQuantity--;
    //         this.userCartData.pop();
    //       }
    //     });
    //   }

    //   if (this.userCartData.length === 0)
    //     this.storageService.clearAllItemsFromCart().then(() => {
    //       this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
    //     });
    // }
  }

  goToVendorPage(vendorDetails: any) {
    this.sharedLocalStorageService.storeInLocalStorage("vendorId", vendorDetails.vendorId);
    this.router.navigate([`/mbd/${this.foodCourtName}/resturant`]);
  }

  goToHomePage() {
    this.router.navigate([`/mbd/${this.foodCourtName}`]);
  }
}
