import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileService } from '../../modules/user-profile/user-profile.service';
import { SharedService } from '../../shared-services/shared.service';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private userProfileService: UserProfileService
  ) {}

  helpContactList: any[] = [];
  foodCourtId: string | null = null;
  siteId: string | null = null;

  ngOnInit() {
    this.siteId = this.dialogData.orderDetails.siteId;
    this.foodCourtId = this.dialogData.orderDetails.foodCourtId;
    this.getHelpContactList();
  }

  closeDialogBox() {
    this.dialogRef.close();
  }
  getHelpContactList() {

    if (!this.siteId || !this.foodCourtId)
      return;

    this.userProfileService.getAllContactPerson(this.siteId, this.foodCourtId).subscribe((response: any)=> {
      this.helpContactList = response.data;
    },(error: any)=> {

    })
  }
}
