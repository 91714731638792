import { Injectable } from '@angular/core';
import { Item } from '../entities/Item.model';
import { v1 as uuidv1 } from 'uuid';
import { CartItem } from '../entities/CartItem.model';
import { SharedService } from '../../../shared-services/shared.service';
import { WarningPopupComponent } from '../shared-components/warning-popup/warning-popup.component';
import { SharedUtilsService } from './shared-utils.service';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {

  constructor(
    private sharedLocalStorageService: SharedService,
    private sharedUtilService: SharedUtilsService,
    private cartService: CartService
  ) {
    this.createDataBase();
  }

  databaseName: string = "USER_DETAILS";
  databaseVersion: number = 1;
  objectStorageName: string = "USER_CART";
  database!: IDBDatabase;

  async createDataBase() {
    const request: IDBOpenDBRequest = window.indexedDB.open(this.databaseName, 1);

    request.onerror = () => { };
    request.onsuccess = async (event) => {
      this.database = await (event.target as IDBOpenDBRequest).result;
    };
    request.onupgradeneeded = (event) => {

      this.database = (event.target as IDBOpenDBRequest).result;
      const objectStore = this.database.createObjectStore(this.objectStorageName, { keyPath: "appItemId" });
    };
  }

  getUserCart() {
    const request = this.database.transaction(this.objectStorageName).objectStore(this.objectStorageName).getAll();
    return request;
  }

  convertItemToCartItem() {

  }

  async addObject(data: Item, createNewId: boolean = false, isCustomization: boolean = false) {

    if (!this.database) {
      // throw new Error("IndexDB is not initialized");
      return new Promise((resolve, _reject) => resolve(false));
    }

    let orderingFoodCourtId = this.sharedLocalStorageService.getFromLocalStorage("orderingFCId");
    let currentFoodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId");

    if (orderingFoodCourtId !== currentFoodCourtId && orderingFoodCourtId) {
      this.sharedUtilService.openDialogBox(WarningPopupComponent).afterClosed().subscribe((response: any) => {

        if (response.status === "SUCCESS") {
          this.cartService.fetchAllCartItem.next();
          // return this.addItem(data, createNewId);
        }
        else
          return new Promise((resolve, _reject) => resolve(false));
      });
    }

    else {
      this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", currentFoodCourtId);
      return this.addItem(data, createNewId, isCustomization);
    }
  }

  addItem(data: Item, createNewId: boolean, isCustomization: boolean = false) {

    if (!data.id)
      data.id = data.itemId;


    if (createNewId) {
      if (isCustomization)
        data.appItemId = uuidv1();
      else
        data.appItemId = data.id;

      data.itemQuantity = 1;

      const transaction = this.database.transaction(this.objectStorageName, "readwrite");
      const store = transaction.objectStore(this.objectStorageName);

      return (data.itemQuantity ? store.put(data) : store.delete(data.appItemId));

    } else {

      const request = this.getUserCart();

      request.onsuccess = () => {

        const userCartData = request.result;

        userCartData.forEach((item: any) => {
          if (item.id === data.id) {

            data.appItemId = item.appItemId;
          }
        })

        const transaction = this.database.transaction(this.objectStorageName, "readwrite");
        const store = transaction.objectStore(this.objectStorageName);

        return (data.itemQuantity ? store.put(data) : store.delete(data.appItemId));

      }
      request.onerror = () => {

      }
    }
  }

  clearAllItemsFromCart() {
    return new Promise(async (resolve, reject) => {
      if (this.database != undefined) {
        const request = await this.database
          .transaction(this.objectStorageName, 'readwrite')
          .objectStore(this.objectStorageName)
          .clear();

        request.onsuccess = await function (event: any) {

          if (event.target.result) {
            resolve(event.result);
          } else {
            resolve('SUCCESS');
          }
        };
        request.onerror = await function (_event) {
          reject("No data");
        }
      }
    });
  }



  // private promisifyRequest(request: IDBRequest) {
  //   return new Promise<any>((resolve, reject) => {
  //     request.onsuccess = (event: Event) => {
  //       resolve((event.target as IDBRequest).result);
  //     }
  //     request.onerror = (event: Event) => {
  //       reject((event.target as IDBRequest).error);
  //     }
  //   });
  // }
}
