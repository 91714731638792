import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(
    private http: HttpClient
  ) { }

  getAllCityList() {
    return this.http.get(environment.baseUrl + `/property/master/ist/all`)
  }

  getAllLocalityByCity(cityList: any) {
    return this.http.put(environment.baseUrl + `/property/master/ist/cities/locality`, cityList);
  }

  createEnquiry(data: any) {
    return this.http.post(environment.baseUrl + `/enquiry/enquiry/tenant/ist/create`, data);
  }

  getPropertyByPropertyId(propertyId: string) {
    return this.http.get(
      environment.baseUrl + `/property/property/ist/${propertyId}`
    )
  }
}
