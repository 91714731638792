<ng-container
  *ngIf="!currentUrl.includes('dine-with-us?source=ISTHARA_APP') && !currentUrl.includes('about-us?source=ISTHARA_APP') ">
  <section class="mobile-view-header" *ngIf="screenWidth <= 480">
    <div class="header-section">
      <div class="header__left-section">
        <img loading="lazy" src="../../../assets/common/isthara-foodcourt-logo.svg" alt="back" (click)="goToHomePage()"
          *ngIf="currentUrl.includes('mbd') && selectedFoodCourt.name" />
        <img loading="lazy" src="../../assets/codining/images/home-page/isthara-logo.svg" alt="back"
          (click)="goToHomePage()" *ngIf="!currentUrl.includes('mbd') && selectedFoodCourt.name">
        <div class="foodcourt-details" (click)="openCodiningSpaceBottomSheet()"
          *ngIf="!currentUrl.includes('coliving') && !currentUrl.includes('home') && !currentUrl.includes('isthara') && !currentUrl.includes('dine-with-us') && !currentUrl.includes('partner-with-us') && !currentUrl.includes('about-us') && currentUrl !== '/' && selectedFoodCourt.name">
          <div class="foodcourt-name">
            <span>{{ (selectedFoodCourt.name.length > 20 ? selectedFoodCourt.name.substr(0, 20) + "..." :
              selectedFoodCourt.name ) | titlecase }}</span>
            <img loading="lazy" src="../../assets/codining/images/common/back_arrow.svg" alt="back">
          </div>
          <div class="foodcourt-location" *ngIf="selectedFoodCourt.location">{{ (selectedFoodCourt.location.length > 30
            ?
            selectedFoodCourt.location.substr(0, 30) + '...' : selectedFoodCourt.location) | titlecase }}</div>
        </div>
      </div>
      <div class="header__right-section" (click)="toggleSideNav()">
        <img loading="lazy" src="../../assets/codining/images/common/expansion-btn.svg" alt="back">
      </div>
    </div>
  </section>
  <section class="desktop-view-header" *ngIf="screenWidth > 480">
    <div class="header-section">
      <div class="left-section">
        <img loading="lazy" src="../../../assets/codining/images/home-page/isthara-logo.svg" alt="back"
          (click)="goToHomePage()" *ngIf="!currentUrl.includes('mbd') && selectedFoodCourt.name">
        <img loading="lazy" src="../../../assets/common/isthara-foodcourt-logo.svg" alt="back" (click)="goToHomePage()"
          *ngIf="currentUrl.includes('mbd') && selectedFoodCourt.name" />
        <div class="foodcourt-details" (click)="openCodiningSpaceBottomSheet()"
          *ngIf="currentUrl.includes('mbd') && selectedFoodCourt.name">
          <div class="foodcourt-name">
            <span>{{ selectedFoodCourt.name }}</span>
            <img loading="lazy" src="../../assets/codining/images/common/back_arrow.svg" alt="back">
          </div>
          <div class="foodcourt-location" *ngIf="selectedFoodCourt.location">{{ selectedFoodCourt.location | titlecase
            }}
          </div>
        </div>
      </div>
      <div class="right-section">
        <ng-container *ngFor="let navbar of topNavbarListing">
          <div class="nav-link" (click)="routePath(navbar)"
            [ngClass]="{'nav-link_active': (currentUrl.split('/')[currentUrl.split('/').length -1] == (navbar.checkingParam) && navbar.url !== '') || (navbar.url === '' && currentUrl === '/') || (selectedFoodCourt.name &&  currentUrl.includes('mbd') && navbar.checkingParam == 'foodcourt' )}">
            {{ navbar.name }} </div>
        </ng-container>
        <div class="nav-search-icon" *ngIf="currentUrl.includes('mbd')">
          <img src="../../../assets/codining/images/common/header-search-icon.svg" (click)="openSearchPage()"
            alt="search-icon" />
        </div>
        <div class="nav-link enquire" (click)="openEnquiryForm()">Enquiry</div>
        <div class="nav-link login-btn" (click)="goToLoginPage()" *ngIf="!userId">Login</div>
        <div class="user-details" *ngIf="userId" (click)="goToProfilePage()">
          <!-- <div class="profile-img">

          </div> -->
          <div class="profile-img">
            <div *ngIf="!profilePicture; else profPic">
              <img loading="lazy" src="../../../assets/website/images/common/avatar-male.png" alt="avatar">
            </div>
            <ng-template #profPic>
              <img loading="lazy" [src]="profilePicture" />
            </ng-template>
          </div>
          <div class="user-name">{{ userName | titlecase }}</div>
        </div>
      </div>
    </div>
  </section>
</ng-container>