import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';

// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './shared-module/angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { SlickModule } from './shared-module/slick.module';
import { HeaderSecComponent } from './shared-components/header-sec/header-sec.component';
import { SideNavbarComponent } from './shared-components/side-navbar/side-navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CodiningRoutingModule } from './modules/codining/codining-routing.module';
import { FooterComponent } from './shared-components/footer/footer.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AsyncPipe, DatePipe } from '@angular/common';
import { PrivacyPolicyComponent } from './shared-components/static/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './shared-components/static/terms-and-condition/terms-and-condition.component';
import { HelpDialogComponent } from './shared-components/help-dialog/help-dialog.component';
import { EnquiryFormComponent } from './shared-components/enquiry-form/enquiry-form.component';
import { FormsModule } from '@angular/forms';
import { MediaPageComponent } from './shared-components/static/media-page/media-page.component';
import { WarningDialogComponent } from './shared-components/warning-dialog/warning-dialog.component';
import { TrimTextPipe } from './modules/codining/utils/pipes/trim-text.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ContactUsComponent } from './shared-components/contact-us/contact-us.component';
import { EnquiryDialogComponent } from './shared-components/enquiry-dialog/enquiry-dialog.component';
import { QrCodeComponent } from './modules/qrCode/qr-code/qr-code.component';

// import { FaqComponent } from './shared-components/faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderSecComponent,
    SideNavbarComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    HelpDialogComponent,
    EnquiryFormComponent,
    MediaPageComponent,
    WarningDialogComponent,
    TrimTextPipe,
    ContactUsComponent,
    EnquiryDialogComponent,
    QrCodeComponent,
    // FaqComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatSidenavModule,
    CodiningRoutingModule,
    AngularMaterialModule,
    MatIconModule,
    SlickModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, //!isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    AsyncPipe,
    DatePipe,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
