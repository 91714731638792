import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-footer',
  templateUrl: './cart-footer.component.html',
  styleUrls: ['./cart-footer.component.scss']
})
export class CartFooterComponent implements OnChanges {

  constructor(
    private router: Router
  ) { }

  @Input() cartDetails: any;
  cartPreview = {
    itemQuantity: 0,
    totalPrice: 0,
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cartPreview = {
      itemQuantity: 0,
      totalPrice: 0,
    }

    this.cartDetails.forEach((itemDetails: any) => {
      let itemPrice = 0;
      itemPrice += (itemDetails.markUpPrice * itemDetails.itemQuantity);
      if (itemDetails.orderedItemCustomizations && itemDetails.orderedItemCustomizations.length > 0) {
        itemDetails.orderedItemCustomizations.forEach((customizedItem: any) => {
          itemPrice += (customizedItem.markupPrice);
        })
      }
      this.cartPreview.totalPrice += itemPrice;
    })
    this.cartPreview.itemQuantity = this.cartDetails.length;
  }

  goToCartPage() {
    this.router.navigate(['/mbd/cart']);
  }

}
