<section class="partnership-form">
  <div class="top_section">
    <div class="form-header">
      <div class="form-heading">Partner with us</div>
      <div class="text">Enter your details</div>
    </div>
    <div class="close-btn" (click)="closeDialogBox()">
      <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="">
    </div>
  </div>
  <div class="between_line"></div>
  <div class="bottom_section">
    <div class="dobule_changing_buttons">
      <ng-container *ngFor="let item of topButtons">
        <div [ngClass]="item.status ? 'single_changing_button_active' : 'single_changing_button'"
          (click)="changeButtonType(item)">
          {{item.name | titlecase}}
        </div>
      </ng-container>
    </div>

    <div class="partner_type_selection" *ngIf="propertyButtons === 'CO_DINING'">
      <mat-form-field appearance="outline" class="form-field">
        <mat-select [(ngModel)]="selectedCodiningPartnerType"
          (selectionChange)="selectingPartnershipType($event.value,'CO_DINING')">
          <mat-option *ngFor="let item of coDiningPartnerType" [value]="item.value">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="partner_type_selection" *ngIf="propertyButtons === 'CO_LIVING'">
      <mat-form-field appearance="outline" class="form-field">
        <mat-select [(ngModel)]="selectedColivingPartnerType"
          (selectionChange)="selectingPartnershipType($event.value,'CO_LIVING')">
          <mat-option *ngFor="let item of coLivingPartnerType" [value]="item.value">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->


    <!-- co living partner type select -->

    <!-- partner type selection ends-->


    <!-- partnership type codinging selection start -->
    <div *ngIf="propertyButtons === 'CO_DINING'">
      <div class="form-section__primary-text">Location Details <sup>*</sup></div>
      <!-- partnership type codinging Property Owners -->
      <div class="partner_type_selection" *ngIf="selectedCodiningPartnerType === 'BUILDERS_DEVELOPERS'">

        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPartnershipType" placeholder="Rental type"
            (selectionChange)="selectingPropetyType($event.value,selectedCodiningPartnerType,propertyButtons)">
            <mat-option *ngFor="let item of coDiningBuilderPartnershipType" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- partnership type codinging college/corporate -->
      <div class="partner_type_selection_1" *ngIf="selectedCodiningPartnerType === 'COLLEGE_CORPORATE'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPartnershipType" placeholder="Property type*"
            (selectionChange)="selectingPropetyType($event.value,selectedCodiningPartnerType,propertyButtons)">
            <mat-option *ngFor="let item of coDiningCollegePartnershipType" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- partnershipe type codinging food brand -->

      <div class="partner_type_selection" *ngIf="selectedCodiningPartnerType === 'FOOD_VENDORS'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="foodBusinessType"
            (selectionChange)="selectingPropetyType($event.value,selectedCodiningPartnerType,propertyButtons)">
            <mat-option *ngFor="let item of coDiningFoodbrandPartnershipType" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="three_fields">
          <div class="single_field" [style.width.%]="65">
            <div class="form-section__primary-text">Cusine Type</div>
            <mat-form-field appearance="outline" class="form-field">
              <input type="text" matInput placeholder="Multiple Cusine with comma separated" [(ngModel)]="cusineType">
            </mat-form-field>
          </div>
          <div class="single_field" [style.width.%]="30">
            <div class="form-section__primary-text">Current Turn over</div>
            <mat-form-field appearance="outline" class="form-field">
              <input type="text" matInput placeholder="Enter Turn over" [(ngModel)]="turnOver">
            </mat-form-field>
          </div>
          <!-- <div class="single_field">
            <div class="form-section__primary-text">Area</div>
            <mat-form-field appearance="outline" class="form-field">
              <input type="text" matInput placeholder="Enter area in sq.ft" [(ngModel)]="area">
            </mat-form-field>
          </div> -->
        </div>
      </div>


      <!-- partnership type codining and builders or developers -->
      <div class="partner_type_selection"
        *ngIf="selectedPartnershipType === 'Lease real estate for food court' && selectedCodiningPartnerType != 'FOOD_VENDORS' && selectedCodiningPartnerType != 'COLLEGE_CORPORATE'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPropertyType">
            <mat-option *ngFor="let item of coDiningBuildersorDeveloperandLeaseRealEstate" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- partnership type coding college or corporate and operate food court -->
      <div class="partner_type_selection"
        *ngIf="selectedPartnershipType === 'Operate food court' && selectedCodiningPartnerType != 'BUILDERS_DEVELOPERS' && selectedCodiningPartnerType != 'FOOD_VENDORS'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPropertyType">
            <mat-option *ngFor="let item of coDiningCollegeOrCoporateOpenFoodcourt" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- partnership type codining selection ends -->

    <!-- partnership type coliving selection start -->

    <div class="partner_type_selection" *ngIf="propertyButtons === 'CO_LIVING'">
      <div class="form-section__primary-text">Property Details <sup>*</sup></div>
      <!-- partnership type coliving Property Owners -->
      <div class="partner_type_selection" *ngIf="selectedColivingPartnerType === 'BUILDERS_DEVELOPERS'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPartnershipType"
            (selectionChange)="selectingPropetyType($event.value,selectedCodiningPartnerType,propertyButtons)">
            <mat-option *ngFor="let item of coLivingBuilderPartnershipType" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- partnership type coliving college/corporate -->
      <div class="partner_type_selection_1" *ngIf="selectedColivingPartnerType === 'COLLEGE_CORPORATE'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPartnershipType">
            <mat-option *ngFor="let item of coLviingCollegePartnershipType" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <!-- partnership type coliving and builders or developers -->
      <div class="partner_type_selection"
        *ngIf="selectedPartnershipType === 'Lease real estate for co-living' && selectedColivingPartnerType != 'COLLEGE_CORPORATE'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPropertyType">
            <mat-option *ngFor="let item of coLivingBuildersorDeveloperandLeaseRealEstate" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- partnership type coliving college or corporate and operate food court -->
      <div class="partner_type_selection"
        *ngIf="selectedPartnershipType === 'Operate co-living' && selectedColivingPartnerType != 'BUILDERS_DEVELOPERS'">
        <mat-form-field appearance="outline" class="form-field">
          <mat-select [(ngModel)]="selectedPropertyType">
            <mat-option *ngFor="let item of coLivingCollegeOrCoporateOpenFoodcourt" [value]="item.value">
              {{item.name | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <!-- partnership type coliving selection ends -->

    <div class="form-section">
      <div class="form-section__primary-text">Personal Details <sup>*</sup></div>
      <mat-form-field appearance="outline">
        <input type="text" matInput placeholder="Your name" [(ngModel)]="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input type="number" matInput placeholder="Mobile number" pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
          onKeyPress="if(this.value.length==10) return false;" [(ngModel)]="mobileNumber">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input type="email" matInput placeholder="Email id"
          pattern="^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$" [(ngModel)]="emailId">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <textarea type="text" matInput placeholder="Describe" [(ngModel)]="description"></textarea>
      </mat-form-field>
      <!-- <mat-radio-group [(ngModel)]="selectedRadioButton">
        <mat-radio-button value="AGENT">Agent</mat-radio-button>
        <mat-radio-button value="OWNER">Owner</mat-radio-button>
      </mat-radio-group> -->
    </div>
    <!-- <div class="form-section">
      <div class="form-section__primary-text">Property Details</div>
      <mat-form-field appearance="outline">
        <mat-select placeholder="Property type">
          <ng-container *ngFor="let propertyType of propertyTypeListing">
            <mat-option>{{ propertyType.name | titlecase }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
  <div class="submit_btn">
    <button (click)="submit()" *ngIf="!submitLoader"
      [disabled]="name === '' || emailId === '' || mobileNumber === ''">Submit</button>
    <button class="loading-btn" *ngIf="submitLoader" [disabled]="true">Loading...</button>
  </div>
</section>