<section class="cart-page-section" [style.backgroundColor]="cartPageLoader ? 'white' : '' ">
  <div class="mobile-screen" *ngIf="screenWidth <= 480">
    <div class="cart-header">
      <div class="back-btn" (click)="goToHomePage()">
        <img loading="lazy" src="../../assets/codining/images/common/back_arrow.svg" alt="back">
      </div>
      <div class="header-text">Order Summary</div>
    </div>
    <ng-container *ngIf="!cartPageLoader; else showCartPargeLoading">
      <div class="cart-details">
        <div class="cart-details__item-list">
          <ng-container *ngFor="let item of userCartDetails?.itemsList">
            <div class="cart-details__item">
              <div class="item__top-section">
                <div class="item__top__left-section">
                  <div class="item-name">
                    <div class="type">
                      <img loading="lazy" src="../../../../../assets/codining/images/common/veg-icon.svg" alt="veg"
                        *ngIf="item.veg">
                      <img loading="lazy" src="../../../../../assets/codining/images/common/non-veg-icon.svg"
                        alt="non-veg" *ngIf="!item.veg">
                    </div>
                    <div class="name">{{ (item.itemName.length > 35 ? item.itemName.substr(0, 35) + '...' :
                      item.itemName )  }}</div>
                  </div>
                  <div class="vendor-name">{{ item.vendorName }}</div>
                  <div class="item-customization" *ngIf="item.isCustomizationRequired">Customized</div>
                </div>
                <div class="item__top__right-section">
                  <div class="item-itemQuantity"
                    [style.opacity]="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE' ? 1 : 0.5">
                    <div class="minus-icon"
                      (click)="(item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE') && addItemToCart(item, 'DECREASE')">
                      -</div>
                    <div class="qunatity">{{ item.itemQuantity }}</div>
                    <div class="plus-icon"
                      (click)="(item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE') && addItemToCart(item, 'INCREASE')">
                      +</div>
                  </div>
                  <div class="item-price">Rs: {{ item.markUpTotalPrice }}</div>
                  <div class="out-of-stock item-itemQuantity"
                    *ngIf="item.itemStatus !== 'IN_STOCK' && item.itemStatus !== 'VENDOR_AVAILABLE'"
                    (click)="removeItem(item)">Remove Item</div>
                </div>
              </div>
              <div class="item__bottom-section">
                <input type="text" placeholder="Add Item Cooking Instruction(Optional)"
                  [(ngModel)]="item.cookingInstruction">
              </div>
            </div>
          </ng-container>
        </div>
        <div class="cart-details__order-type">
          <mat-radio-group [(ngModel)]="selectedOrderType">
            <ng-container *ngFor="let orderType of ORDER_TYPE">
              <mat-radio-button [style.width.%]="ORDER_TYPE.length > 2 ? '32' : '45'" (click)="changeOrderType($event)"
                [value]="orderType.value">{{ orderType.name | titlecase }}</mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>
        <div class="cart-details__coupon-section" *ngIf="billDetails?.couponCode">
          <div class="coupon__left">

            <div *ngIf="billDetails?.couponId">
              <img loading="lazy" src="../../../../../assets/codining/images/common/discount-icon.svg"
                alt="discount icon">
              <div class="coupon-code">{{ billDetails?.couponCode }}</div>
            </div>
          </div>
          <div class="coupon__right">
            <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close icon"
              *ngIf="billDetails?.couponCode" (click)="removeDiscountFromCart()">
          </div>
        </div>
        <div class="cart-details__coupon-section" (click)="goToCouponListingPage()" *ngIf="!billDetails?.couponId">
          <div class="coupon__left">
            <span>Apply Coupon</span>
          </div>
          <div class="coupon__right">
            <img loading="lazy" src="../../../../../assets/codining/images/common/discount-icon.svg"
              alt="discount icon">
          </div>
        </div>
        <div class="cart-details__isthara-points">
          <div class="isthara-points__primary-text">Wallet</div>
          <div class="isthara-points">
            <div class="isthara-points-text">{{ userPoints }} Reward Points (Rs. {{ userPoints }})</div>
            <div class="isthara-points-toggle"><mat-slide-toggle
                (change)="istharaPointsToggled($event)"></mat-slide-toggle></div>
          </div>
        </div>
        <div class="delivery-address-section" *ngIf="selectedOrderType === 'DELIVERY'">

          <ng-container *ngIf="!deliveryAddress.address; else showUserAddress">
            <div class="address-left-section">Delivery Address</div>
          </ng-container>
          <ng-template #showUserAddress>
            <div class="address-left-section">
              <div class="address-left-top-sec">{{ userCartDetails.address?.address | titlecase}}</div>
              <div class="address-left-bottom-sec">{{ userCartDetails.address?.addressTitle | titlecase }}</div>
            </div>
          </ng-template>
          <div class="address-right-section" (click)="goToDeliveryAddressPage()" *ngIf="deliveryAddress.address">Change
          </div>
          <div class="address-right-section" (click)="goToDeliveryAddressPage()" *ngIf="!deliveryAddress.address">Select
          </div>
        </div>
        <div class="cart-details__bills-summary">
          <div class="payment-summary__primary-text">Payment Summary</div>
          <div class="payment-summary__key-value">
            <div class="payment-summary__key">Item Total</div>
            <div class="payment-summary__value">Rs. {{ billDetails.breakup["Item Total"] |
              number:'1.0-2' }}</div>
          </div>
          <div class="payment-summary__key-value">
            <div class="payment-summary__key">Taxes & Charges</div>
            <div class="payment-summary__value">Rs. {{ billDetails.breakup["Taxes & charge"] |
              number:'1.0-2' }}</div>
          </div>
          <div class="payment-summary__key-value" *ngIf="billDetails.breakup['Packing Charges']">
            <div class="payment-summary__key">Packaging Charges</div>
            <div class="payment-summary__value">Rs. {{ billDetails.breakup["Packing Charges"] |
              number:'1.0-2' }}</div>
          </div>
          <ng-container *ngIf="billDetails.breakup['Delivery Charges']">
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">Delivery Charges</div>
              <div class="payment-summary__value">Rs. {{ billDetails.breakup["Delivery Charges"] |
                number:'1.0-2' }}</div>
            </div>
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">GST on Delivery</div>
              <div class="payment-summary__value">Rs. {{ billDetails.breakup["Delivery Charges"] * 0.18
                |
                number:'1.0-2' }}</div>
            </div>
          </ng-container>
          <div class="payment-summary__key-value" *ngIf="billDetails.breakup['Item Discount']">
            <div class="payment-summary__key">Discount Applied</div>
            <div class="payment-summary__value discount-value">- Rs. {{
              billDetails.breakup["Item Discount"] | number:'1.0-2'
              }}</div>
          </div>
          <hr>
          <div class="payment-summary__total">
            <div class="total__key">Total</div>
            <div class="total__value">Rs. {{ billDetails.finalPrice | number:'1.0-2' }}</div>
          </div>
        </div>
      </div>
      <div class="cart-footer"
        [style.opacity]="(this.selectedOrderType === 'DELIVERY' ? !this.userCartDetails.address?.address : false) ? '0.4' : '1'">
        <div class="cart-footer__left">Price : {{ userBillingInfo.cashUsed | number:'1.0-2' }}</div>
        <div class="cart-footer__divider"></div>
        <div class="cart-footer__right" (click)="checkOrderBeforePayment()">
          <button *ngIf="!paymentButtonLoader">Proceed to
            Pay</button>
          <button class="loading-btn" *ngIf="paymentButtonLoader" [disabled]="true">Loading...</button>
        </div>
      </div>
    </ng-container>
    <ng-template #showCartPargeLoading>
      <div class="loading-screen">
        <div class="loading-screen-container">
          <img loading="lazy" src="../../../../../assets/common/food-loader.gif" alt="">
        </div>
      </div>
    </ng-template>
  </div>
  <div class="desktop-screen" *ngIf="screenWidth > 480">
    <div class="heading-content">Cart</div>
    <ng-container *ngIf="!cartPageLoader; else showCartPargeLoading">
      <div class="cart-content">
        <div class="cart-page-left-sec">
          <div class="cart-details">
            <div class="cart-details__item-list">
              <ng-container *ngFor="let item of userCartDetails?.itemsList">
                <div class="cart-details__item">
                  <div class="item__top-section">
                    <div class="item__top__left-section">
                      <div class="item-name">
                        <div class="type">
                          <img loading="lazy" src="../../../../../assets/codining/images/common/veg-icon.svg" alt="veg"
                            *ngIf="item.veg">
                          <img loading="lazy" src="../../../../../assets/codining/images/common/non-veg-icon.svg"
                            alt="non-veg" *ngIf="!item.veg">
                        </div>
                        <div class="name" *ngIf="item.itemName; else showName">{{ (item.itemName.length > 35 ?
                          item.itemName.substr(0,
                          35) + '...' :
                          item.itemName ) }}</div>
                        <ng-template #showName>
                          <div class="name" *ngIf="item.name">{{ (item.name.length > 35 ? item.name.substr(0, 35) +
                            '...'
                            :
                            item.name )  }}</div>
                        </ng-template>
                      </div>
                      <div class="vendor-name">{{ item.vendorName }}</div>
                      <div class="item-customization" *ngIf="item.isCustomizationRequired">Customized</div>
                    </div>
                    <div class="item__top__right-section">
                      <div class="item-itemQuantity" [style.opacity]="item.itemStatus === 'OUT_OF_STOCK' ? 0.5 : 1">
                        <div class="minus-icon"
                          (click)="item.itemStatus !== 'OUT_OF_STOCK' && addItemToCart(item, 'DECREASE')">-</div>
                        <div class="qunatity">{{ item.itemQuantity }}</div>
                        <div class="plus-icon"
                          (click)="item.itemStatus !== 'OUT_OF_STOCK' && addItemToCart(item, 'INCREASE')">+</div>
                      </div>
                      <div class="item-price">Rs: {{ item.markUpTotalPrice |  number:'1.0-2' }}</div>
                      <div class="out-of-stock item-itemQuantity" *ngIf="item.itemStatus === 'OUT_OF_STOCK'"
                        (click)="removeItem(item)">Remove Item</div>
                    </div>
                  </div>
                  <div class="item__bottom-section">
                    <input type="text" placeholder="Add Item Cooking Instruction(Optional)"
                      [(ngModel)]="item.cookingInstruction">
                  </div>
                  <!-- <div class="item__bottom-section">
                    Edit
                  </div> -->
                </div>
              </ng-container>
            </div>
            <div class="cart-details__order-type">
              <mat-radio-group [(ngModel)]="selectedOrderType">
                <ng-container *ngFor="let orderType of ORDER_TYPE">
                  <mat-radio-button [style.width.%]="ORDER_TYPE.length > 2 ? '32' : '45'"
                    (click)="changeOrderType($event)" [value]="orderType.value">{{ orderType.name | titlecase
                    }}</mat-radio-button>
                </ng-container>
              </mat-radio-group>
            </div>

            <div class="delivery-address-section" *ngIf="selectedOrderType === 'DELIVERY'">
              <ng-container *ngIf="!deliveryAddress.address; else showUserAddress">
                <div class="address-left-section">Delivery Address</div>
              </ng-container>
              <ng-template #showUserAddress>
                <div class="address-left-section">
                  <div class="address-left-top-sec">{{ userCartDetails.address?.address | titlecase}}</div>
                  <div class="address-left-bottom-sec">{{ userCartDetails.address?.addressTitle | titlecase }}</div>
                </div>
              </ng-template>
              <div class="address-right-section" (click)="goToDeliveryAddressPage()" *ngIf="deliveryAddress.address">
                Change</div>
              <div class="address-right-section" (click)="goToDeliveryAddressPage()" *ngIf="!deliveryAddress.address">
                Select</div>
            </div>
          </div>
        </div>
        <div class="cart-page-right-sec" *ngIf="!showDeliveryAddress">
          <div class="cart-details__bills-summary">
            <div class="payment-summary__primary-text">Payment Summary</div>
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">Item Total</div>
              <div class="payment-summary__value">Rs. {{ billDetails?.breakup["Item Total"] |
                number:'1.0-2' }}</div>
            </div>
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">Taxes & Charges</div>
              <div class="payment-summary__value">Rs. {{ billDetails?.breakup["Taxes & charge"] |
                number:'1.0-2' }}</div>
            </div>
            <div class="payment-summary__key-value" *ngIf="billDetails?.breakup['Packing Charges']">
              <div class="payment-summary__key">Packaging Charges</div>
              <div class="payment-summary__value">Rs. {{ billDetails?.breakup["Packing Charges"] |
                number:'1.0-2' }}</div>
            </div>
            <ng-container *ngIf="billDetails?.breakup['Delivery Charges']">
              <div class="payment-summary__key-value">
                <div class="payment-summary__key">Delivery Charges</div>
                <div class="payment-summary__value">Rs. {{ billDetails?.breakup["Delivery Charges"] |
                  number:'1.0-2' }}</div>
              </div>
              <div class="payment-summary__key-value">
                <div class="payment-summary__key">GST on Delivery</div>
                <div class="payment-summary__value">Rs. {{ billDetails?.breakup["Delivery Charges"] * 0.18
                  |
                  number:'1.0-2' }}</div>
              </div>
            </ng-container>
            <div class="payment-summary__key-value" *ngIf="billDetails?.breakup['Item Discount']">
              <div class="payment-summary__key">Discount Applied</div>
              <div class="payment-summary__value discount-value">- Rs. {{
                billDetails?.breakup["Item Discount"] | number:'1.0-2'
                }}</div>
            </div>
            <hr>
            <div class="payment-summary__total">
              <div class="total__key">Total</div>
              <div class="total__value">Rs. {{ billDetails?.finalPrice | number:'1.0-2' }}</div>
            </div>
          </div>
          <!-- <div class="cart-details__bills-summary">
            <div class="payment-summary__primary-text">Payment Summary</div>
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">Item Total</div>
              <div class="payment-summary__value">Rs. {{ billDetails.breakup["Item Total"] |
                number:'1.0-2' }}</div>
            </div>
            <div class="payment-summary__key-value">
              <div class="payment-summary__key">Taxes & Charges</div>
              <div class="payment-summary__value">Rs. {{ billDetails.breakup["Taxes & charge"] |
                number:'1.0-2' }}</div>
            </div>
            <hr>
            <div class="payment-summary__total">
              <div class="total__key">To Pay</div>
              <div class="total__value">Rs. {{ billDetails.finalPrice | number:'1.0-2' }}</div>
            </div>
          </div> -->

          <div class="cart-details__coupon-section" (click)="openCouponDialog()">
            <div class="coupon__left">
              <span *ngIf="!billDetails?.couponId">Apply Coupon</span>
              <div *ngIf="billDetails?.couponId">
                <img loading="lazy" src="../../../../../assets/codining/images/common/discount-icon.svg"
                  alt="discount icon">
                <div class="coupon-code">{{ billDetails?.couponCode }}</div>
              </div>
            </div>
            <div class="coupon__right">
              <img loading="lazy" src="../../../../../assets/codining/images/common/discount-icon.svg"
                alt="discount icon" *ngIf="!billDetails?.couponCode">
              <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close icon"
                *ngIf="billDetails?.couponCode" (click)="removeDiscountFromCart()">
            </div>
          </div>
          <div class="cart-details__isthara-points">
            <div class="isthara-points__primary-text">Wallet</div>
            <div class="isthara-points">
              <div class="isthara-points-text">{{ userPoints }} Reward Points (Rs. {{ userPoints }})</div>
              <div class="isthara-points-toggle"><mat-slide-toggle
                  (change)="istharaPointsToggled($event)"></mat-slide-toggle></div>
            </div>
          </div>

          <div class="cart-footer" (click)="checkOrderBeforePayment()">
            <div class="cart-footer__left">Price : {{ userBillingInfo.cashUsed | number:'1.0-2' }}</div>
            <div class="cart-footer__divider"></div>
            <div class="cart-footer__right">
              <button *ngIf="!paymentButtonLoader"
                [disabled]="selectedOrderType === 'DELIVERY' ? !userCartDetails.address?.address : false">Proceed to
                Pay</button>
              <button class="loading-btn" *ngIf="paymentButtonLoader" [disabled]="true">Loading...</button>
            </div>
          </div>
        </div>
        <div class="cart-page-right-sec" *ngIf="showDeliveryAddress">
          <app-delivery-address-page></app-delivery-address-page>
        </div>
      </div>
    </ng-container>

    <ng-template #showCartPargeLoading>
      <div class="loading-screen-tab">
        <div class="loading-screen-container-tab">
          <img loading="lazy" src="../../../assets/codining/images/common/burger_loader.gif" alt="">
        </div>
      </div>
    </ng-template>
  </div>
</section>