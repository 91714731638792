import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Subject, BehaviorSubject } from 'rxjs';
import { BookingAvailableRequest, BookingDetails, CashfreePaymentRequest, FavouritePropertyRequest, GeoLocation, Property, PropertyFilterRequest, PropertyFilterRequestForHomePage, SuggestedPropertyResponse } from '../../constants/property';
import { PropertySearchType, PropertyView } from '../../constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ColivingService {

  constructor(
    private http: HttpClient
  ) { }

  // For user geo location coordinates
  private userLocationObs = new Subject<GeoLocation>()

  setUserCurrentLocation(latitude: number, longitude: number) {
    this.userLocationObs.next({
      latitude: latitude,
      longitude: longitude
    })
  }

  getUserCurrentLocation() {
    return this.userLocationObs.asObservable();
  }


  // For storing user favourite properties
  private favouriteproperties: { [key: string]: string } = {};

  // setfavouriteproperties(properties: Property[]) {
  //   properties.forEach((item: Property) => {
  //     if (!this.favouriteproperties[item.propertyId]) {
  //       this.favouriteproperties[item.propertyId] = item.propertyName;
  //     }
  //   })
  // }

  setfavouriteproperties(userId: string = '') {
    if (userId) {
      this.getUserFavouritePropertiesByUserId(userId).subscribe({
        next: (response: any) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item: Property) => {
              if (!this.favouriteproperties[item.propertyId]) {
                this.favouriteproperties[item.propertyId] = item.propertyName;
              }
            })
          }
        }
      })
    } else {
      this.favouriteproperties = {};
    }
  }

  getFavouriteProperties() {
    return this.favouriteproperties;
  }

  // Required for what view should be shown in landing page
  // Must set what view needs to show whenever you navigate
  public propertyViewType = new BehaviorSubject<PropertyView | null>(null);

  // Required for search property list based on what parameter in home page
  // It will be set in property landing component based on result of suggestProperty API
  public propertySearchParameter: PropertySearchType | null = null;

  // Required for checking params required or not
  public shouldCheckParamsInLandingPage: boolean = true;


  // Storing localities for later use if required
  private localities = new Subject<{ name: string, city: string }[]>;

  storeLocalities(city: string = '') {
    this.getLocalitiesByCity(city).subscribe({
      next: (response: any) => {
        if (response.data && response.data.length > 0) {
          const localityList = response.data.map((element: any) => {
            return {
              name: element.name,
              city: element.city
            }
          })

          this.localities.next(localityList);
        } else {
          this.localities.next([]);
        }
      }
    })
  }

  getLocalities() {
    return this.localities.asObservable();
  }

  getNearByProperties(latitude: number, longitude: number) {
    return this.http.get(
      environment.baseUrl + `/property/master/ist/nearestproperties?lat=${latitude}&longi=${longitude}`
    )
  }

  filterProperties(propertyFilterPayload: PropertyFilterRequest) {
    return this.http.post(
      environment.baseUrl + `/property/property/ist/search`, propertyFilterPayload
    )
  }

  getSuggestProperties(searchedText: string) {
    return this.http.get(
      environment.baseUrl + `/property/master/ist/suggest?text=${searchedText}`
    )
  }

  getPropertyByPropertyId(propertyId: string) {
    return this.http.get(
      environment.baseUrl + `/property/property/ist/${propertyId}`
    )
  }

  filterPropertiesWithPagination(propertyFilterRequest: PropertyFilterRequestForHomePage, pageNo: number, size: number) {
    return this.http.put(
      environment.baseUrl + `/property/property/filter/properties/paged?pageNo=${pageNo}&size=${size}`, propertyFilterRequest
    )
  }

  getLocalitiesByCity(city: string) {
    return this.http.get(
      environment.baseUrl + `/property/master/ist/locality?city=${city}`
    )
  }

  // User related API's

  markPropertyAsFavourite(favouritePropertyRequest: FavouritePropertyRequest) {
    return this.http.post(
      environment.baseUrl + `/property/property/ist/markfavouriteproperty`, favouritePropertyRequest
    )
  }

  getUserFavouritePropertiesByUserId(userId: string) {
    return this.http.get(
      environment.baseUrl + `/property/property/ist/favouriteproperties/${userId}`
    )
  }

  getUserDetailsByUserId(userId: string) {
    return this.http.get(
      environment.baseUrl + `/user/user/${userId}`
    )
  }

  getTopPropertyList(pageNo: number, promotionType: string, size : number){
    return this.http.get(
      environment.baseUrl + `/property/property/ist/trendingProperty?pageNo=${pageNo}&promotionType=${promotionType}&size=${size}`
    )
  }

  // Booking API's

  getAvailabilityOfProperty(bookingAvailableRequest: BookingAvailableRequest) {
    return this.http.post(
      environment.baseUrl + `/finance/Booking/getavailability`, bookingAvailableRequest
    )
  }

  createBooking(bookingData: BookingDetails) {
    return this.http.post(
      environment.baseUrl + '/finance/Booking/createBooking', bookingData
    )
  }

  generateCashfreeToken(cashfreeData: { bookingId: string, orderAmount: number }) {
    return this.http.post(
      environment.baseUrl + '/finance/Booking/cashfreetoken', cashfreeData
    )
  }

  generateToken(payload: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('username', payload.username).set('password', payload.password).set('grant_type', payload.grant_type);
    const options = { headers: headers };
    return this.http.post(environment.oldBaseUrl + `/token`, body.toString(), options);
  }

  getUserPendingRentalAmount(residentDetailsId: string) {
    return this.http.get(
      environment.oldBaseUrl +
      `/api/MobileApi/GetCurrentMonthDueAmountByResidentDetailsId?ResidentDetailsId=${residentDetailsId}`
    )
  }

  paymentStatus(paymentData: any) {
    return this.http.post(
      environment.baseUrl + '/finance/Booking/paymentstatus', paymentData
    )
  }

  checkEBRechargeStatus(payload: any, access_token: string) {
    const headers = new HttpHeaders({ 'Authorization': "bearer " + access_token, 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('OrderId', payload.OrderId).set('UserId', payload.UserId);
    const options = { headers: headers };
    return this.http.post(
      environment.oldBaseUrl + `/api/PaymentAPI/SaveCashfreeResponseEB`, body.toString(), options
    )
  }

  checkRentalPaymentStatus(payload: any, access_token: string) {
    const headers = new HttpHeaders({ 'Authorization': "bearer " + access_token, 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('OrderId', payload.OrderId).set('UserId', payload.UserId);
    const options = { headers: headers };
    return this.http.post(
      environment.oldBaseUrl + `/api/PaymentAPI/SaveCashfreeResponse`, body.toString(), options
    )
  }

  // Property Food Subscription

  getPropertyFoodSubscription(userId: string, propertyId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/subscription/ist/user?userId=${userId}&propertyId=${propertyId}`
    )
  }
  getPropertyFoodSubscriptionList(foodCourtId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/subscription/ist/plan?fcId=${foodCourtId}&isActive=true&pageNo=0&size=40`
    )
  }

  getUsersFoodSubscription(userId: string) {
    // return this.http.get(

    // )
  }

  
  getPromotionbannerlist(vertical:string){
    return this.http.get(
      environment.baseUrl + `/foodcourt/promotion/banner/ist/promotional/banners/vertical/list/all?vertical=${vertical}`
    )
  }


}
