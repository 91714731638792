import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { CodiningService } from '../../codining.service';

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.scss']
})
export class BannerDetailsComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private codiningService: CodiningService
  ) { }

  discountId: any;
  loader: boolean = false;
  discountDetails: any;
  ngOnInit(): void {
    this.discountId = this.bottomSheetData.discountId;
    this.getDiscountDetails();
  }

  getDiscountDetails() {
    this.loader = true;
    this.codiningService.getDiscountDetails(this.discountId).subscribe((response: any) => {
      this.discountDetails = response.data;
      this.loader = false;
    }, (error: any) => {

    })
  }

}
