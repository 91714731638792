<section>
    <!-- <div class="top">
          <user-flow-top-navbar></user-flow-top-navbar>
      </div> -->
    <div>
      <h1 class="heading">Terms and Conditions</h1>
      <p class="sub-head">We are here to assist you to follow the terms and conditions of ISTHARA</p>
      <div class="sub-text">
        <div>
          <h4 class="sub-heading">Terms and Conditions</h4>
          <ng-container *ngFor="let  t of terms.bulletPoints">
            <div class="contain">
              <li></li>
              <p class="points">
                {{t.bpoints}}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  
    <div>
      <!-- <app-footer></app-footer> -->
    </div>
  </section>
  