import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ResturantPageService {

  constructor(
    private http: HttpClient
  ) { }

  getVendorFoodItemList(data: any) {
    return this.http.put(environment.baseUrl + `/foodcourt/menu/ist/v1/search/items`, data);
  }
  getVendorDetails(vendorId: string) {
    return this.http.get(environment.baseUrl + `/foodcourt/vendor/ist/vendor/${vendorId}`);
  }

  searchMenuItemForVendor(foodcourtId: string, vendorId: string, searchItem: string, inStock: boolean = true) {
    return this.http.get(environment.baseUrl + `/foodcourt/menu/ist/foodmenu/search?foodCourtId=${foodcourtId}&vendorId=${vendorId}&name=${searchItem}&inStock=${inStock}`);
  }

  getAllSubCategories() {
    return this.http.get(
      environment.baseUrl + `/property/property/v1/ist/list/all/subCategory/pagination?pageNo=0&size=1000`
    )
  }
}
