<section class="banner-details" *ngIf="!loader; else showLoadingScreen">
  <div class="banner-heading">
    <div class="banner-title">Flat 200.0/- on Order above 500/-</div>
    <div class="banner-secondary-text">Vald on total value of items worth 500 or more</div>
  </div>
  <div class="banner-content-divider"></div>
  <div class="banner-content">
    <div class="banner-secondary-text bold-text">Terms & Conditions</div>
    <div class="banner-terms-section">
      <ul>
        <ng-container>
          <li>Coupon Code is applicable for Mrp and Non Mrp items</li>
        </ng-container>
      </ul>
    </div>
  </div>
</section>
<ng-template #showLoadingScreen>
  <div class="loading-screen-container">
    <div class="loader-image">
      <img src="../../../../../assets/common/food-loader.gif" alt="food loader">
    </div>
  </div>
</ng-template>