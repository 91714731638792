<section class="resturant-page-section" *ngIf="!resturantPageLoader; else showLoadingScreen">
  <div class="resturent-side-img" *ngIf="screenWidth <= 480">
    <img src="../../../../../assets/codining/images/resturant-page/resturent-side-img-mobile.svg" alt="">
  </div>
  <div class="back-btn" (click)="goToHomePage()">

    <mat-icon>keyboard_arrow_left</mat-icon>

  </div>
  <div class="resturant-details">

    <div class="resturant-name">{{ vendorDetails.vendor.brandName }}</div>
    <div class="resturant-rating">
      <img loading="lazy" src="../../../assets/codining/images/common/rating-star.svg" alt="">
      {{ vendorDetails.vendor.rating | number:'1.0-2' }} Rating
    </div>
 
    <div class="resturant-cuisines">
      <ng-container *ngFor="let cuisine of vendorDetails.cuisineCategories">

      <ul class="cuisine">
        <li>
          {{cuisine |titlecase }}
        </li>
      </ul>
    </ng-container>
    </div>
  
    <div class="vendor-coupon-listing" *ngIf="vendorDetails.discountResponseList.length > 0">
      <ng-container *ngFor="let discountDetail of vendorDetails.discountResponseList; let idx = index">
        <div class="coupon-container" [style.backgroundImage]="discountDetail.discountImageUrl"
          [style.right.%]="(couponPositionFromLeft) % (vendorDetails.discountResponseList.length * 100)">
          <div class="discount-title">{{ discountDetail.discountName | titlecase }}</div>
          <div class="discount-description">{{ discountDetail.discountdesc | titlecase }}</div>
        </div>
      </ng-container>
      <div class="dots-listing">
        <ng-container *ngFor="let _ of vendorDetails.discountResponseList.length; let idx = index">
          <div class="dot"></div>
        </ng-container>
      </div>
    </div>
    <div class="resturant-search-container">
      <div class="search-container">
        <input type="text" placeholder="Search Menu Item or Cuisine" [(ngModel)]="searchText"
          (ngModelChange)="handleFoodcourtSearch()">
        <mat-icon>search</mat-icon>
      </div>
    </div>
  </div>
  <div class="resturant-item-listing-section">
    <ng-container *ngIf="!menuItemListLoader; else showMenuItemListLoader">
      <div class="resturant-item-filter">
        <div class="filter-container">
          <div class="filter-container-text" (click)="showSortContent = !showSortContent">Sort by</div>
          <div class="filter-container-popup" *ngIf="showSortContent">
            <mat-radio-group [(ngModel)]="sortingType" (ngModelChange)="sortItemListing()">
              <mat-radio-button value="LOW_TO_HIGH">Price Low to High</mat-radio-button>
              <mat-radio-button value="HIGH_TO_LOW">Price High to Low</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <!-- <div class="filter-container">Less than 10 mins</div> -->
        <div class="filter-container" [ngClass]="{'filter-container_active': selectedMealType === 'VEG' }"
          (click)="fiterResturantMenu('VEG')">
          <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt="veg icon">
          Veg
        </div>
        <div class="filter-container" [ngClass]="{'filter-container_active': selectedMealType === 'NON_VEG' }"
          (click)="fiterResturantMenu('NON_VEG')">
          <img loading="lazy" src="../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg icon">
          Non Veg
        </div>
      </div>
      <div class="resturant-item-listing">
        <ng-container *ngIf="vendorItemList.length > 0; else showEmptyScreen">
          <ng-container *ngFor="let item of vendorItemList">
            <div class="item-container">
              <div class="item-container__left">
                <div class="item-meal-type">
                  <!-- <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt=""> -->
                </div>
                <div class="item-name">
                  <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt="veg"
                    *ngIf="item.itemType === 'VEG'">
                  <img loading="lazy" src="../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg"
                    *ngIf="item.itemType === 'NON_VEG'">
                  {{ (item.name.length > 35 ? item.name.substr(0, 35) + "..." : item.name ) }}
                </div>
                <div class="item-iscustomizable" *ngIf="item.customizationRequired">Customisable</div>
                <div class="item-rating">
                  <img loading="lazy" src="../../../assets/codining/images/resturant-page/rating-star-fill.svg" alt="">
                  {{ item.rating | number: '0.0-1' }}
                </div>
                <div class="item-price">Rs : {{ item.markUpPrice | number:'1.0-2' }}</div>
              </div>
              <div class="item-container__right">
                <div class="item-image">
                  <img loading="lazy" src="../../../assets/common/item-empty-icon.svg" *ngIf="!item.itemImageUrl"
                    alt="item image">
                  <img loading="lazy" [src]="item.itemImageUrl" *ngIf="item.itemImageUrl" alt="item image">
                </div>
                <ng-container
                  *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OUT_OF_STOCK">
                  <div class="add-to-cart-btn" (click)="addItemToCart(item, 'INCREASE')" *ngIf="!item.showItemQuantity">
                    Add
                  </div>
                  <div class="add-to-cart-btn" *ngIf="item.showItemQuantity">
                    <div class="decrease-item-count" (click)="addItemToCart(item, 'DECREASE')"> - </div>
                    <div class="item-itemQuantity-count">{{ item.showItemQuantity }}</div>
                    <div class="increase-item-count" (click)="addItemToCart(item, 'INCREASE')"> + </div>
                  </div>
                </ng-container>
                <ng-template #OUT_OF_STOCK>
                  <div class="out-of-stock">Out Of Stock</div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #showEmptyScreen>
          <div class="empty-listing-section">
            <div class="empty-image-container">
              <img src="../../../../../assets/common/empty-plate.svg" alt="empty">
            </div>
            <div class="empty-primary-text">Ohh! Try Other Filter</div>
            <div class="empty-secondary-text">Looks like we do not have items of your search</div>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #showMenuItemListLoader>
      <div class="menu-loading-screen">
        <div class="loading-screen-container">
          <img loading="lazy" src="../../../../../assets/common/food-loader.gif" alt="">
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="userCartData.length > 0">
    <app-cart-footer [cartDetails]="userCartData"></app-cart-footer>
  </ng-container>
</section>

<ng-template #showLoadingScreen>
  <div class="loading-screen">
    <div class="loading-screen-container">
      <img loading="lazy" src="../../../../../assets/common/food-loader.gif" alt="">
    </div>
  </div>
</ng-template>
