<div class="choose-meal-spinner-section">
    <div class="close-container">
        <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="" (click)="closeBottomSheet()">
    </div>
    <div class="primary-text">Confused</div>
    <div class="secondary-text">Let us help you</div>
    <div class="spin-btn" (click)="rotateAgain()">Spin</div>
    <div class="choosen-item">
        <div class="item-details">
            <img loading="lazy" src="../../../assets/codining/images/common/pepsi-img.svg" alt="">
            <div class="item-name">Pepsi</div>
        </div>
    </div>
    <div class="spinning-container">
        <div class="container-one" #rotateContainer>
            <div class="item-position ball-one">
                <img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt="">
                <div class="item-details">
                    <img loading="lazy" src="../../../assets/codining/images/common/pepsi-img.svg" alt="">
                    <div class="item-name">Pepsi</div>
                </div>
            </div>
            <div class="item-position ball-two">
                <img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt="">
                <div class="item-details">
                    <img loading="lazy" src="../../../assets/codining/images/common/pepsi-img.svg" alt="">
                    <div class="item-name">Pepsi</div>
                </div>
            </div>
            <div class="item-position ball-three">
                <img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt="">
                <div class="item-details">
                    <img loading="lazy" src="../../../assets/codining/images/common/pepsi-img.svg" alt="">
                    <div class="item-name">Pepsi</div>
                </div>
            </div>
            <div class="item-position ball-four">
                <img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt="">
                <div class="item-details">
                    <img loading="lazy" src="../../../assets/codining/images/common/pepsi-img.svg" alt="">
                    <div class="item-name">Pepsi</div>
                </div>
            </div>
        </div>
        <!-- <div class="container-two">
            <div class="item-position ball-one"><img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt=""></div>
            <div class="item-position ball-two"><img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt=""></div>
            <div class="item-position ball-three"><img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt=""></div>
            <div class="item-position ball-four"><img loading="lazy" src="../../../assets/codining/images/common/random-item-bg.svg" alt=""></div>
        </div> -->
    </div>
</div>