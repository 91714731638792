<section class="delivery-address-section">
  <div class="address-header">
    <img loading="lazy" src="../../../../../assets/codining/images/common/back_arrow.svg" alt="back_arrow"
      (click)="goToCartPage()">
    <span>{{ presentPage === 'ADDRESS_PAGE' ? 'Select Address' : 'Add Delivery Location' }}</span>
  </div>
  <div class="page-view" [ngSwitch]="presentPage">
    <section class="address-listing-page" *ngSwitchCase="'ADDRESS_PAGE'">
      <div class="add-new-address-btn" (click)="changeView('ENTER_ADDRESS')">
        <div class="add-address">+ Add New Address</div>
        <div class="navigation-arrow-icon">
          <img loading="lazy" src="../../../../../assets/codining/images/common/back_arrow.svg" alt="back">
        </div>
      </div>
      <div class="address-listing">
        <div class="address-heading-text">Saved Locations</div>
        <div class="listing-container">
          <ng-container *ngFor="let address of userAddressList">
            <div class="address-container" (click)="selectAddress(address)">
              <div class="address-container__left-sec">
                <img loading="lazy" src="../../../../../assets/codining/images/common/hostel-address-icon.svg"
                  alt="address" *ngIf="address.addressTitle === 'College'">
                <img loading="lazy" src="../../../../../assets/codining/images/common/work-address-icon.svg"
                  alt="address" *ngIf="address.addressTitle === 'Work' || address.addressTitle === 'Home'">
                <img loading="lazy" src="../../../../../assets/codining/images/common/other-address-icon.svg"
                  alt="address"
                  *ngIf="address.addressTitle !== 'College' && address.addressTitle !== 'Work' && address.addressTitle !== 'Home'">
              </div>
              <div class="address-container__right-sec">
                <div class="address-type">{{ address.addressTitle | titlecase }}</div>
                <div class="address-text">{{ address.address | titlecase }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
    <div class="address-selection-page" *ngSwitchCase="'ENTER_ADDRESS'">
      <div class="map-section">
        <agm-map [latitude]="locationDetail.geoLocation.latitude" [longitude]="locationDetail.geoLocation.longitude"
          [zoom]="10" [disableDefaultUI]="false" (mapReady)=" onMapReady($event)" #AgmMap>
          <agm-marker [latitude]="locationDetail.geoLocation.latitude"
            [longitude]="locationDetail.geoLocation.longitude" [label]="locationDetail.geoLocation.label"
            [markerDraggable]="locationDetail.geoLocation.draggable" (dragEnd)="markerDragEnd($event)">
          </agm-marker>
        </agm-map>
        <div class="user-current-location-btn" (click)="getLocation()">
          <img loading="lazy" src="../../../../../assets/common/location-icon.svg" alt="location icon">
          <div class="fetch-current-location-btn">Use Current Location</div>
        </div>
      </div>
      <div class="address-form">
        <div class="form-sec">
          <div class="form-label">Address <sup>*</sup></div>
          <input type="text" placeholder="Eg. Vidhan Sabha, Raipur" [(ngModel)]="locationDetail.addressLine">
        </div>
        <div class="form-sec">
          <div class="form-label">Name <sup>*</sup></div>
          <input type="text" placeholder="Eg. Ranu" [(ngModel)]="username">
        </div>
        <div class="form-sec">
          <div class="form-label">Mobile Number <sup>*</sup></div>
          <input type="text" placeholder="+91"  pattern="^[6-9]\d{9}$|^[1-9]\d{9}$"
          onKeyPress="if(this.value.length==10) return false;" [(ngModel)]="usermobile">
        </div>
        <div class="form-sec">
          <div class="form-label">Save as <sup>*</sup></div>
          <div class="save-as-chip-list">
            <ng-container *ngFor="let addressType of addressTypeChips">
              <div class="save-as-chip" [ngClass]="{'selected-chip': locationDetail.geoLocation.title === addressType}"
                (click)="locationDetail.geoLocation.title = addressType">{{
                addressType | titlecase }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="action-btn">
      <button class="confirm-location-btn" (click)="addNewAddress()"
        [disabled]="!locationDetail.addressLine || !usermobile || !locationDetail.geoLocation.title">Confirm
        Location</button>
    </div>
  </div>
</section>