<section class="warning-container">
  <ng-container *ngIf="dialogType === 'LOGOUT'">
    <div class="heading-container">
      <div class="heading__left-sec">Info</div>
      <div class="heading__right-sec" (click)="closeDialogBox('CLOSE')">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="">
      </div>
    </div>
    <div class="dialog-content">
      <div class="content-text">Are you sure you want to Logout?</div>
      <ul>
        <li class="left-btn-desc">Click Logout to clear your session</li>
        <li class="right-btn-desc">Click Close to close the Warning popup</li>
      </ul>
    </div>
    <div class="footer-container">
      <button class="left-btn" (click)="closeDialogBox('LOGOUT')">Logout</button>
      <button class="right-btn" (click)="closeDialogBox('CLOSE')">Close</button>
    </div>
  </ng-container>
  <ng-container *ngIf="dialogType === 'CANCEL_ORDER'">
    <div class="heading-container">
      <div class="heading__left-sec">Info</div>
      <div class="heading__right-sec" (click)="closeDialogBox('CLOSE')">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="">
      </div>
    </div>
    <div class="dialog-content">
      <div class="content-text">Are you sure you want to Cancel?</div>
      <ul>
        <li class="left-btn-desc">Click Cancel to Cancel Order</li>
        <li class="right-btn-desc">Click Close to close the Warning popup</li>
      </ul>
    </div>
    <div class="footer-container">
      <button class="left-btn" (click)="closeDialogBox('CANCEL')">Cancel</button>
      <button class="right-btn" (click)="closeDialogBox('CLOSE')">Close</button>
    </div>
  </ng-container>
  <ng-container *ngIf="dialogType === 'CHECKOUT'">
    <div class="heading-container">
      <div class="heading__left-sec">Info</div>
      <div class="heading__right-sec" (click)="closeDialogBox('CLOSE')">
        <img loading="lazy" src="../../../../../assets/codining/images/common/close.svg" alt="close">
      </div>
    </div>
    <div class="dialog-content">
      <div class="content-text">Are you sure you want to Cancel?</div>
      <ul>
        <li class="left-btn-desc">Click Checkout button to Checkout</li>
        <li class="right-btn-desc">Click Close to close the Warning popup</li>
      </ul>
    </div>
    <div class="footer-container">
      <button class="left-btn" (click)="closeDialogBox('CHECKOUT')">Checkout</button>
      <button class="right-btn" (click)="closeDialogBox('CLOSE')">Close</button>
    </div>
  </ng-container>
</section>