<div class="customization-section">
  <section [ngSwitch]="operationType">
    <div class="add-item" *ngSwitchCase="'INCREASE'">
      <ng-container
        *ngIf="itemCustomizationDetail.orderedItemCustomizations.length === 0 || createNewId; else repeatCustomizationSection">
        <section class="new-customization-section">
          <div class="close-section">
            <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt=""
              (click)="closeBottomSheet()">
          </div>
          <div class="header">
            <div class="primary-text-soft">Customise your dish</div>
            <div class="item-preview">
              <div class="item-name">{{ (itemCustomizationDetail.name) | titlecase }}</div>
              <!-- <div class="item-price">Rs. {{ itemCustomizationDetail.price |  number:'1.0-2' }}</div> -->
              <div class="item-price">Rs. {{ itemCustomizationDetail.markUpPrice |  number:'1.0-2' }}</div>
            </div>
            <div class="warning-message">
              Max allowed <span style="color:#C53053;font-weight: 600;">{{itemCustomizationDetail.menuCustomizationList[0].maxOptionAllowed}}</span> only
            </div>
          </div>
          <div class="customization-container">
            <!-- <div class="add-ons-section">
                        <div class="primary-text">Add Ons</div>
                        <div class="cusomization-list">
                            <ng-container *ngFor="let _ of [].constructor(5)">
                                <div class="item-container">
                                    <div class="custom-item-name">Extra Onion</div>
                                    <div class="custom-item-price">
                                        <div class="price">Rs. 10</div>
                                        <mat-radio-group>
                                            <mat-radio-button></mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div> -->
            <div class="customize-section">
              <ng-container *ngFor="let customization of itemCustomizationDetail?.menuCustomizationList">
                <div class="primary-text">How would you like it? ({{ customization.customizationCategoryName | titlecase
                  }})
                  <sup *ngIf="customization.required" [style.color]="'red'">*</sup>
                </div>
                <div class="cusomization-list" *ngIf="customization.maxOptionAllowed === 1">
                  <mat-radio-group [(ngModel)]="customization.selectedCustomizationId">
                    <ng-container *ngFor="let customItem of customization?.customizedItemsForMenuList">
                      <div class="item-container">
                        <div class="custom-item-name">{{ customItem.subTitle | titlecase }}</div>
                        <div class="custom-item-price">
                          <div class="price">Rs. {{ customItem.markupPrice | number:'1.0-2' }}
                          </div>
                          <!-- <ng-container> -->
                          <!-- <mat-radio-group > -->
                          <mat-radio-button [disabled]="!customItem.active" [value]="customItem.uniqueId"
                            (click)="changeCustomization(customization, customItem)"></mat-radio-button>
                          <!-- </mat-radio-group> -->
                          <!-- </ng-container> -->
                          <!-- <ng-container *ngIf="customization.maxOptionAllowed > 1">
                          <mat-checkbox
                            [disabled]="(!customItem.active) || (customization.noOfSelectedItem === customization.maxOptionAllowed)"
                            [value]="customItem.uniqueId" [(ngModel)]="customItem.selected"
                            (click)="calculateCustomizationPrice()"></mat-checkbox>
                        </ng-container> -->

                        </div>
                      </div>
                    </ng-container>
                  </mat-radio-group>
                </div>
                <div class="cusomization-list" *ngIf="customization.maxOptionAllowed > 1">
                  <!-- <mat-radio-group [(ngModel)]="customization.selectedCustomizationId"> -->
                  <ng-container *ngFor="let customItem of customization?.customizedItemsForMenuList">
                    <div class="item-container">
                      <div class="custom-item-name">{{ customItem.subTitle | titlecase }}</div>
                      <div class="custom-item-price">
                        <!-- <div class="price">Rs. {{ customItem.price | number:'1.0-2' }} -->
                        <div class="price">Rs. {{ customItem.markupPrice | number:'1.0-2' }}
                        </div>
                        <!-- <mat-radio-button [disabled]="!customItem.active" [value]="customItem.uniqueId"
                            (click)="changeCustomization(customization, customItem)"></mat-radio-button> -->
                        <mat-checkbox
                          [disabled]="(!customItem.active) || (customItem.disabled)"
                          [value]="customItem.uniqueId" [(ngModel)]="customItem.selected"
                          id="checkbox"
                          (click)="calculateCustomizationPrice()"></mat-checkbox>

                      </div>
                    </div>
                  </ng-container>
                  <!-- </mat-radio-group> -->
                </div>
              </ng-container>
            </div>
          </div>
          <div class="footer">
            <div class="item-price">Price : Rs. {{ finalPrice | number: '1.0-2' }}</div>
            <div class="add-to-cart-btn" (click)="addItemToCart()">Add Item</div>
          </div>
        </section>
      </ng-container>
      <ng-template #repeatCustomizationSection>
        <section class="repeat-last-customization-section">
          <div class="header-section">
            <div class="heading-text">Repeat Previous Customization</div>
            <div class="close-img" (click)="closeBottomSheet()">
              <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="">
            </div>
          </div>
          <div class="cart-details__item">
            <ng-container *ngFor="let custom of itemCustomizationDetail.orderedItemCustomizations">
              <div class="item__top-section">
                <div class="item__top__left-section">
                  <div class="item-name">{{ custom.name | titlecase }}</div>
                  <div class="vendor-name">{{ itemCustomizationDetail.vendorName | titlecase }}</div>
                  <!-- <div class="item-customization">Customized</div> -->
                </div>
                <div class="item__top__right-section">
                  <!-- <div class="item-itemQuantity">
                                  <div class="plus-icon" (click)="addItemToCart(item, 'INCREASE')">+</div>
                                  <div class="qunatity">{{ item.itemQuantity }}</div>
                                  <div class="minus-icon" (click)="addItemToCart(item, 'DECREASE')">-</div>
                              </div> -->
                  <div class="item-price">Rs: {{ custom.price |  number:'1.0-2' }}</div>
                </div>
              </div>
              <hr />
            </ng-container>
            <!-- <div class="item__bottom-section">
              Edit
            </div> -->
          </div>
          <div class="footer">
            <div class="item-price" (click)="choseNewCustomization()">Choose new</div>
            <div class="add-to-cart-btn" (click)="repeatCustomization()">Repeat</div>
          </div>
        </section>
      </ng-template>
    </div>
    <div class="remove-item" *ngSwitchCase="'DECREASE'">
      <section class="new-customization-section">
        <div class="close-section">
          <img loading="lazy" src="../../../assets/codining/images/common/close.svg" alt="close"
            (click)="closeBottomSheet()">
        </div>
        <div class="header">
          <div class="primary-text-soft">Select Customization to Remove</div>
          <div class="item-preview">
            <div class="item-name">{{ (itemCustomizationDetail.name) | titlecase }}</div>
            <!-- <div class="item-price">Rs. {{ itemCustomizationDetail.price }}</div> -->
          </div>
          <div class="selected-items-list">
            <ng-container *ngFor="let custom of customizedItemList">
              <mat-checkbox [checked]="selectedCustomizationToRemove.includes(custom.appItemId)"
                (click)="addItemToRemove($event, custom)">
                <div class="item-name-with-customization">
                  <ng-container *ngFor="let itemDetail of custom.orderedItemCustomizations">{{ itemDetail.name |
                    titlecase }}</ng-container>
                </div>
              </mat-checkbox>
            </ng-container>
          </div>
          <div class="footer-container">
            <button class="remove-customization-btn" [disabled]="selectedCustomizationToRemove.length === 0"
              (click)="removeCustomItem()">Remove
              Item</button>
          </div>
        </div>
      </section>
    </div>