import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient
  ) { }

  // Special Case when we need to get all cart item
  public fetchAllCartItem: Subject<void> = new Subject<void>();

  getCartData(userId: string) {
    return this.http.get(
      environment.baseUrl + `/foodcourt/user/ist/cart?userId=${userId}`
    );
  }

}
