import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';

@Component({
  selector: 'app-codining-landing',
  templateUrl: './codining-landing.component.html',
  styleUrls: ['./codining-landing.component.scss']
})
export class CodiningLandingComponent implements OnInit {

  constructor(
    private sharedUtilService: SharedService,
    private activatedRoute: ActivatedRoute,
  ) {

    // console.log("CodiningLandingComponent");
  }

  foodCourtList: any[] = [];
  showLoadingScreen: boolean = false;
  // getFoodCourtList() {
  //   this.showLoadingScreen = true;
  //   this.codiningService.getAllFoodCourts().subscribe((response: any) => {
  //     this.foodCourtList = response.data.filter((foodCourt: any) => foodCourt.active);

  //   }, (error: any) => {

  //   }, () => {
  //     this.checkParams();
  //   })
  // }

  ngOnInit(): void {
    // this.getFoodCourtList();
    // this.checkParams();
  }

  // checkParams(): void {
  //   this.showLoadingScreen = true;
  //   this.activatedRoute.paramMap.subscribe((params: any) => {
  //     if (params) {
  //       let foodCourtDetails: any;
  //       this.foodCourtList.forEach((foodCourt: any) => {
  //         let foodCourtOne = foodCourt.foodCourtName.toLowerCase().replaceAll("-", "").replaceAll(" ", "");
  //         let foodCourtTwo = params.params['foodCourt'].toLowerCase().replaceAll(" ", "").substr(0, 10);

  //         if (foodCourtOne.includes(foodCourtTwo)) {
  //           foodCourtDetails = foodCourt;
  //           this.localStorageService.storeInLocalStorage("foodCourtName", foodCourt.foodCourtName);
  //           this.localStorageService.storeInLocalStorage("foodCourtId", foodCourt.foodCourtId);
  //           this.localStorageService.storeInLocalStorage("siteName", foodCourt.siteName);
  //           this.localStorageService.storeInLocalStorage("siteId", foodCourt.siteId);
  //         }
  //       })

  //       this.showLoadingScreen = false;
  //     } else {
  //       this.showLoadingScreen = false;
  //     }
  //   })
  // }
}
