<!-- <section class="resturant-cuisine-section">
  <div class="header-section" *ngIf="screenWidth <= 480">
    <div class="back-btn">
      <img loading="lazy" src="../../../../../assets/codining/images/common/back_arrow.svg" alt="back"
        (click)="goToHomePage()">
    </div>
    <div class="header-text">{{ selectedCuisineDetails.cuisineName | titlecase }}</div>
  </div>
  <div class="header-section-tab" *ngIf="screenWidth > 480">
    <div class="heading-text">{{ selectedCuisineDetails.cuisineName | titlecase }}</div>
    <img src="../../../../../assets/codining/images/common/close.svg" alt="close" (click)="goToHomePage()">
  </div>
  <div class="resturant-listing-section">
    <ng-container *ngFor="let vendorDetails of selectedCuisineDetails.vendorCuisineLookupList">
      <div class="resturant-container" (click)="goToResturantPage(vendorDetails)">
        <div class="resturant-details">
          <div class="resturant-details__left-section">
            <div class="vendor-name">{{ vendorDetails.brandName | titlecase }}</div>
            <div class="cuisines">South Indian * North Indian</div>
            <div class="ordering-type">Self Pickup / Delivery</div>
          </div>
          <div class="resturant-details__right-section">
            <img loading="lazy" [src]="vendorDetails.imgUrl" alt="vendor image"
              *ngIf="vendorDetails.imgUrl && vendorDetails.imgUrl !== 'string'">
            <img loading="lazy" src="../../../assets/codining/images/common/default_restuarant.svg" alt=""
              *ngIf="!vendorDetails.imgUrl || vendorDetails.imgUrl === 'string'">
          </div>
        </div>
        <div class="resturant-footer" *ngIf="vendorDetails.vendorDiscountsList.length > 0">
          <ng-container *ngFor="let discount of vendorDetails.vendorDiscountsList; let idx = index">
            <div class="resturant-discount"
              [style.right.%]="(vendorDiscountPositionFromLeft * idx) % (vendorDetails.vendorDiscountsList.length * 100)">
              {{ discount.discountName | titlecase }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</section> -->

<section class="resturant-page-section">
  <div class="back-btn" (click)="goToHomePage()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <div class="resturant-details">
    <div class="resturant-name">{{ selectedCuisineDetails?.cuisineName | titlecase }}</div>
    <div class="description-text">Embrace convenience save time, and enjoy your meals like never before.</div>
    <!-- <div class="resturant-rating">
      <img loading="lazy" src="../../../assets/codining/images/common/rating-star.svg" alt="">
      {{ vendorDetails.vendor.rating | number:'1.0-2' }} Rating
    </div> -->
    <!-- <div class="resturant-cuisines">South Indian . North Indian</div> -->
    <div class="resturant-search-container">
      <div class="search-container">
        <input type="text" placeholder="Search Menu Item or Cuisine" [(ngModel)]="searchText"
          (ngModelChange)="handleFoodcourtSearch()">
        <mat-icon>search</mat-icon>
      </div>
    </div>
  </div>
  <div class="resturant-item-listing-section">
    <ng-container *ngIf="!menuItemListLoader; else showMenuItemListLoader">
      <div class="resturant-item-filter">
        <!-- <div class="filter-container">Sort by</div> -->
        <!-- <div class="filter-container">Less than 10 mins</div> -->
        <!-- <div class="filter-container" [ngClass]="{'filter-container_active': selectedMealType === 'VEG' }"
          (click)="fiterResturantMenu('VEG')">
          <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt="veg icon">
          Veg
        </div>
        <div class="filter-container" [ngClass]="{'filter-container_active': selectedMealType === 'NON_VEG' }"
          (click)="fiterResturantMenu('NON_VEG')">
          <img loading="lazy" src="../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg icon">
          Non Veg
        </div> -->
      </div>
      <div class="resturant-item-listing">
        <ng-container *ngIf="cuisineItemList.length > 0; else showEmptyScreen">
          <ng-container *ngFor="let item of cuisineItemList">
            <div class="item-container">
              <div class="item-container__left">
                <div class="item-meal-type">
                  <!-- <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt=""> -->
                </div>
                <div class="item-name">
                  <img loading="lazy" src="../../../assets/codining/images/common/veg-icon.svg" alt="veg"
                    *ngIf="item.itemType === 'VEG'">
                  <img loading="lazy" src="../../../assets/codining/images/common/non-veg-icon.svg" alt="non veg"
                    *ngIf="item.itemType === 'NON_VEG'">
                  {{ (item.name.length > 30 ? item.name.substr(0, 30) + "..." : item.name ) | titlecase }}
                </div>
                <div class="item-iscustomizable" *ngIf="item.customizationRequired">Customisable</div>
                <div class="item-rating">
                  <img loading="lazy" src="../../../assets/codining/images/resturant-page/rating-star-fill.svg" alt="">
                  {{ item.rating | number: '0.0-1' }}
                </div>
                <div class="item-price">Rs : {{ item.markUpPrice | number:'1.0-2' }}</div>
              </div>
              <div class="item-container__right">
                <div class="item-image">
                  <img loading="lazy" src="../../../../../assets/common/item-empty-icon.svg"
                    *ngIf="!item.itemImageUrl" alt="item image">
                  <img loading="lazy" [src]="item.itemImageUrl" *ngIf="item.itemImageUrl" alt="item image">
                </div>
                <ng-container
                  *ngIf="item.itemStatus === 'IN_STOCK' || item.itemStatus === 'VENDOR_AVAILABLE'; else OUT_OF_STOCK">
                  <div class="add-to-cart-btn" (click)="addItemToCart(item, 'INCREASE')" *ngIf="!item.showItemQuantity">
                    Add
                  </div>
                  <div class="add-to-cart-btn" *ngIf="item.showItemQuantity">
                    <div class="decrease-item-count" (click)="addItemToCart(item, 'DECREASE')"> - </div>
                    <div class="item-itemQuantity-count">{{ item.showItemQuantity }}</div>
                    <div class="increase-item-count" (click)="addItemToCart(item, 'INCREASE')"> + </div>
                  </div>
                </ng-container>
                <ng-template #OUT_OF_STOCK>
                  <div class="out-of-stock">Out Of Stock</div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #showEmptyScreen>
          <div class="empty-listing-section">
            <div class="empty-image-container">
              <img src="../../../../../assets/common/empty-plate.svg" alt="empty">
            </div>
            <div class="empty-primary-text">Ohh! Try Other Filter</div>
            <div class="empty-secondary-text">Looks like we do not have items of your search</div>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #showMenuItemListLoader>
      <div class="menu-loading-screen">
        <div class="loading-screen-container">
          <img loading="lazy" src="../../../assets/codining/images/common/burger_loader.gif" alt="">
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="userCartData.length > 0">
    <app-cart-footer [cartDetails]="userCartData"></app-cart-footer>
  </ng-container>
</section>