import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { CodiningService } from '../../codining.service';

@Component({
  selector: 'app-redirecting-page',
  templateUrl: './redirecting-page.component.html',
  styleUrls: ['./redirecting-page.component.scss']
})
export class RedirectingPageComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharedUtilService: SharedService,
    private router: Router,
    private codiningService: CodiningService
  ) {

    this.checkParams();
  }


  foodCourtId: string | null = null;
  siteId: string | null = null;

  // ngOnInit() {
  //
  //   this.checkParams();
  // }
  checkParams() {

    this.activatedRoute.queryParams.subscribe((qParams: any) => {
      if (qParams['siteId']) {

        this.foodCourtId = qParams['foodCourtId'];
        this.siteId = qParams['siteId'];
        this.sharedUtilService.storeInLocalStorage('foodCourtId', this.foodCourtId);
        this.sharedUtilService.storeInLocalStorage('siteId', this.siteId);
        this.getFoodCourtDetails();
      }

    })
  }
  getFoodCourtDetails() {
    if (!this.foodCourtId)
      return;


    this.codiningService.getFoodocurtByFoodcourtId(this.foodCourtId).subscribe((response: any) => {
      let foodCourtName = response.data.foodCourtName;
      let siteName = response.data.siteName;
      let siteId = response.data.siteId;


      this.sharedUtilService.storeInLocalStorage('foodCourtName', foodCourtName);
      this.sharedUtilService.storeInLocalStorage('siteName', siteName);
      this.sharedUtilService.storeInLocalStorage('siteId', siteId);
      this.router.navigate([`/mbd/${foodCourtName}`]);
    }, (error: any) => {
      this.sharedUtilService.storeInLocalStorage('foodCourtName', null);
      this.sharedUtilService.storeInLocalStorage('siteName', null);
      this.sharedUtilService.storeInLocalStorage('siteId', null);
      this.router.navigate([`/mbd`]);
    })
  }
}
