<section class="codining-homepage-section">
  <!-- <div class="codining-banner">
    <img loading="lazy" src="../../../../../assets/codining/images/landing-page/codining-banner-desktop.png" alt=""
      *ngIf="screenWidth <= 480" />
    <img loading="lazy" src="../../../../../assets/codining/images/landing-page/codining-banner-desktop.png"
      class="destop-view-banner" *ngIf="screenWidth > 480" alt="" />
    <div class="codining-banner-text-container">
      <div class="banner__primary-text">Feast, Fun and Flavour!</div>
      <div class="banner__secondary-text">
        Experience the perfect blend of food, entertainment, and unforgettable
        moments at Isthara FoodCourt
      </div>

       <div class="search-container" *ngIf="screenWidth > 480">
                <input type="text" class="search-feild" placeholder="Search Co-dining Spaces">
                <img loading="lazy" src="../../../assets/codining/images/common/search-btn.svg" alt="">
            </div>
    </div>
  </div> -->
  <div class="codining-banner">
    <img loading="lazy" src="../../../../../assets/website/images/common/dine-in-with-us-banner-mobile.svg" alt=""
      *ngIf="screenWidth <= 480">
    <img loading="lazy" src="../../../../../assets/website/images/common/dine-in-with-us-banner.svg"
      class="destop-view-banner" *ngIf="screenWidth > 480" alt="image">
  </div>

  <div class="codining-features">
    <div class="feature feature-one" [style.backgroundColor]="'#EDFFFA'">
      <div class="feature-icon">
        <img loading="lazy" src="../../assets/codining/images/landing-page/variety-icon.svg" alt="foodcourt" />
      </div>
      <div class="feature-text">Variety at its best</div>
    </div>
    <div class="feature feature-two" [style.backgroundColor]="'#FFF6E9'">
      <div class="feature-icon">
        <img loading="lazy" src="../../../../../assets/coliving/property-home/community-connect.svg" alt="foodcourt" />
      </div>
      <div class="feature-text">Community Connect</div>
    </div>
    <div class="feature feature-three" [style.backgroundColor]="'#FFF2F4'">
      <div class="feature-icon">
        <img loading="lazy" src="../../assets/codining/images/landing-page/money.svg" alt="foodcourt" />
      </div>
      <div class="feature-text">Value for money</div>
    </div>
  </div>
  <div class="codining-with-tech">
    <div class="tech-integration__primary-text">
      Where culinary excellence meets tech convenience
    </div>
    <!-- <div class="tech-integration-carousel" *ngIf="screenWidth <= 480">
      <ngx-slick-carousel class="carousel-content" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let feature of techIntegrationFeatures; let i = index">
          <div class="img-container">
            <img loading="lazy" src="../../../assets/codining/images/landing-page/{{ feature.imgUrl }}.svg" alt="">
          </div>
          <div class="feature-desc">{{ feature.name | titlecase }}</div>
          <div class="feautre-description">{{ feature.description | titlecase }}</div>
        </div>
      </ngx-slick-carousel>
    </div> -->
    <div class="partner-benifit__carousel" *ngIf="screenWidth <= 480">
      <div class="carousel-content" *ngFor="let feature of techIntegrationFeatures; let i = index">
        <div class="slide" [ngStyle]="{
            left:
              (techIntegrationLeft % (techIntegrationFeatures.length * 100)) +
              '%'
          }">
          <div class="img-container">
            <img loading="lazy" src="../../../../../assets/codining/images/landing-page/{{
                feature.imgUrl
              }}.svg" />
            <!-- <img loading="lazy" src="../../../assets/website/images/partner-with-us/dummy-img.svg" alt="corporate partner"> -->
          </div>
          <div class="benifit-container">
            <div class="benifit-title">{{ feature.name | titlecase }}</div>
            <div class="benifit-brief">
              {{ feature.description | titlecase }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-integration-container" *ngIf="screenWidth > 480">
      <div class="tech-integration-content-listing">
        <div class="tech-integration-content">
          <div class="image-section">
            <img loading="lazy" src="../../../../../assets/codining/images/landing-page/queueless-ordering.svg"
              alt="" />
          </div>
          <div class="main-heading">Queueless Ordering</div>
          <div class="text-section">
            Experience the queueless ordering. goodbye lines, hello hassle-free
            dining.
          </div>
        </div>
        <div class="tech-integration-content">
          <div class="image-section">
            <img loading="lazy" src="../../../../../assets/codining/images/landing-page/multi-ordering-system.svg"
              alt="back" />
          </div>
          <div class="main-heading">Multi-Ordering System</div>
          <div class="text-section">
            Experience orders from multiple restaurants in one go! No more app
            switching or separate deliveries.
          </div>
        </div>
        <div class="tech-integration-content">
          <div class="image-section">
            <img loading="lazy" src="../../../../../assets/codining/images/landing-page/multiple-payments.svg" alt="" />
          </div>
          <div class="main-heading">Multiple-Payments</div>
          <div class="text-section">
            Your convenience, our priority, make multiple payments and real-time
            updates for seamless experiences.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="refer-and-subscription-section" *ngIf="false">
    <div class="card-container">
      <img loading="lazy" src="../../assets/codining/images/landing-page/refer-friend.svg" alt="" />
      <div class="card-text">Refer</div>
    </div>
    <div class="card-container">
      <img loading="lazy" src="../../assets/codining/images/landing-page/food-subscription.svg" alt="" />
      <div class="card-text">Food Subscription</div>
    </div>
  </div>


  <!-- dining experience section  -->
  <div class="dining-experience-main-section">
  <div class="dining__primary-text">
    Indulge in our delightful dining experience
  </div>
  <div class="dining-main-section">
    <div class="dining__section">
      <div class="dining_image">
        <img src="../../../../../assets/codining/images/home-page/dine-in-1.svg" alt="image" />
      </div>
      <div class="dining_data">
        <div class="dining_data_heading">
          A Gastronomic Adventure 
        </div>
        <div class="dining_data_text">
          Embark on flavorful journeys for the whole family. Enjoy authentic dishes from renowned pizzerias, birthplaces of classics, and cherished brands, promising an unforgettable dining experience from 35+ brands.
        </div>
      </div>
    </div>

    <div class="dining__section">
      <div class="dining_image">
        <img src="../../../../../assets/codining/images/home-page/dine-in-2.png" alt="image" />
      </div>
      <div class="dining_data">
        <div class="dining_data_heading">
          Elevating Experiences Through Tailored Services 
        </div>
        <div class="dining_data_text">
          Our attentive and responsive staff create a comfortable and enjoyable atmosphere. Our staff bring the food from different restaurant  within co-dine in place at your table so you and your loved one are not limited to choices.
        </div>
      </div>
    </div>

    <div class="dining__section">
      <div class="dining_image">
        <img src="../../../../../assets/codining/images/home-page/dine-in-3.png" alt="image" />
      </div>
      <div class="dining_data">
        <div class="dining_data_heading">
          Easy Dining
        </div>
        <div class="dining_data_text">
          Savor budget-friendly, satisfying meals without compromising on taste. Our well-designed menu and tailored packages cater to various dining needs. Relax, and let us curate delightful experiences within your budget.
        </div>
      </div>
    </div>

    <div class="dining__section">
      <div class="dining_image">
        <img src="../../../../../assets/codining/images/home-page/dine-in-4.png" alt="image" />
      </div>
      <div class="dining_data">
        <div class="dining_data_heading">
          Smart Ordering Technology & Personalized Suggestions 
        </div>
        <div class="dining_data_text">
          Experience the future of dining with our innovative Table-Side Smart Ordering Technology, blending indulgence with cutting-edge convenience. Our state-of-the-art system tailors recommendations to your taste, creating a personalized culinary adventure.
        </div>
      </div>
    </div>
  </div>
  </div>
  

  <div class="new-changes-section" *ngIf="latestFeatures.length > 0">
    <div class="new-changes__primary-text">What’s New</div>
    <div class="new-changes-carousel">
      <ngx-slick-carousel #slickModal="slick-carousel" class="carousel-content" [config]="slideConfig"
        *ngIf="screenWidth <= 480">
        <div ngxSlickItem class="slide" *ngFor="let feature of latestFeatures">
          <div class="carousel-content" *ngIf="feature.bannerImages.MOBILE">
          <div class="img-container" (click)="openEnquiryFormDialog()">
            <img [src]="feature.bannerImages.MOBILE" alt="image">
          </div>
          </div>
        </div>
      </ngx-slick-carousel>

      <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" *ngIf="screenWidth > 480">
        <div ngxSlickItem class="slide" *ngFor="let feature of latestFeatures">
          <div class="carousel-content" *ngIf="feature.bannerImages.WEB">
            <div class="img-container" (click)="openEnquiryFormDialog()">
              <img [src]="feature.bannerImages.WEB" alt="image">
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <!-- culinary-brands section  -->
  <div class="culinary-Brands">
    <ng-container *ngIf="screenWidth >= 480">
      <div class="top-left-background">
        <img loading="lazy" src="../../../../../assets/website/images/common/brands-left-top-bg.svg" alt="">
      </div>
    </ng-container>
    <div class="testimonial__primary-text">
      <span>Our Culinary Brands</span>
    </div>
    <div class="our-brands-img" *ngIf="screenWidth > 480">
      <img src="../../../../../assets/website/images/common/our-brands.png" alt="">
    </div>
    <div class="our-brands-img"*ngIf="screenWidth < 480">
      <img src="../../../../../assets/website/images/common/our-brands-mobile.png" alt="">
    </div>
  </div>


  <!-- location section  -->
  <div class="our-location-section">
    <div class="our-location-headertext">Our Locations</div>
    <div class="img-codining-section" *ngIf="screenWidth > 480">
      <img src="../../../../../assets/common/codining-web-static-location.svg" alt="image" />
    </div>
    <div class="img-codining-section" *ngIf="screenWidth <= 480">
      <img src="../../../../../assets/common/codining-mobile-static-location.svg" alt="image" />
    </div>

  </div>


  <!-- testimonial section  -->
  <div id="testimonials__container">
    <!-- <h2>Resident Tales:</h2> -->
    <h2>Hear it from our <span>Joyful Folks</span></h2>
    <div class="testimonial" [ngStyle]="{ left: positionForTestimonialsList + '%' }">
      <ng-container *ngFor="let testimonial of testimonials">
        <div class="testimonial-content">
          <div class="image__testimonial">
            <img loading="lazy" [src]="testimonial.imageUrl" alt="Profile" />
          </div>
          <h4 class="user__testimonial">{{ testimonial.name | titlecase }}</h4>
          <p class="occupation__testimonial">
            {{ testimonial.occupation | titlecase }}
          </p>
          <p class="text__testimonial">"{{ testimonial.text }}"</p>
        </div>
      </ng-container>
    </div>

    <div class="buttons__testimonial">
      <button>
        <img loading="lazy" src="../../../../assets/coliving/property-home/back-arrow-circular-outline.svg"
          alt="previous" *ngIf="!prevTestimonialsButton" />
        <img loading="lazy" src="../../../../assets/coliving/property-home/next-arrow-circular-outline.svg"
          alt="previous" *ngIf="prevTestimonialsButton" style="transform: rotate(180deg); cursor: pointer"
          (click)="previousTestimonial()" />
      </button>
      <button>
        <img loading="lazy" src="../../../../assets/coliving/property-home/back-arrow-circular-outline.svg"
          alt="previous" *ngIf="!nextTestimonialsButton" style="transform: rotate(180deg)" />
        <img loading="lazy" src="../../../../assets/coliving/property-home/next-arrow-circular-outline.svg" alt="next"
          *ngIf="nextTestimonialsButton" style="cursor: pointer" (click)="nextTestimonial()" />
      </button>
    </div>
  </div>


  <!-- success story section   -->

  <div id="owner-contact__container">
    <div class="content__owner-contact">
      <h2>Join the</h2>
      <h2>Success story</h2>

      <div class="image__owner-contact" *ngIf="screenWidth <= 480">
        <img loading="lazy" src="../../../../assets/coliving/property-home/property-owner-banner.svg" alt="Join-hands" />
      </div>
      <p>
        Ready to take your food business to new heights? Partner with Isthara's food courts and ignite a flavor
        revolution. With our bustling footfall, your brand will thrive like never before. Join us on this adventure and
        let's make taste buds dance together!
      </p>
      <p style="cursor: pointer" (click)="openPartnershipForm('CO_LIVING')">
        <span style="
            border-bottom: 1px solid black;
            font-size: 16px;
            margin-right: 10px;
          ">Let's talk</span>
        <img loading="lazy" src="../../../../assets/coliving/common/black-straight-right-arrow.svg" alt="Arrow"
          style="position: relative; top: 3px; width: 15px" />
      </p>
    </div>

    <div class="image__owner-contact" *ngIf="screenWidth > 480">
      <img loading="lazy" src="../../../../assets/coliving/property-home/property-owner-banner.svg" alt="Join-hands" />
    </div>
  </div>

</section>