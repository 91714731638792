import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss', './mq-terms-and-condition.component.scss']
})
export class TermsAndConditionComponent {

  terms: any =  
    {
      bulletPoints: [
        {
          bpoints:"Parking is not guaranteed and Subjected to Availability.",
        },
        {
          bpoints:"Rent is payable on or before the 8th of every month. Delays would result in a charge of Rs.200/- per day and in recurring delays (2 times or more ) Isthara reserves the right to terminate the lease.",
        },
         {
          bpoints:"Housekeeping is provided thrice a week. Any misuse/ unhygienic use of property or linen will be charged a fee of Rs.1000/- per occurrence along with the cost of the material. Isthara Premier will offer Housekeeping 5 times a week.",
        },
          {
          bpoints:"Isthara App is available on Android Play Store and IOS App Store. Please use this as your tool of engagement with us. Kindly refrain from contacting the caretaker for any requests except in times of emergency.",
        },
           {
          bpoints:"Visitors will be entertained only in common areas and will not be allowed into personal rooms. Visiting  timings will be till 11 p.m.",
        },
            {
          bpoints:"Please store your valuables in the cupboards and lock them up. Isthara will not be responsible for any  loss of belongings.",
        },
         {
          bpoints:"Rent is payable on or before the 8th of every month. Delays would result in a charge of Rs.200/- per day and in recurring delays (2 times or more ) Isthara reserves the right to terminate the lease.",
        },
         {
          bpoints:"30 Day notice period is mandatory for any departure. Notice period will be calculated from the date of submission of the exit form.",
        },
          {
          bpoints:"Exit form needs to be signed and submitted to help us process your refund . No verbal instructions to anyIsthara Staff will be accepted as a submission of exit form .",
        },
           {
          bpoints:"If the necessary notice period is not complete, then the remaining days charges will be deduced from  the deposit.",
        },
            {
          bpoints:"Rental Refund will not be done. Please ensure you plan your departure on the last day of the month .Isthara reserves the “Right of admission”",
        },
             {
          bpoints:"Surcharges will be applicable for the summer months- The amount and duration will be announced 30 days  prior to the surcharges and these are mandatory in nature with no exceptions.",
        },
        {
          bpoints: "Refund will be done only to the Resident bank Account not third party Account .",
        },
        {
          bpoints:"Refund will be given in 15 days after the Resident vacating date",
        },
        {
          bpoints:"Smoking is strictly prohibited in the rooms .A freshening fee of Rs.2000 will be levied if any  resident is  found smoking in the room."
        },
        {
          bpoints:"Illegal Activity such as Drug , Verbal / physical violence , Sexual Harassment or Carrying of any weapon lead to immediate eviction from the property and can register a legal case.",
        },
        {
          bpoints: "Residents are expected to behave politely with all the Co-residents and Isthara staff.",
        
        },
        {
          bpoints:"Residents should not tamper any in-House material/ Electronic Equipment .",
        },
        {
          bpoints:"Devices per Resident are allowed to access the WiFi.",
        },
        {
          bpoints:"Advance Deposit will be refunded within 30 working days after your physical checkout from the rooms .Any damages or  due that the resident owes to Isthara will be deducted from the deposit",
        },
        {
          bpoints:"Isthara serves Vegetarian food only and Non –Vegetarian Food at a cost will be provided by an authorized  vendor. Days of this being served and surcharge for this will be displayed on the Buildings notice Board . This will   be on a monthly package and should be prepaid .There will be no Refund of unused days on this Package .",
        },
        {
          bpoints:"Isthara reserves the right to change /modify the polices without any prior concerns to residents .",
        },
        {
          bpoints:"Isthara may store details provided by lessee from time to time and may use for marketing and operational  purpose."
        },
      ]
    }
}
