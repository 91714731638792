import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageServiceService } from '../../shared-services/storage-service.service';
import { Cart } from '../../entities/Cart.model';
import { Item } from '../../entities/Item.model';
import { SharedUtilsService } from '../../shared-services/shared-utils.service';
import { CartPageService } from './cart-page.service';
import { CartItem } from '../../entities/CartItem.model';
import { environment } from "../../../../../environments/environment";
import { SharedService } from 'projects/isthara/src/app/shared-services/shared.service';
import { cashfreeSandbox, cashfreeProd } from "cashfree-pg-sdk-javascript";
import { ORDER_TYPE } from 'projects/isthara/src/app/constants/constant';
import { OrderService } from '../../shared-services/order-service.service';
import { CouponsListingComponent } from '../coupons-listing/coupons-listing.component';
import { CodiningService } from '../../shared-services/codining-service.service';
import { SuccessPopupComponent } from '../../shared-components/success-popup/success-popup.component';

class UserBillingInfo {
  pointsUsed: number = 0;
  cashUsed: number = 0;
}

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss', './mq-cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedLocalStorageService: SharedService,
    private codiningService: CodiningService,
    private storageService: StorageServiceService,
    private sharedUtilsService: SharedUtilsService,
    private cartPageService: CartPageService,
    private orderService: OrderService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) { };


  screenWidth: number = 0;

  @HostListener('window:resize', ['$event'])
  getScreenWidth() {
    this.screenWidth = window.innerWidth;
  }

  ORDER_TYPE: any = [...ORDER_TYPE];

  userCartDetails!: Cart;
  cartPageLoader: boolean = true;
  userPoints: number = 0;

  selectedOrderType: string = "DINE_IN";

  userId: string = "";
  userName: string = "";
  userMobile: string = "";
  foodCourtName: string = "";
  foodCourtId: string = "";
  siteId: string = "";

  userBillingInfo: UserBillingInfo = new UserBillingInfo();

  goToHomePage() {

    this.router.navigate([`/mbd/${this.foodCourtName}`]);
  }

  removeItem(itemDetails: any) {
    this.addItemToCart(itemDetails, "DECREASE", true);
  }

  isVendorGSTConfigured: any = {};

  ngOnInit() {
    this.checkParam();

    this.intialize();
    this.getUserPoints();

    this.cartPageLoader = true;
    const request = this.storageService.getUserCart();
    request.onsuccess = () => {
      const itemsList: any[] = [];
      if (!request.result[0]?.isCartObject) {
        request.result.forEach((item: Item, idx) => {
          itemsList[idx] = new CartItem();
          itemsList[idx].convertToCartItem(item);
          this.isVendorGSTConfigured[item.vendorId] = false;
        });
      } else {
        request.result.forEach((item: Item, idx) => {
          itemsList[idx] = item;
          this.isVendorGSTConfigured[item.vendorId] = false;
        });
      }

      this.userCartDetails.itemsList = itemsList;
      this.userCartDetails.itemsList.forEach((item: any) => {
        item.customizationPrice = 0;
        if (item.orderedItemCustomizations?.length > 0) {
          item.orderedItemCustomizations.forEach((customItem: any) => {
            item.customizationPrice += customItem.price;
          })
        }
      })

      this.cartPageLoader = false;

      this.getVendorGSTInfo();

      this.userCartDetails.itemsList.map((item: any) => {
        this.userCartDetails.billDetails.breakup["Item Total"] += ((item.markUpTotalPrice + (item.customizationPrice ? item.customizationPrice * item.itemQuantity : 0)));
        this.userCartDetails.billDetails.breakup["Taxes & charge"] += (item.markUpTaxValue * item.itemQuantity);
      })
      this.userCartDetails.billDetails.finalPrice = this.userCartDetails.billDetails.breakup["Item Total"] + this.userCartDetails.billDetails.breakup["Taxes & charge"] + (this.userCartDetails.billDetails.breakup["Delivery Charges"] ? this.userCartDetails.billDetails.breakup["Delivery Charges"] : 0) + (this.userCartDetails.billDetails.breakup["Packing Charges"] ? this.userCartDetails.billDetails.breakup["Packing Charges"] : 0) - (this.userCartDetails.billDetails.breakup['Item Discount'] ? this.userCartDetails.billDetails.breakup['Item Discount'] : 0);
      this.userCartDetails.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("orderingFCId") || "";
      this.userCartDetails.siteId = this.sharedLocalStorageService.getFromLocalStorage("siteId") || "";

      this.billDetails = this.userCartDetails.billDetails;

      this.userBillingInfo.cashUsed = this.userCartDetails.billDetails.finalPrice;


      if (this.userCartDetails.itemsList.length === 0)
        this.router.navigate([`/mbd/${this.foodCourtName}`]);

      this.dumpItemsToServer(false);
    };
  }

  async getVendorGSTInfo() {
    await Object.keys(this.isVendorGSTConfigured).forEach(async (vendorId: string) => {

      this.cartPageService.getVendorDetails(vendorId).subscribe({
        next: async (response: any) => {
          this.isVendorGSTConfigured[vendorId] = await response.data.vendor.gstVerified;
          this.calculatePrice();
        }, error: () => {

        }
      })
    })
  }

  getItemFromLocalCart() {

  }

  deliveryAddress: any;
  changeOrderType(event: any) {

    if (!this.userId) {
      this.router.navigate(['/auth'], { queryParams: { preview_page: 'CART' } });
      return;
    }
    this.cartPageLoader = true;
    this.selectedOrderType = event.target.value;

    let data = {
      "address": null,
      "delivery": false,
      "orderType": this.selectedOrderType,
      "userId": this.userId
    }
    if (this.selectedOrderType === "DELIVERY") {
      // data.address = {
      //   "address": "string",
      //   "addressId": "string",
      //   "addressTitle": "string",
      //   "geolocation": {
      //     "latitude": 0,
      //     "longitude": 0
      //   },
      //   "mobile": this.userMobile,
      //   "name": this.userName,
      //   "userId": this.userId
      // }
      data.delivery = true;
    }

    this.orderService.changeOrderStatus(data).subscribe((response: any) => {
      this.userCartDetails = response.data;
      this.deliveryAddress = this.userCartDetails.address;
      this.billDetails = this.userCartDetails.billDetails;
      this.selectedOrderType = this.userCartDetails.orderType;

      // this.userCartDetails.billDetails.finalPrice = this.userCartDetails.billDetails.breakup["Item Total"] + this.userCartDetails.billDetails.breakup["Taxes & charge"] + (this.userCartDetails.billDetails.breakup["Delivery Charges"] ? this.userCartDetails.billDetails.breakup["Delivery Charges"] : 0) + (this.userCartDetails.billDetails.breakup["Packing Charges"] ? this.userCartDetails.billDetails.breakup["Packing Charges"] : 0) - (this.userCartDetails.billDetails.breakup['Item Discount'] ? this.userCartDetails.billDetails.breakup['Item Discount'] : 0);
      this.userBillingInfo.cashUsed = this.billDetails.finalPrice;
      // this.calculatePrice();
      this.cartPageLoader = false;
    }, (error: any) => {
      this.sharedUtilsService.openSnackBar(error.error.details, "Close");
      this.selectedOrderType = "DINE_IN";
      this.cartPageLoader = false;
    }, () => {
    })
  }

  getUserPoints() {

    let userId: string = this.sharedLocalStorageService.getFromLocalStorage("userId");
    if (!this.userId)
      return;

    this.cartPageLoader = true;

    this.sharedUtilsService.getUserPoints(userId).subscribe((response: any) => {
      this.userPoints = response.data;

    }, (error: any) => {

    }, () => {
      this.cartPageLoader = false;
    })
  }

  istharaPointsToggled(event: any) {
    if (event.checked) {

      this.userBillingInfo.pointsUsed = (this.userBillingInfo.cashUsed > this.userPoints) ? this.userPoints : this.userBillingInfo.cashUsed;
      this.userBillingInfo.cashUsed = (this.userBillingInfo.cashUsed > this.userPoints) ? (this.userBillingInfo.cashUsed - this.userPoints) : 0;
    } else {
      this.userBillingInfo.cashUsed = this.userCartDetails.billDetails.finalPrice;
      this.userBillingInfo.pointsUsed = 0;
    }
  }

  dumpItemsToServer(willDoPayment: boolean = false) {

    let userId = this.sharedLocalStorageService.getFromLocalStorage("userId") || "";
    let userName = this.sharedLocalStorageService.getFromLocalStorage("userName") || "";
    let mobile = this.sharedLocalStorageService.getFromLocalStorage("mobile") || "";

    this.userCartDetails.userId = userId;
    this.userCartDetails.userName = userName;
    this.userCartDetails.mobile = mobile;
    this.userCartDetails.orderType = this.selectedOrderType;

    if (userId) {

      this.cartPageLoader = true;
      this.sharedUtilsService.addItemToCart(this.userCartDetails).subscribe((response: any) => {
        this.userCartDetails = response.data;

        // console.log(response.data);
        this.userCartDetails.itemsList.forEach((item: any) => {
          item.customizationPrice = 0;
          if (item.orderedItemCustomizations?.length > 0) {
            item.orderedItemCustomizations.forEach((customItem: any) => {
              item.customizationPrice += customItem.price;
            })
          }
        })

        this.userCartDetails.billDetails.finalPrice = this.userCartDetails.billDetails.breakup["Item Total"] + this.userCartDetails.billDetails.breakup["Taxes & charge"] + (this.userCartDetails.billDetails.breakup["Delivery Charges"] ? this.userCartDetails.billDetails.breakup["Delivery Charges"] : 0) + (this.userCartDetails.billDetails.breakup["Packing Charges"] ? this.userCartDetails.billDetails.breakup["Packing Charges"] : 0) - (this.userCartDetails.billDetails.breakup['Item Discount'] ? this.userCartDetails.billDetails.breakup['Item Discount'] : 0);
        this.userBillingInfo.cashUsed = this.userCartDetails.billDetails.finalPrice;
        this.deliveryAddress = this.userCartDetails.address;
        this.billDetails = this.userCartDetails.billDetails;

        this.selectedOrderType = this.userCartDetails.orderType;
        // if (this.deliveryAddress.address)
        //   this.selectedOrderType = "DELIVERY";

        this.cartPageLoader = false;
        if (willDoPayment)
          this.doPayment();
        this.changeDetectorRef.detectChanges();
        // this.userCartDetails = { ...this.userCartDetails, billDetails: this.userCartDetails.billDetails };
        // console.log(this.userCartDetails.billDetails.breakup["Item Total"]);=
        // this.calculatePrice();
      }, (error: any) => {

      });
    }
  }

  removeDiscountFromCart() {
    this.cartPageLoader = true;
    this.cartPageService.removeCouponFromUserCart(this.userId).subscribe(
      (response: any) => {
        this.userCartDetails.billDetails = response?.data?.billDetails || [];
        this.userBillingInfo.cashUsed = this.userCartDetails.billDetails.finalPrice;
        this.billDetails = this.userCartDetails.billDetails;

        this.cartPageLoader = false;
      },
      (err) => {
        //console.log(err);
        this.cartPageLoader = false;
        this.sharedUtilsService.openSnackBar('Failed to remove Coupon Code', 'CLOSE');
      }
    );
  }

  intialize() {

    this.getScreenWidth();

    this.showDeliveryAddress = false;
    this.foodCourtId = this.sharedLocalStorageService.getFromLocalStorage("foodCourtId") || "";
    this.foodCourtName = this.sharedLocalStorageService.getFromLocalStorage("foodCourtName") || "";
    this.siteId = this.sharedLocalStorageService.getFromLocalStorage("siteId") || "";
    this.userName = this.sharedLocalStorageService.getFromLocalStorage("userName") || "";
    this.userId = this.sharedLocalStorageService.getFromLocalStorage("userId") || "";
    this.userMobile = this.sharedLocalStorageService.getFromLocalStorage("mobile");

    this.getFoodCourtDetails();

    this.userCartDetails = new Cart();
  }

  checkParam() {
    this.activatedRoute.queryParams.subscribe((qParam: any) => {
      this.showDeliveryAddress = false;
      this.getUserCartData();
    })
  }

  billDetails: any;
  getUserCartData() {
    let userId = this.sharedLocalStorageService.getFromLocalStorage("userId");
    if (!userId)
      return;

    this.cartPageLoader = true;
    this.cartPageService.getCartData(userId).subscribe((response: any) => {
      this.userCartDetails = response.data;
      this.selectedOrderType = this.userCartDetails?.orderType || "DINE_IN";
      this.billDetails = this.userCartDetails.billDetails;
      this.deliveryAddress = this.userCartDetails.address;
      this.userBillingInfo.cashUsed = this.billDetails.finalPrice;
      this.cartPageLoader = false;
    }, (error: any) => {
      this.cartPageLoader = false;
    })
  }

  foodCourtHasDelivery: boolean = false;
  getFoodCourtDetails() {

    this.cartPageLoader = true;
    let orderedFoodCourtId = this.sharedLocalStorageService.getFromLocalStorage("orderingFCId");

    this.codiningService.getFoodCourtDetails(orderedFoodCourtId).subscribe((response: any) => {

      this.cartPageLoader = false;
      this.foodCourtHasDelivery = response.data.hasDelivery;
      if (!this.foodCourtHasDelivery)
        this.ORDER_TYPE.pop();
      // if (response.data.hasDelivery) {
      //   this.foodcourtDetails.minOrderAmount = response.data.minOrderAmount;
      //   this.foodcourtDetails.minDeliveryCharges =
      //   response.data.minDeliveryCharges;
      //   this.foodcourtDetails.deliveryCharges = response.data.deliveryCharges;
      // }
    }, (err) => {
      this.cartPageLoader = false;
      this.sharedUtilsService.openSnackBar("Could not get delivery info of the foodcourt", "Close");
    });
  }

  openCouponDialog() {
    this.sharedUtilsService.openDialogBox(CouponsListingComponent).afterClosed().subscribe((response: any) => {
      // location.reload(); // need to change
      this.getUserCartData();
    });
  }


  addItemToCart(itemDetails: any, operation: "INCREASE" | "DECREASE", removeItem?: boolean) {


    if (operation === "INCREASE") itemDetails.itemQuantity++;
    else {
      itemDetails.itemQuantity--;
    }
    if (removeItem)
      itemDetails.itemQuantity = 0;
    let itemPosition = -1;
    this.userCartDetails.itemsList.forEach((item: any, index: number) => {
      if (itemDetails.appItemId === item.appItemId)
        itemPosition = index;
    })
    itemDetails.markUpTotalPrice = itemDetails.markUpPrice * itemDetails.itemQuantity;
    this.userCartDetails.itemsList = this.userCartDetails.itemsList.filter((item) => itemDetails.appItemId !== item.appItemId);
    if (itemDetails.itemQuantity > 0) {
      this.userCartDetails.itemsList = [
        ...this.userCartDetails.itemsList.slice(0, itemPosition),
        itemDetails,
        ...this.userCartDetails.itemsList.slice(itemPosition)
      ];
    }
    this.storageService.addObject({ ...itemDetails } as Item);

    this.dumpItemsToServer();
    if (this.userCartDetails.itemsList.length === 0) {
      this.storageService.clearAllItemsFromCart().then(() => {
        this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
      });
      this.router.navigate([`/mbd/${this.foodCourtName}`]);
    }
    // }


    this.calculatePrice();
  }

  calculatePrice() {
    this.cartPageLoader = true;

    setTimeout(() => {
      this.cartPageLoader = false;
    }, 500);

    this.userCartDetails.billDetails.breakup["Item Total"] = 0;
    this.userCartDetails.billDetails.breakup["Taxes & charge"] = 0;
    this.userCartDetails.itemsList.map((item: any) => {
      let itemTotal = ((item.markUpTotalPrice + (item.customizationPrice ? item.customizationPrice * item.itemQuantity : 0)));
      this.userCartDetails.billDetails.breakup["Item Total"] += itemTotal;
      this.userCartDetails.billDetails.breakup["Taxes & charge"] += (!this.isVendorGSTConfigured[item.vendorId] ? 0 : (itemTotal * (item.taxPercentPerItem / 100)));
      // this.userCartDetails.billDetails.breakup["Item Total"] += ((item.markUpTotalPrice + (item.customizationPrice ? item.customizationPrice * item.itemQuantity : 0)));
      // this.userCartDetails.billDetails.breakup["Taxes & charge"] += (item.markUpTaxValue * item.itemQuantity);

    })

    this.userCartDetails.billDetails.finalPrice = this.userCartDetails.billDetails.breakup["Item Total"] + this.userCartDetails.billDetails.breakup["Taxes & charge"] + (this.userCartDetails.billDetails.breakup["Delivery Charges"] ? this.userCartDetails.billDetails.breakup["Delivery Charges"] : 0) + (this.userCartDetails.billDetails.breakup["Packing Charges"] ? this.userCartDetails.billDetails.breakup["Packing Charges"] : 0) - (this.userCartDetails.billDetails.breakup['Item Discount'] ? this.userCartDetails.billDetails.breakup['Item Discount'] : 0);
    this.userBillingInfo.cashUsed = this.billDetails.finalPrice;

  }

  paymentButtonLoader: boolean = false;
  doCashFreePayment() {

    let email = this.sharedLocalStorageService.getFromLocalStorage('email');
    let mobile = this.sharedLocalStorageService.getFromLocalStorage('mobile');
    let userId = this.sharedLocalStorageService.getFromLocalStorage('userId');
    let username = this.sharedLocalStorageService.getFromLocalStorage('userName');

    let orderNote: string = "";
    this.userCartDetails.itemsList.forEach((itemDetail: any) => {
      orderNote += itemDetail.itemName + " : " + itemDetail.cookingInstruction + " , ";
    })

    let paymentPayload = {
      "email": (email ? email : "test@gmail.com"),
      "mobile": mobile,
      "orderAmount": this.userBillingInfo.cashUsed,
      "orderNote": orderNote,
      "pointsToBePaid": this.userBillingInfo.pointsUsed,
      "userId": userId,
      "userName": username
    }

    this.paymentButtonLoader = true;

    this.cartPageService.doCashFreePayment(paymentPayload).subscribe((paymentDetails: any) => {
      // this.localStorageService.storeInLocalStorage('orderId', );

      const paymentSessionId = paymentDetails.data.orderSignature;
      let cashFree;
      if (environment.production && !environment.baseUrl.includes("newprod")) {
        cashFree = new cashfreeProd.Cashfree(paymentSessionId);
      } else {
        cashFree = new cashfreeSandbox.Cashfree(paymentSessionId);
      }
      this.sharedLocalStorageService.storeInLocalStorage("paymentFor", "CART_PAYMENT");
      cashFree.redirect();

    }, (error: any) => {
      this.sharedUtilsService.openSnackBar("Payment Failed", "Close");
    }, () => {
      this.paymentButtonLoader = false;
    })
  }

  pointsPayment() {

    let userId = this.sharedLocalStorageService.getFromLocalStorage('userId');
    let orderNote: string = "";
    this.userCartDetails.itemsList.forEach((itemDetail: any) => {
      orderNote += itemDetail.itemName + " : " + itemDetail.cookingInstruction + " , ";
    })
    let data = {
      orderNote: orderNote,
    };

    this.paymentButtonLoader = true;
    this.cartPageService.payWithIstharaPoints(userId, data).subscribe(
      (response: any) => {
        this.sharedUtilsService.openDialogBox(SuccessPopupComponent, { type: "ORDER_DETAIL" }).afterClosed().subscribe(() => {

          this.sharedLocalStorageService.storeInLocalStorage("orderId", response.data.orderId);
          this.storageService.clearAllItemsFromCart().then(() => {
            this.sharedLocalStorageService.storeInLocalStorage("orderingFCId", null);
            this.router.navigate(['/mbd/order-history']);
          })
        })
      },
      (err) => {

        if (err.status === 404) {
          this.sharedUtilsService.openSnackBar('Items present in the cart are out of stock. Please Remove the Items', "Close");
        }
        else {
          this.sharedUtilsService.openSnackBar('Something went wrong!! plz try again later', "Close");
        }
      }, () => {

        this.paymentButtonLoader = false;
      }
    );
  }

  checkOrderBeforePayment() {
    if (this.selectedOrderType === 'DELIVERY' ? !this.userCartDetails.address?.address : false) {
      return;
    }

    if (!this.userId || this.userId === '') {
      this.sharedUtilsService.openSnackBar("Redirecting to Loging Page", "CLose");
      this.router.navigate(['/auth'], { queryParams: { preview_page: 'CART' } });
    }

    if (this.userCartDetails.itemsList.some((itemDetail: any) => (itemDetail.cookingInstruction && itemDetail.cookingInstruction.length > 0))) {
      this.dumpItemsToServer(true);
    } else {
      this.doPayment();
    }


  }

  doPayment() {
    if (!this.userId)
      return;

    if (this.userBillingInfo.cashUsed) {
      this.doCashFreePayment();
    } else {
      this.pointsPayment();
    }

    // this.sharedUtilsService.openDialogConfig(
    //   SuccessPopupComponent,
    //   '430px',
    //   '420px',
    //   {data:null})
  }

  goToCouponListingPage() {
    if (!this.userId) {
      this.sharedUtilsService.openSnackBar("Please login to apply discount", "Close");
      return;
    }
    this.router.navigate(['/mbd/coupons']);
  }

  showDeliveryAddress: boolean = false;
  goToDeliveryAddressPage() {
    if (this.screenWidth <= 480) {
      this.router.navigate(['/mbd/delivery-address']);
    }
    else {
      this.showDeliveryAddress = true;
    }
  }
}
