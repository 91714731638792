<div class="main-section">
    <div class="community-details">
      <!-- <user-flow-top-navbar></user-flow-top-navbar> -->
    </div>
    <h1 class="heading">Privacy and Security Policy</h1>
    <p class="text">
      We are here to assist you in understanding how we collect and use the
      personal information you provide to us.
    </p>
    <br />
    <div class="sub-heading">
      <div>
        <p class="heading-pol">Privacy Policy</p>
        <ul>
          <li class="list-value">
            Thank you for visiting Isthara.com. We appreciate that you trust us as
            a partner in your search for a new place to call home. We value your
            privacy,please read this
            <span class="bold-text">Privacy Statement</span> to understand our
            information gathering practices and your ability to selectively opt
            out.
          </li>
          <li class="list-value">
            Your consent to the information collection, usage, disclosure, and
            storage practiced described in this Privacy Statement when you use the
            Isthara website.
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">Cancellation Policy</p>
        <ul>
          <li class="list-value">
            As Per Terms and Conditions of the company
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">
          What information does Isthara collect from its users?
        </p>
        <ul>
          <li class="list-value">
            Some information you provide to us automatically, and other
            information you provide in order to utilize certain features of our
            website. Any information that you provide to us we may collect. For
            example, we might collect information about the type of Accommodation
            that interests you.
          </li>
          <li class="list-value">
            Mobile Information: When you use Isthara’s mobile site, you have the
            option to disclose your location to Isthara.
          </li>
          <li class="list-value">
            Information You Provide To Isthara: To leverage certain
            functionalities offered by the website, you may need to supply
            additional information. For example, For Isthara Team to reach you
            about your accomodation requirement, you must share your e-mail
            address / mobile number.
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">Does Isthara Share the Information it receives?</p>
        <ul>
          <li class="list-value">
            Isthara does not sell any of the information that it receives from its
            users. Isthara shares information unless it is directed by you to do
            so. For example, if you use a Isthara web form to share a listing to
            your friend as a referral.
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">
          How does Isthara use the information it collects?
        </p>
        <ul>
          <li class="list-value">
            Any time you supply an e-mail address (or a friend’s e-mail address),
            Isthara may use it to communicate with you about matters believed to
            be of interest to you. Should Isthara ever do so, it will provide you
            the opportunity to opt out of future communications. Isthara may also
            use your e-mail address to communicate with you regarding specific
            transactions you engage in on the site. For example: Whenever you
            establish an account, Isthara will send you an e-mail confirming that
            the account has been created.
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">Cookies and Google Analytics</p>
        <ul>
          <li class="list-value">
            A HTTP cookie is an identifier that a website transfers to the user’s
            computer or mobile device and that is stored in the user’s web
            browser. Isthara uses cookies to identify the user and enhance the
            user experience, and also to understand the way in which the website
            is used. If you object to isthara’s use of cookies, look to your
            browser’s help feature for information about how to disable or clear
            cookies.
          </li>
        </ul>
      </div>
      <div>
        <p class="heading-pol">Security</p>
        <ul>
          <li class="list-value">
            E-mail addresses, passwords, credit card information, messages
            transmitted in web forms, and other user-generated content of a
            confidential nature in Isthara’s possession will be accessible only to
            authorized personnel at Isthara.
          </li>
          <li class="list-value">
            The Isthara website is hosted at AWS (Amazon Web Services) and Isthara
            cannot guarantee that information will not be intercepted from the
            website or that security breaches will not occur. This Privacy
            Statement applies solely to your dealings with Isthara.
          </li>
          <li class="list-value">
            Once you leave the Isthara domain, your online actions will be subject
            to the privacy policy, if any, of the new website you enter, even if
            you reached that website by following a link from Isthara. This
            Privacy Statement may be updated from time-to-time, and Isthara
            recommends that you periodically revisit this page to keep yourself
            apprised of any changes.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <!-- <app-footer></app-footer> -->
  </div>
  