<section class="qr-page-section">
  <div class="content" role="main">
    <!-- Resources -->
    <!-- Next Steps -->
    <div id="root">
      <div class="jss4 jss5">
        <div class="jss6 jss7">
          <div class="jss8">
            <h1 class="jss10 jss11 jss9">Isthara Parks Pvt Ltd.</h1>
            <div class="jss14">
              <div class="jss15">
                <div class="jss16">
                  <img
                    src="../../../../../assets/common/isthara-logo.svg"
                    alt="app description"
                  />
                </div>
                <div class="jss17"></div>
              </div>
              <p class="jss18">
                <i style="font-weight: bold"
                  >"Creating Memorable Experiences."</i
                >
              </p>
              <p class="jsstag jsstagp">
                Thanks for your interest in Isthara and reaching this app
                download section. Select the store based on your mobile and
                enjoy an elevated experience.
              </p>
              <div class="jss19">
                <a class="jss20" href="http://bit.ly/3eaOtf7"
                  ><img
                    src="../../../../../assets/website/images/common/google-play.svg"
                    width="100%"
                    alt="download"
                /></a>
                <a class="jss20" href="http://apple.co/3c3KvSR"
                  ><img
                    src="../../../../../assets/website/images/common/app-store.svg"
                    width="100%"
                    alt="download"
                /></a>
              </div>
            </div>
            <a
              class="jss48 jss49"
              color="#FD6F70"
              href="https://isthara.com"
              style="text-align: center; font-size: 18px"
              >isthara.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
